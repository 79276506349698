import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../core/services/customer.service';
import { ErrorMessage } from '../../shared/error-message.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { CartService } from '../../core/services/cart.service';
import { CustomValidator } from '../../shared/validators';
import { RouterExtensionService } from '../../core/services/router-extension.service';
import { NotificationService } from '../../core/services/notification.service';
import { PreviousUrlModel } from '../../shared/router-extension.model';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnInit {
  @Input() returnUrl: string;
  @Input() email: string;
  @Input() first_name: string;
  @Input() last_name: string;
  @Input() phone: string;
  @Input() company_name: string;
  @Input() showSwitchText: boolean = false;
  @Input() showMessage: boolean = false;
  @Input() hideTitle: boolean = false;
  @Input() update: boolean = false;
  @Input() customer;

  showSuccessMessage: boolean = false;
  buttonState: string = 'Register';
  previousUrl = new PreviousUrlModel();

  registerForm: FormGroup;
  loginError = false;
  loginErrorMessage = '';
  registrationError:boolean = false;
  registrationErrorMessage:string;

  customErrorMessages: ErrorMessage[] = [
    {
      error: 'areEqual',
      format: (label, error) => `${label} does not match`
    },
    {
      error: 'complexity',
      format: (label, error) => `Passwords must be a minimum of 8 characters, maximum of 20 characters and include 1 uppercase letter, 1 lowercase letter and 1 special character`
    }
  ];

  constructor(
    private customerService: CustomerService,
    private cartService: CartService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private routerExtensionService: RouterExtensionService,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.routerExtensionService.previousUrlSubject.subscribe(previousUrl => {
      this.previousUrl.url = previousUrl ? previousUrl.url : ['/store'];
      this.previousUrl.paramMap = previousUrl ? previousUrl.paramMap : {};
    });
  }

  ngOnInit() {
    if (this.update) {
      this.registerForm = this.formBuilder.group({
        customerGroup: this.formBuilder.group({
          id: [null, []],
          first_name: [null, []],
          last_name: [null, []],
          company_name: [null, []],
          email: [{value: null, disabled: true}, [CustomValidator.emailAddressValidator]],
          phone: [null, [CustomValidator.phoneNumberValidator]],
          salesAttribution: ['', []]
        })
      });
    } else {

      const sa = this.authService.getAttribution() ? this.authService.getAttribution().url : undefined;

      this.registerForm = this.formBuilder.group({
        customerGroup: this.formBuilder.group({
          id: ['', []],
          first_name: ['', [Validators.required]],
          last_name: ['', [Validators.required]],
          company_name: [''],
          email: ['', [Validators.required, CustomValidator.emailAddressValidator]],
          phone: ['', [CustomValidator.phoneNumberValidator]],
          salesAttribution: [sa, []],
          timezone: [dayjs.tz.guess(), []]
        }),
        passwordGroup: this.formBuilder.group({
          password: ['', [Validators.required, CustomValidator.validatePasswordsComplexity, Validators.minLength(8),
            Validators.maxLength(20)]],
          confirmPassword: ['', [Validators.required]]
        }, { validator: (formGroup: FormGroup) => {
          return CustomValidator.validatePasswordsAreEqual(formGroup);
        } })
      });
    }

    if (this.last_name) {
      this.registerForm.get('customerGroup').patchValue({last_name: this.last_name});
    }
    if (this.first_name) {
      this.registerForm.get('customerGroup').patchValue({first_name: this.first_name});
    }
    if (this.phone) {
      this.registerForm.get('customerGroup').patchValue({phone: this.phone});
    }
    if (this.email) {
      this.registerForm.get('customerGroup').patchValue({email: this.email});
    }
    if (this.company_name) {
      this.registerForm.get('customerGroup').patchValue({company_name: this.company_name});
    }

    if (this.update) {
      this.buttonState = "Save Changes";
    }

    if (this.customer) {
      this.customer.subscribe(customer => {
        this.initializeForm(customer);
      });
    }
  }

  initializeForm = (customerToInitialize) => {
    if (customerToInitialize ) this.registerForm.setValue(
      {
        customerGroup: customerToInitialize
      },
    );
  }

  customerLogin(credentials) {
    this.loginError = false;
    this.authService.login(credentials).subscribe(
      result => {
        this.cartService.convertGuestCart().subscribe((res)=>{
          if (result) {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(this.previousUrl['url'], { queryParams: this.previousUrl['paramMap'] });
            }
          }
        })
      },
      error => {
        console.log(error);
        this.loginError = true;
        this.loginErrorMessage = 'Incorrect login credentials';
    });
  }

  cleanObject = (customerObject) => {
    let result = customerObject;

    for (var propName in customerObject) { 
      if (customerObject[propName] === null || customerObject[propName] === undefined) {
        delete result[propName];
      }
    }

    return result;
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      return;
    }

    let apiDataFormat = {
      firstName: this.registerForm.value.customerGroup.first_name,
      lastName: this.registerForm.value.customerGroup.last_name,
      companyName:this.registerForm.value.customerGroup.company_name,
      phoneNumber: this.registerForm.value.customerGroup.phone,
      email: this.registerForm.value.customerGroup.email,
      company: this.registerForm.value.customerGroup.company,
      salesAttribution: this.registerForm.value.customerGroup.salesAttribution,
      timezone: this.registerForm.value.customerGroup.timezone
    }

    // If the update flag is true, we are using this form to update a customer record instead of create
    if (this.update) {
      apiDataFormat['id'] = this.registerForm.value.customerGroup.id;
 
      apiDataFormat = this.cleanObject(apiDataFormat);

      this.customerService.put(apiDataFormat).subscribe(customer => {
        this.customerService.getCustomer();
        this.notificationService.broadcastSuccessNotification({
          message: 'Successfully updated account information.',
          options: {
            timeout: true
          }
        });
      }, error => {
        this.notificationService.broadcastFailureNotification({
          message: 'Error updating account information.'
        });
      });
    } else {
      this.customerService.post({...apiDataFormat, ...this.registerForm.value.passwordGroup}).subscribe(result => {
        if (result) {
          this.customerLogin({
            email: this.registerForm.value.customerGroup.email,
            password: this.registerForm.value.passwordGroup.password,
          });
        }
      }, err => {
        this.registrationError = true;
        if (err && err.error === "Customer already exists") {
          this.registrationErrorMessage = "This email address is already in use. Please click below to sign in."
        } else {
          this.registrationErrorMessage = "An error occured during registration."
        }
      });
    }
  }
} 
