import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { StoreLayoutComponent } from './layout/store-layout/store-layout.component';
import { Main_ROUTES } from './main-layout.routes';
import { Store_ROUTES } from './store-layout.routes';
import { CheckoutLayoutComponent } from './layout/checkout-layout/checkout-layout.component';


const routes: Routes = [
  {
    path: 'store',
    component: StoreLayoutComponent,
    children: Store_ROUTES
  },
  {
    path: 'checkout',
    component: CheckoutLayoutComponent,
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: Main_ROUTES
  }
];

export const routerModuleForRoot = RouterModule.forRoot(routes, { enableTracing: false, initialNavigation: 'enabledBlocking', paramsInheritanceStrategy: 'always', anchorScrolling: 'enabled', scrollPositionRestoration: 'top' } );

@NgModule({
  imports: [routerModuleForRoot],
  exports: [RouterModule]
})
export class AppRoutingModule { }
