import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalContentTypeInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!req.headers.get('Content-Type')){
      const dupReq = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
      return next.handle(dupReq);
    } 
    if (req.headers.get('Content-type')==='multipart/form-data;'){
      const dupReq = req.clone({headers: req.headers.delete('Content-Type')});
      return next.handle(dupReq);
    }
    return next.handle(req);
  }
}
