<div style="height: 450px" class="card mt-4">
  <div *ngIf="!product.empty" class="card-body flex-column">
    <div style="height: 100px">
      <div (click)="logClickAndNavigate()" class="link-style" style="font-size: 16px; font-weight: bold;">{{product['_source'].title}}</div>
      <div style="color: #6fa453; font-size: 16px; font-weight: bold">
        In Stock
      </div>
    </div>

    <img (click)="logClickAndNavigate()" style="height: 175px" class="product-thumbnail pointer" [src]="product['_source'].image_product_thumbnail" />
    <div class="d-flex flex-row mt-4 mb-4 align-items-center">
      <input
        placeholder="1"
        name="quantity"
        style="width: 75px; border-radius: 5px; height: 25px; border: 1px solid #e9ab3f"
        class="mr-2"
        [(ngModel)]="quantity"
        autocomplete="off"
        type="number"
        min="1"
        step="1"
        id="quantity-input"
        tabindex="1"
        #quantityInput
      />
      <!-- <span *ngIf="price" class="flex-1 btn btn-md product-results-button product-results-instock">
        <fa-icon [icon]="faShippingFast" class="pr-1"></fa-icon>
        In Stock
      </span> -->
      <!-- <span *ngIf="!price" class="flex-1"></span> -->
      <div *ngIf="price" style="color: green; font-size: 16px; font-weight: bold;">${{price}} / ea</div>
      <div *ngIf="!price && !loading" style="color: orange; font-size: 14px; font-weight: bold;">Qty Not Available</div>
    </div>
    <button *ngIf="!loading" class="btn btn-primary btn-block btn-sm m-0" (click)="addPartToCart()" data-toast data-toast-type="success">
      Add to Cart
    </button>
    <button *ngIf="loading" class="btn btn-primary btn-block btn-sm m-0" data-toast data-toast-type="success" disabled>
      <div class="spinner-border spinner-border-sm text-white mr-2" role="status"></div>Add to Cart
    </button>
  </div>
</div>