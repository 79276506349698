import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { CartService } from '../../core/services/cart.service';

@Component({
  selector: '[app-cart-row-item]',
  templateUrl: './cart-row-item.component.html',
  styleUrls: ['./cart-row-item.component.scss']
})
export class CartRowItemComponent implements OnInit {
  @Input() item;    // TODO - figure out why this won't work:CartPayloadItemModel | OrderItemModel;
  @Input() expired:boolean;
  @Input() enableRemove:boolean;
  @Input() disableNavigate:boolean;
  @Input() deliveryRange:boolean;
  @Input() price:string;
  @Input() pricePerUnit:string;
  @Input() totalPrice:string;
  @Input() shippingDate:string;
  @Input() deliveryDate:string;
  @Input() failedMessage:string;
  @Output() removeFromCartEmitter = new EventEmitter();
  @Output() navigateBackToProductEmitter = new EventEmitter();

  faTimes = faTimes;
  faTimesCircle = faTimesCircle;
  faExclamationTriangle = faExclamationTriangle;

  constructor(
    private cartService: CartService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  removeFromCart(item) {
    this.removeFromCartEmitter.emit(item);
  }

  navigateToQuote(quoteItemQuantityBreakGuid) {
    this.cartService.getQuoteGuidByBreakGuid(quoteItemQuantityBreakGuid).subscribe((res:any) => {
      this.router.navigate([`/quotes/${res.guid}`])
    })
  }

  navigateBackToProduct(item) {
    this.navigateBackToProductEmitter.emit(item);
  }
}
