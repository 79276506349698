import { Component, OnInit, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { RecommendationService } from '../core/services/recommendation.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AnalyticsService } from '../analytics/analytics.service';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.css']
})
export class RecommendationsComponent implements OnInit {
  @Input() partNumber;
  @Input() type;
  @Input() context;
  @Input() header;

  recommendations = [];
  currentView = [];
  currentPage = 0;
  totalPages = 0;
  sentAnalytics = false;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  constructor(
    private recommendationService: RecommendationService,
    private analyticsService: AnalyticsService
  ) {
  }

  nextPage = () => {
    if (this.currentPage + 1 <= this.totalPages) {
      this.currentPage = this.currentPage + 1;
      this.currentView = this.recommendations.slice((this.currentPage * 4), (this.currentPage * 4 + 4))

      if (this.currentView.length != 4) {
        let fillers = 4 - this.currentView.length;
        this.currentView = this.currentView.concat(
          [...Array(fillers).keys()].map((i) => {
            return {
              empty: true
            }
          })
        )
      }
    }
  }

  previousPage = () => {
    if (this.currentPage >= 1) {
      this.currentPage = this.currentPage - 1;
      this.currentView = this.recommendations.slice((this.currentPage * 4), (this.currentPage * 4 + 4))
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (changes instanceof SimpleChange) {
      // console.log()

      let payload = <any>{
        type: this.type,
        context: this.context,
        quantity: 24,
      }
      if (this.context === 'product') payload.partNumber = changes.partNumber.currentValue;
  
      this.recommendationService.getRecommendations(payload).subscribe(response => {
        let copy = response.map(r => {
          if (r['_source'].product_type !== 'Accessories') {
            r.dynamic_img = `/oring-product-detail-image-id:${r["_source"].inside_diameter}mm-cs:${r["_source"].cross_section}mm-color:${r["_source"].color.toLowerCase()}.svg`;
          }
  
          return r;
        });
        this.recommendations = copy;
        this.currentView = copy.slice(0, 4);
  
        // once the currentView has been built, log these recommended product
        //   impressions to GA:
        if (!this.sentAnalytics) {
          const evt = {
            'ecommerce': {
              'impressions': []
            }
          };
          this.currentView.forEach((item, idx) => {
            const itemAnalytics = {
              'name': item.partNumber,
              'price': item.pricePerUnit,
              'brand': 'Marco',
              'category': 'O-Rings',
              'list': 'Recommendations',
              'position': idx
            };
            evt.ecommerce.impressions.push(itemAnalytics);
          });
          this.analyticsService.trackEcom(evt);
          this.sentAnalytics = true;
        }    

        if (this.currentView.length < 4) {
          let fillers = 4 - this.currentView.length;
  
          this.currentView = this.currentView.concat(
            [...Array(fillers).keys()].map((i) => {
              return {
                empty: true
              }
            })
          )
        }
  
        this.totalPages = Math.round(Math.ceil(response.length / 4)) - 1;
      });
    // 
    // this.doSomething(changes.categoryId.currentValue);
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
    
  }

  ngOnInit(): void {
    
  }
}
