import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationItemAccessoryComponent } from './recommendation-item-accessory.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    RecommendationItemAccessoryComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    RecommendationItemAccessoryComponent
  ]
})
export class RecommendationItemAccessoryModule { }
