<div #checkoutFlowTop class="no-gutters pb-3 mb-1 pt-1">



      <hr />

      <!-- Payment section -->
       <!-- End payment section -->

      <hr />

      <!-- Tax exemption Status Section -->
      <div class="d-flex flex-column" class="pt-3">
        <h5>Tax Exempt Certificate (Optional)</h5>
        <div class="table-responsive">
          <table class="table table-hover" *ngIf="taxExemptCertificates.length !== 0">
            <thead class="thead-default">
              <tr>
                <th colspan="4">Saved Certificates</th>
              </tr>
            </thead>
            <tbody>
              <tr class="pointer" *ngFor="let taxCert of taxExemptCertificates">
                <td class="align-middle" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <input name='taxExemptCertificate' type='checkbox' [id]='taxCert.id' [value]='taxCert.id'
                    [ngModel]='selectedTaxCertId === taxCert.id' (ngModelChange)='selectNewTaxExemptCert(taxCert.id, $event)' />
                  <label [for]='taxCert.id'></label>
                </td>
                <td (click)="selectNewTaxExemptCert(taxCert.id, $event)" class="align-middle" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <span class="text-gray-dark">
                    {{ taxCert.certificateNumber }}
                  </span>
                </td>
                <td (click)="selectNewTaxExemptCert(taxCert.id, $event)" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <span class="text-muted">{{ taxCert.fileName }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex flex-column mt-3 mb-5 pl-2">
            <span *ngIf="taxExemptCertificates.length === 0">
              You have no saved tax exempt certificates.
            </span>
            <span class="link-style pointer" (click)="addNewTaxExemptCert()">
              Enter a new Tax Exempt Certificate
            </span>
          </div>
        </div>

        <div class="d-flex pb-1" *ngIf="showTaxExemptCertFrom">
          <app-tax-exempt-certificate class="w-100" (taxExemptCertCreated)="taxExemptCertCreated($event)"
            (formVisibility)="toggleTaxExemptForm($event)"></app-tax-exempt-certificate>
        </div>
  
      </div> 

      <!-- end Tax exemption section -->

</div>

<button [disabled]="btnSpinny || (useNewCard() && !cardForm.valid)" id="payButton"
    class="btn btn-primary btn-block mb-5 d-block"
    (click)="billingComplete()">
        <fa-icon *ngIf="btnSpinny" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>
        Review Order
</button>

<!-- DEBUGGING  -->
<!-- 
<pre>solupayReady: {{ solupayReady }}</pre>
<pre>cardForm.valid: {{ cardForm.valid }}</pre>
<pre>solupayValidationPending: {{ solupayValidationPending }}</pre>
<pre>useCreditTerms: {{ useCreditTerms() }}</pre>
<pre>submitSolupayForm: {{ submitSolupayForm }}</pre>
<pre>{{ (checkoutService.checkoutCardSubject | async) | json }}</pre> -->
