<div class="d-flex flex-row form-group pl-0">
  <div class="col-sm-12 pl-0">
    <label for="ccnumber">Card Number</label>
    <div id="cc_number" class="solupayIframeContainers"></div>
    <div class="validationError" id="cc_number_err">{{ ccnumber.msg }}</div>
  </div>
</div>
<div class="d-flex flex-row form-group pl-0">
  <div class="col-sm-6 pl-0">
    <label for="ccexp">Expiration</label>
    <div id="cc_exp" class="solupayIframeContainers"></div>
    <div class="validationError" id="cc_exp_err">{{ ccexp.msg }}</div>
  </div>
  <div class="col-sm-6 pl-0">
    <label for="cvv">CVV</label>
    <div id="_cvv" class="solupayIframeContainers"></div>
    <div class="validationError" id="cc_cvv_err">{{ cvv.msg }}</div>
  </div>
</div> 
