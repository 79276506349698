import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { FilteredProductResult } from '../../shared/product-result.model';
import { PricingApiService } from './pricing-api.service';
import { Page } from '../../shared/page.model';
import { ActivatedRoute, Router } from '@angular/router';

const CACHE_SIZE = 1;

@Injectable({ providedIn: 'root' })
export class CatalogProductService {
  private productResult = new FilteredProductResult();
  public productResultChangeSubject = new BehaviorSubject(this.productResult);

  public page: Page = new Page();
  public pageChangeSubject = new BehaviorSubject(this.page);

  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor(
    private apiService:PricingApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('offset')) {
        this.page.pageNumber = parseInt(queryParams.get('offset'));
      } 
    })
  }

  public getProducts(query) {
    query.pageNum = this.page.pageNumber ? this.page.pageNumber - 1 : 0;
    query.numberOfResults = this.page.pageSize;
    query.exclusionKeys = ["vendor_inventories", "quantities.vendor_code"];

    const productsObserver = this.requestFilteredProducts(query).pipe(
      catchError(this.formatErrors)
    );

    productsObserver.subscribe(res => {
      if (res && res.hits && res.hits.hits.length) {
        this.productResult = res;
        this.productResultChangeSubject.next(this.productResult)
        this.page.totalElements = res.hits.total.value;
        this.page.totalPages = res.hits.total.value / this.page.pageSize;
        this.pageChangeSubject.next(this.page);
      } else {
        this.productResult = res;
        this.productResultChangeSubject.next(this.productResult);
        this.page.totalElements = 0;
        this.page.totalPages = 0;
        this.pageChangeSubject.next(this.page);

        if(query.pageNum && query.pageNum > 0){
          let queryParams = Object.assign({}, this.activatedRoute.snapshot.queryParams);
          delete queryParams.offset;
          this.page.pageNumber = 0;
          this.router.navigate([], { queryParams });
        }
      }
    },
    err => console.log("ERROR: ", err));
    return this.productResultChangeSubject;
  }

  public updatePageNumber(pageNum) {
    this.page.pageNumber = pageNum;
    this.pageChangeSubject.next(this.page);
  }

  public getCached() {
    return this.productResultChangeSubject;
  }

  public requestFilteredProducts(query) {
    return this.apiService.post(`/product/query`, query);
  }


  public pageChanged(event) {
    this.loadPage({offset: event.page})
  }

  public loadPage(pageInfo) {

    let queryParamsObject = Object.assign({}, this.activatedRoute.snapshot.queryParams);

    queryParamsObject['offset'] = pageInfo.offset; // this

    if(pageInfo.offset == 1 && queryParamsObject['offset']==1){
      delete queryParamsObject['offset'];
    } else if(pageInfo.offset && (pageInfo.offset != queryParamsObject['offset'])){
      this.updatePageNumber(pageInfo.offset);
      queryParamsObject['offset'] = pageInfo.offset;
    }

    if (pageInfo.offset === 0) {
      delete queryParamsObject['offset'];
    } else {
      this.router.navigate([], { queryParams: queryParamsObject });
    }
  }


}

