import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';
import dayjs from 'dayjs';

import { PricingApiService } from './pricing-api.service';
import { CartService } from './cart.service';

import { CheckoutDetailModel, CheckoutDetailsPayloadModel } from '../../shared/checkout-detail.model';

export interface CardCheckoutDetails {
  loading: boolean;  
  cardDetails?: {
    cardHolderName: string; // the cardholder name; used for order submission on review step
  };
  paymentToken?: {
    token: string;     // a safe, short lived paymemt token representing a valid card / auth.  Used to submit the order later
    card: {
      type: string;    // eg 'visa', 'mastercard', 'amex', etc; used to parrot back to user during review
      number: string;  // a safe, obfuscated represntation of the card number; eg 41111 ************ 1111 
    }
  };
}


@Injectable({ providedIn: 'root' })
export class CheckoutService { 
  private formatErrors(error: any) {
    return  throwError(error);
  }

  private checkoutDetails: CheckoutDetailModel;
  public checkoutDetailsSubject: BehaviorSubject<CheckoutDetailModel | null> = new BehaviorSubject<CheckoutDetailModel | null>(null);
  public checkoutCardSubject: BehaviorSubject<CardCheckoutDetails> = new BehaviorSubject<CardCheckoutDetails>({ loading: true });

  constructor (
    private apiService: PricingApiService, 
    private cartService: CartService,
  ) {}

  
  public initializeCheckoutDetails(cart){
    if (!cart) return;
    if (cart.checkoutDetailId) {
      this.getCheckoutDetails(cart.checkoutDetailId).subscribe((res)=>{
        return res;
      });
    } else if (cart.cartId) {
      this.createCheckoutDetails(cart.cartId).subscribe((res)=>{
        this.cartService.getCart();
        return res;
      });
    }
  }


  public getCheckoutDetails(checkoutDetailId) {
    const cartObserver = this.apiService.get('/checkout/'+checkoutDetailId);

    cartObserver.subscribe(
      res => {
        this.checkoutDetails = res;
        this.checkoutDetailsSubject.next(this.checkoutDetails);
      },
      err => {
        console.log("ERROR: ", err);
      }
    );

    return this.checkoutDetailsSubject;
  }


  public checkoutDetailsExpired(details) {
    if(details.expiresAt){
      // if we are past the expiration time
      if(dayjs() > dayjs(details.expiresAt)){
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  public deleteCheckoutDetails(cartId: number, checkoutDetailId:number): Observable<any> {
    this.checkoutDetails = null;
    this.checkoutDetailsSubject.next(this.checkoutDetails);

    this.cartService.deleteCheckoutDetails();

    return this.apiService.post('/checkout/delete', {
      cartId:cartId,
      checkoutDetailId:checkoutDetailId
    }).pipe(
      catchError(this.formatErrors)
    );
  }


  private createCheckoutDetails(cartId) {
    return this.apiService.post('/checkout', {
      cartId:cartId
    }).pipe(shareReplay());
  }

  public updateCheckoutDetails(checkoutDetails:CheckoutDetailsPayloadModel) {
    checkoutDetails.expiresAt = dayjs().add(6, 'hours').toDate();
    this.apiService.put('/checkout', checkoutDetails).subscribe((res)=>{
      this.getCheckoutDetails(checkoutDetails.checkoutDetailId);
    });
  }

  public clearCheckoutDetails() {
    this.checkoutDetails = null
    this.checkoutDetailsSubject = new BehaviorSubject(this.checkoutDetails);
  }
}
