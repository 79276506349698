<ng-container>
  <div class="pb-1">
      <table class="table table-hover">
        <tbody>
          <tr class="pointer" *ngFor="let address of addresses">
            <td class="align-middle" [ngClass]="address.customer_address_id === selectedAddressId ? 'selected-blue' : ''">
              <input name='shippingAddress' type='radio' [id]='address.customer_address_id'
                [value]='address.customer_address_id' [ngModel]='selectedAddressId'
                (ngModelChange)='selectNewAddress(address.customer_address_id)' />
              <label [for]='address.customer_address_id'></label>
            </td>
            <td (click)="selectNewAddress(address.customer_address_id)" class="align-middle"  [ngClass]="address.customer_address_id === selectedAddressId ? 'selected-blue' : ''">
              <span class="text-gray-dark">
                <b>{{ address.first_name }} {{ address.last_name }}</b><br>
                {{ address.address1 }}
                {{address.city}}, {{ address.state}} {{ address.zipCode }}
              </span>
            </td>
            <td [ngClass]="address.customer_address_id === selectedAddressId ? 'selected-blue' : ''"><span class="text-muted">{{ address.nickname }}</span></td>
            <td class="link-style" (click)="assignEditingAddress(address)"  [ngClass]="address.customer_address_id === selectedAddressId ? 'selected-blue' : ''">Edit</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-12" *ngIf="addresses.length === 0 && !showAddressForm">
          You have no saved shipping addresses.  Please 
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="link-style pointer" (click)="addNewAddress()" *ngIf="!showAddressForm">
            Enter a new shipping address
          </span>
        </div>
      </div>
  </div>
</ng-container>

<div class="add-flex padding-bottom-1x" *ngIf="showAddressForm">
  <app-new-address class="w-100" [address]="editingAddressSubject" [addressType]="'shipping'" (addressCreated)="addressCreated($event)" (formVisibility)="toggleAddressForm($event)"></app-new-address>
</div>

<!-- <div class="row py-2">
  <button (click)="wizard.goStep(CheckoutStepEnum.NEW_ADDRESS)" class="btn btn-outline-primary">
    <fa-icon class="pr-2" [icon]="faPlus"></fa-icon>Add New Address
  </button> <br />
</div> -->
<div class="row my-5">
  <div class="col-12">
    <button *ngIf="!showAddressForm && selectedAddressId" (click)="wizard.goStep(CheckoutStepEnum.SHIPPING_METHOD)" 
      class="btn btn-primary">Use Selected Address</button>
  </div>
</div>