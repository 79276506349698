export class LightUserModel {
  first_name: string;
  last_name: string;
  email: string;
}


export class UserModel {
  addresses: object[];
  company_id: number;
  company_name?: string;
  created: Date;
  customer_id: number;
  customer_type: string;
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone_number: string;
  timezone: string;
  title: string;
  user_name: string;
  salesAttribution?: string;
  attribution_key?: string;

  constructor(
    data?: Partial<UserModel>
  ) {
    if (data) Object.assign(this, data);
  }
}