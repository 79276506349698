import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";

@Injectable({ providedIn: 'root' })
export class SentryService {
  constructor (
  ) { }

  public attachUser(user) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email
      });
    });
  }

  public captureShippingError(errorObject) {
    Sentry.captureException(errorObject);
  }
}
