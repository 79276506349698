import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserModel } from '../../shared/user.model';
import { CustomerModel, CustomerPasswordUpdateModel } from '../../shared/customer.model';
import { LoginModel } from '../../shared/login.model';
import { ResetPasswordModel } from '../../shared/reset-password.model';
import { ForgotPasswordModel } from '../../shared/forgot-password.model';
import { CustomerApiService } from './customer-api.service';
import { SentryService } from './sentry.service';
import { JwtHelperService } from '@auth0/angular-jwt';


const drift = window["drift"];

@Injectable({ providedIn: 'root' })
export class CustomerService {
  private user = new UserModel();
  
  public userSubject = new BehaviorSubject(this.user);

  private formatErrors(error: any) {
    return throwError(() => new Error(error));
  }

  constructor (
    private apiService: CustomerApiService,
    private sentryService: SentryService,
    private jwtHelper: JwtHelperService
  ) { 
    
  }


  public hasCustomerToken() {
    const decoded = this.jwtHelper.decodeToken();
    let customerIdStr;
    if (decoded?.user?.customer_id) {
      customerIdStr = decoded.user.customer_id;
    }
    return customerIdStr;
  }


  getCustomer() {
    // only load the customer if there's localstorage and a token (there's no localstorage 
    //  on locked-down browsers (v. rare) AND in SSR
    if (this.hasCustomerToken()) {
      this.apiService.get('/customer').subscribe(user => {
        // as soon as we get a customer, attach them to Sentry session:
        if (user && user.email) {
          this.sentryService.attachUser(user.email);
        }
        if (user && drift) {
          const driftUser = { 
            email: user.email,
            name: user.first_name ? user.first_name : "" + user.last_name ? user.last_name : ""
          };
          console.log("drift user ", driftUser);
          drift.identify(user.id, driftUser)
        }
        this.user = new UserModel(user);
        this.userSubject.next(this.user);
      });
    }
  }

  getCustomerAttributionUrl(url){
    if(this.user && this.user.attribution_key){
      if(url.indexOf('?')>0){
        return url + `&ordsrc=${this.user.attribution_key}`;
      } else {
        return url + `?ordsrc=${this.user.attribution_key}`;
      }
      
    } else {
      return '';
    }
  }

  clearCustomer() {
    this.user = new UserModel();
    this.userSubject.next(this.user);
  }

  getEntitlement(entitlementKey){
    return this.apiService.get('/entitlement/' + entitlementKey).pipe(
      catchError(this.formatErrors)
    );
  }

  getCustomerBasicDetails(): Observable<any> {
    return this.apiService.get('/customer/basic').pipe(
      catchError(this.formatErrors)
    );
  }

  getAddresses(): Observable<any> {
    return this.apiService.get('/customer_address').pipe(
      catchError(this.formatErrors)
    );
  }

  getShippingAccounts(): Observable<any> {
    return this.apiService.get('/customer_shipping_account').pipe(
      catchError(this.formatErrors)
    );
  }

  getShippingAccountById(shippingAccountId): Observable<any> {
    return this.apiService.get('/customer_shipping_account/' + shippingAccountId).pipe(
      catchError(this.formatErrors)
    );
  }

  createShippingAccount(shippingAccount) {
    return this.apiService.post('/customer_shipping_account', shippingAccount);
  }

  updateShippingAccount(shippingAccount) {
    return this.apiService.put('/customer_shipping_account', shippingAccount);
  }

  deleteShippingAccount(customerShippingAccountId): Observable<any> {
    return this.apiService.delete('/customer_shipping_account/' + customerShippingAccountId).pipe(
      catchError(this.formatErrors)
    );
  }

  hasTerms(): Observable<any> {
    return this.apiService.get('/customer/has_terms').pipe(
      catchError(this.formatErrors)
    );
  }

  getTaxExemptCertificates(): Observable<any> {
    return this.apiService.get('/tax_exempt_certificate').pipe(
      catchError(this.formatErrors)
    );
  }

  getTaxExemptCertificateById(taxExemptCertId): Observable<any> {
    return this.apiService.get('/tax_exempt_certificate/' + taxExemptCertId).pipe(
      catchError(this.formatErrors)
    );
  }

  createTaxExemptCertificate(taxExemptCert) {
    return this.apiService.post('/tax_exempt_certificate', taxExemptCert);
  }

  updateTaxExemptCertificate(taxExemptCert) {
    return this.apiService.put('/tax_exempt_certificate', taxExemptCert);
  }

  deleteTaxExemptCertificate(taxExemptCertId): Observable<any> {
    return this.apiService.delete('/tax_exempt_certificate/' + taxExemptCertId).pipe(
      catchError(this.formatErrors)
    );
  }

  deleteAddresses(customerAddressId): Observable<any> {
    return this.apiService.delete('/customer_address/' + customerAddressId).pipe(
      catchError(this.formatErrors)
    );
  }

  removeSavedCard (){
    return this.apiService.delete('/customer/saved_card').pipe(
      catchError(this.formatErrors)
    );
  }

  validateEmail(email: string): Observable<any> {
    return this.apiService.get('/customers/exists/' + email).pipe(
      catchError(this.formatErrors)
    );
  }

  updatePassword(customerPasswordUpdatData: CustomerPasswordUpdateModel): Observable<any> {
    return this.apiService.put('/customers/password', customerPasswordUpdatData).pipe(
      catchError(this.formatErrors)
    );
  }

  forgotPassword(forgotPasswordData: ForgotPasswordModel): Observable<any> {
    return this.apiService.post('/login/forgot_password', forgotPasswordData).pipe(
      catchError(this.formatErrors)
    );
  }

  resetPassword(resetPasswordData: ResetPasswordModel): Observable<any> {
    return this.apiService.post('/login/reset_password', resetPasswordData).pipe(
      catchError(this.formatErrors)
    );
  }

  post(customer: CustomerModel) {
    return this.apiService.post('/customers', customer);
  }

  updateAddress(address) {
    return this.apiService.put('/customer_address', address);
  }

  createAddress(address) {
    return this.apiService.post('/customer_address', address).pipe(
      catchError(this.formatErrors)
    );
  }

  put(customer: any) {
    return this.apiService.put('/customers', customer);
  }

  registerGuest() {
    return this.apiService.post('/register_guest');
  }

  refreshToken() {
    return this.apiService.post('/refresh_token');
  }

  refreshGuestToken() {
    return this.apiService.post('/refresh_guest_token');
  }

  login(loginData: LoginModel) {
    return this.apiService.post('/login', loginData);
  }

}
