
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { AuthService } from './core/services/auth.service';
import { SalesAttributionModel } from './shared/sales-attribution.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private mouseMovement = new Subject();
  private tokenSubscription: Subscription;
  title = 'exp-web-ui';

  constructor(
      @Inject(AuthService) private authService: AuthService,
      @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
      @Inject(Router) private router: Router
    ) {
      this.authService.initializeUser();
  }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let salesAttribution;
      const ordSrc = params['ordsrc'];
      if (ordSrc) {
        salesAttribution = new SalesAttributionModel(this.router.url, ordSrc, SalesAttributionModel.getDefaultAttributionEnd());
      }
      this.authService.setAttribution(salesAttribution);

      this.tokenSubscription = this.authService.hasTokenSubject.subscribe(hasToken => {
        if (hasToken) { // if no token, we have nothing to attribute the traffic to
          this.authService.setUserAttribution();
        }
      });
    });

    this.mouseMovement.pipe(
      debounceTime(60000)
    ).subscribe(e => this.refreshToken());

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.mouseMovement.next(e);
  }

  refreshToken() {
    this.authService.checkToken();
  }
}
