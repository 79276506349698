import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { CheckoutWizardService } from 'src/app/checkout/checkout-wizard.service';



@Component({
  selector: 'app-checkout-layout',
  templateUrl: './checkout-layout.component.html',
  styleUrls: ['./checkout-layout.component.scss']
})
export class CheckoutLayoutComponent implements OnInit {

  dayjs = dayjs;
  
  constructor(public wizard:CheckoutWizardService) { }

  ngOnInit(): void {
  }

}
