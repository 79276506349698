import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomerService } from '../../core/services/customer.service';
import { ErrorMessage } from '../../shared/error-message.model';
import { AuthService } from '../../core/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SignInComponent implements OnInit {
  validEmail:boolean = false;
  loaded:boolean = false;
  customerLoginExpired: Observable<boolean>;

  loginError:boolean = false;
  loginErrorMessage:string = '';

  form: FormGroup;
  returnTo = '/checkout/shipping-address';

  customErrorMessages: ErrorMessage[] = [
    {
      error: 'areEqual',
      format: (label, error) => `${label} does not match`
    }
  ];

  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor(
    private authService: AuthService,
    private customerService:CustomerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { 

    this.customerLoginExpired = authService.customerLoginExpired().pipe(catchError(this.formatErrors));
    
    this.form = this.formBuilder.group({
      email:['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.maxLength(20)]]
    });

    this.route.queryParams.subscribe(params => {
      if (params.returnTo)
        this.returnTo = params.returnTo;
    });

  }

  ngOnInit() {
  }

  validateEmail() {
    if (this.form.value.email !== '' && this.form.value.email) {
      this.customerService.validateEmail(this.form.value.email).subscribe(res => {
        if (res && res.id && res.type !== 'guest') {
          this.validEmail = res;
        } else {
          this.validEmail = false;
        }
        this.loaded = true;
      })
    }
  }
}
