import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { debounceTime } from 'rxjs/operators';
import { CartService } from '../../core/services';
import { CartPayloadRfqModel } from '../cart-payload.model';

@Component({
  selector: 'app-cart-row-rfq-item',
  templateUrl: './cart-row-rfq-item.component.html',
  styleUrls: ['./cart-row-rfq-item.component.scss']
})
export class CartRowRfqItemComponent implements OnInit {

  faFileAlt = faFileAlt;
  faTimesCircle = faTimesCircle;

  @Input('item') item: FormGroup; 
  @Input('edit') edit:boolean = false;
  @Output() removeFromCartEvent = new EventEmitter();

  constructor(
    private cartService: CartService,
    public controlContainer: ControlContainer
  ) { 
    this.item = this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {

    // add a listener to the notes field to update this rfqItem
    //  back to the api after 1s of typing cessation
    this.item.get('comment').valueChanges
      .pipe(debounceTime(1000))
      .subscribe(change => {
        this.updateRfqItem(change, this.item.get('rfqItemId').value, 'comment');
    });
  }

  removeFromCart(item) {
    this.removeFromCartEvent.emit(item);
  }

  removeRfqFromCart(item) {
    this.removeFromCartEvent.emit(item);
  }


  isFieldValid(item, controlName) {
    return !item.get(controlName).valid && item.get(controlName).touched
  }

  updateRfqItem(ev, rfqItemId, key: string) {
    if (!this.item.valid) {
      return;
    }
    const rfqItemPayload = new CartPayloadRfqModel();

    rfqItemPayload['rfqItemId'] = parseInt(rfqItemId) ? rfqItemId : this.item.get('rfqItemId').value;
    rfqItemPayload[key] = ev.target ? ev.target.value : ev;

    this.cartService.updateRfqItemInCart(rfqItemPayload);
  }


}
