import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NewAddressComponent } from './new-address/new-address.component';
import { SigninFormComponent } from './signin/signin.component';
import { RegisterFormComponent } from './register-form/register-form.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { ShippingAccountFormComponent } from './shipping-account-form/shipping-account-form.component';
import { TaxExemptCertificateComponent } from './tax-exempt-certificate/tax-exempt-certificate.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    NewAddressComponent,
    SigninFormComponent,
    RegisterFormComponent,
    ChangePasswordFormComponent,
    ForgotPasswordFormComponent,
    ShippingAccountFormComponent,
    TaxExemptCertificateComponent,
    FeedbackComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    NgBootstrapFormValidationModule.forRoot(),
  ],
  exports: [
    NewAddressComponent,
    SigninFormComponent,
    RegisterFormComponent,
    ChangePasswordFormComponent,
    ForgotPasswordFormComponent,
    ShippingAccountFormComponent,
    TaxExemptCertificateComponent,
    FeedbackComponent
  ]
})
export class SharedFormsModule { }
