<div class="breadcrumbs-container">
  <ul class="breadcrumbs">
    <li class="d-flex align-items-center">
      <span (click)="navigateCheckoutStep(CheckoutStepEnum.CHOOSE_ADDRESS)" [ngClass]="wizard.currentStep.step === CheckoutStepEnum.CHOOSE_ADDRESS ? '':'active-link'" routerLinkActive="active-link">Shipping Address</span>
    </li>
    <li class="d-flex align-items-center">
      <fa-icon class="mx-2" [icon]="faChevronRight" size="xs"></fa-icon>
      <span (click)="navigateCheckoutStep(CheckoutStepEnum.SHIPPING_METHOD)" [ngClass]="wizard.currentStep.step === CheckoutStepEnum.SHIPPING_METHOD ? '':'active-link'" routerLinkActive="active-link">Shipping Method</span>
    </li>
    <li class="d-flex align-items-center">
      <fa-icon class="mx-2" [icon]="faChevronRight" size="xs"></fa-icon>
      <span (click)="navigateCheckoutStep(CheckoutStepEnum.REVIEW)" [ngClass]="wizard.currentStep.step === CheckoutStepEnum.REVIEW ? '':'active-link'">Payment & Review</span>
    </li>
  </ul>
</div>