<div class="user-header-container d-flex justify-content-between py-2 px-4">
    <div class="d-flex justify-content-start">
        <img class="avatar mr-3" src="/assets/img/logo/user_login.svg">
        <div>
            <a class="user-name" [routerLink]="['/profile/main']">{{ (customerService.userSubject | async)?.first_name }} {{ (customerService.userSubject | async)?.last_name}}</a><br/>
            <span class="user-email">{{ (customerService.userSubject | async)?.email }}</span>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <a [routerLink]="['/profile/main']">Account</a>
    </div>
</div>
