import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../core/services/customer.service';
import { CustomValidator } from '../../shared/validators';
import { FormAddressModel } from '../../shared/address.model';
import { upperFirst } from 'lodash-es';
import { ShippingApiService } from '../../core/services/shipping-api.service';
import { NotificationService } from '../../core/services/notification.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-new-address',
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.scss']
})
export class NewAddressComponent implements OnInit {
  @Input() addressType: string;
  @Input() address;
  @Output() addressCreated = new EventEmitter();
  @Output() formVisibility = new EventEmitter();

  @ViewChild('addressConfirmTemplate') addressConfirmTemplate

  poBoxRegex = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#) *\d+)/i;

  addressTitle: string = "";
  form: FormGroup;
  addressChanges : boolean = false;
  validatingAddress: boolean = false;
  addressCorrections: any = {};
  poBoxWarning = false;
  faTimes = faTimes;
  addressConfirmModalRef?: BsModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private shippingApiService: ShippingApiService,
    private notificationService: NotificationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({ 
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required, CustomValidator.phoneNumberValidator]],
      company: [null],
      nickname: [null],
      country: [{ value: null, disabled: true }, [Validators.required]],
      state: [null, [Validators.required]],
      city: [null, [Validators.required]],
      zipCode: [null, [Validators.required, Validators.minLength(5)]],
      address1: [null, [Validators.required]],
      address2: [null],
      addressType: [null],
      customerId: [null],
      customerAddressId: [null],
      isResidential: [null],
      validationOverridden: [null]
    });

    this.address.subscribe(address => {
      this.initializeForm(address);
    });
  }


  regexAddressForPoBox() {
    if (this.form.value.address1.match(this.poBoxRegex)) {
      return true;
    }
    return false;
  }

  initializeForm(addressToInitialize) {
    if (!addressToInitialize) {
      const address = new FormAddressModel();
      address.customerAddressId = null;
      address.customerId = null;
      address.addressType = null;
      address.address1 = null;
      address.address2 = null;
      address.city = null;
      address.state = null;
      address.zipCode = null;
      address.firstName = null;
      address.lastName = null;
      address.phoneNumber = null;
      address.company = null;
      address.nickname = null;
      address.country = null;
      address.isResidential = null;
      address.validationOverridden = null;
      this.form.setValue(address);

      if (this.addressType) {
        this.form.patchValue({
          addressType:this.addressType,
          country:'USA'
        });
  
        this.addressTitle = `Enter a New ${upperFirst(this.addressType)} Address`;
      } else {
        this.addressTitle = `Enter a New Address`;
      }
    } else {
      const address = new FormAddressModel();
        
      address.customerAddressId = addressToInitialize.customer_address_id || "",
      address.customerId = addressToInitialize.customer_id || "",
      address.addressType = addressToInitialize.address_type || "",
      address.address1 = addressToInitialize.address1 || "",
      address.address2 = addressToInitialize.address2 || "",
      address.city = addressToInitialize.city || "",
      address.state = addressToInitialize.state || "",
      address.zipCode = addressToInitialize.zip_code || "",
      address.firstName = addressToInitialize.first_name || "",
      address.lastName = addressToInitialize.last_name || "",
      address.phoneNumber = addressToInitialize.phone_number || "",
      address.company = addressToInitialize.company || "",
      address.nickname = addressToInitialize.nickname || "",
      address.country = addressToInitialize.country || "",
      address.isResidential = addressToInitialize.isResidential || "",
      address.validationOverridden = addressToInitialize.validationOverridden || 0,
      this.form.setValue(address);
      this.addressTitle = "Edit Address"
    }
  }

  close() {
    this.formVisibility.emit(false);
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  updateOrCreateAddress(formValuesWithDisabledFields, result) {
    formValuesWithDisabledFields.isResidential = result.isResidential;
    // If there is a customer ID present in the form, we are updating a preexisting address
    if (formValuesWithDisabledFields.customerAddressId) {
      this.customerService.updateAddress(formValuesWithDisabledFields).subscribe(result => {
        if (result) {
          this.validatingAddress = false;
          this.addressCreated.emit({
            address: result,
            type: 'update'
          });

          this.form.reset();
          this.notificationService.broadcastSuccessNotification({
            message: 'Successfully updated address.',
            options: {
              timeout: true
            }
          });
        }
      }, err => {
        console.log("There was an error updating your address", err);
        this.validatingAddress = false;
        this.notificationService.broadcastFailureNotification({message: "There was an error updating your address"});
      })
    } else {
      this.customerService.createAddress(formValuesWithDisabledFields).subscribe(result => {
        if (result) {
          this.validatingAddress = false;
          this.addressCreated.emit({
            address: result,
            type: 'create'
          });

          this.form.reset();
          this.notificationService.broadcastSuccessNotification({
            message: 'Successfully added address.',
            options: {
              timeout: true
            }
          });
        }
      }, err => {
        console.log("There was an error creating your address", err);
        this.validatingAddress = false;
        this.notificationService.broadcastFailureNotification({message: "There was an error creating your address"});
      });
    }
  }

  lastResult;

  validateAddress() {
    this.poBoxWarning = false;
    this.validateAllFormFields(this.form);
    if (this.form.invalid) {
      console.log("invalid form", this.form);
      return;
    }

    if (this.form.value.addressType === 'shipping' && this.regexAddressForPoBox()) {
      this.poBoxWarning = true;
      return;
    }

    this.validatingAddress = true;
    this.addressChanges = false;
    this.addressCorrections = {};

    let formValuesWithDisabledFields = this.form.getRawValue();

    this.shippingApiService.validateAddress(formValuesWithDisabledFields).subscribe(result=>{
      this.lastResult = cloneDeep(result);
      if(result.valid) {
        this.validatingAddress = false;
        this.save();
      } else { // invalid address according to shippo
        this.validatingAddress = false;
                
        this.openAddressWarningModal(this.addressConfirmTemplate);
        
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
      }
    },
    (err) => {
      console.log("error getting address validation data", err);
      this.validatingAddress = false;
      this.notificationService.broadcastFailureNotification({message: "There was an error validating your address"});
    });
  }


  save() {
    let formValuesWithDisabledFields = this.form.getRawValue();

    // There are fields that we 'corrected' for you
    if(this.lastResult.correctedAddressFields && Object.keys(this.lastResult.correctedAddressFields).length > 0){
      let addressChangeBool = false;
      Object.keys(this.lastResult.correctedAddressFields).forEach((addressField)=>{
        if (addressField !== 'zipCode' || (formValuesWithDisabledFields.zipCode.length === 5 && formValuesWithDisabledFields.zipCode !== this.lastResult.correctedAddressFields["zipCode"].split('-')[0])) {
          this.addressCorrections[addressField] = true;
          addressChangeBool = true;
        }
        this.form.controls[addressField].setValue(this.lastResult.correctedAddressFields[addressField]);
        formValuesWithDisabledFields[addressField] = this.lastResult.correctedAddressFields[addressField];
      })
      if (addressChangeBool) {
        this.addressChanges = addressChangeBool;
      } else {
        this.updateOrCreateAddress(formValuesWithDisabledFields, this.lastResult);
      }
    // Your address is valid, and we didnt need to change anything
    } else {
      this.updateOrCreateAddress(formValuesWithDisabledFields, this.lastResult);
    }

  }


  openAddressWarningModal(template: TemplateRef<any>) {
    this.addressConfirmModalRef = this.modalService.show(template, {class: 'modal-sm'});
  }
 
  confirmAddressCorrect(): void {
    console.log('Confirmed!');
    this.addressConfirmModalRef?.hide();
    this.form.get('validationOverridden').setValue(1);
    this.save();
  }
 
  declineAddress(): void {
    console.log('Declined!');
    this.addressConfirmModalRef?.hide();
  }


}
