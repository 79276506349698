import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecommendationsComponent } from './recommendations.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecommendationItemModule } from '../recommendation-item/recommendation-item.module';
import { RecommendationItemAccessoryModule } from '../recommendation-item-accessory/recommendation-item-accessory.module';

@NgModule({
  declarations: [
    RecommendationsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RecommendationItemModule,
    RecommendationItemAccessoryModule
  ],
  exports: [
    RecommendationsComponent
  ]
})
export class RecommendationsModule { }
