import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShippingApiService {
  constructor(
    private http: HttpClient,
  ) {}

  private formatErrors(error: any) {
    return  throwError(error);
  }

get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.API_URL}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }


  validateAddress(body: Object = {}): Observable<any> {
    return this.http.post(`${environment.API_URL}`+'/shipping/validate_address', JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }
}


