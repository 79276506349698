import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalContentTypeInterceptorService } from './global-content-type-interceptor.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalContentTypeInterceptorService, multi: true }
  ]
})
export class GlobalContentTypeInterceptorModule { }
