import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../core/services/notification.service';
import { NotificationModel } from '../shared/notification.model';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  faTimes = faTimes;

  notification: NotificationModel = null;

  constructor(
    private notificationService:NotificationService
  ) {
    notificationService.notificationSubject.subscribe(notification => {
      if (!notification) {
        this.notification = null;
      } else {
        this.notification = notification;
        this.notification.options.layout = this.notification.options.layout || 'bottom';
  
        if (notification.options && notification.options.timeout) {
          setTimeout(() => {
            this.close();
          }, 5000);
        }
      }
    })
  }

  ngOnInit() {
  }

  close() {
    this.notificationService.clearNotification();
  }
}
