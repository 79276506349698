<!-- minimal checkout header; full width no gutters -->
<div class="container-lg">
    <div class="row header-row mt-3 mb-3 justify-content-center no-gutters">
        <img class="header-img" src="/assets/img/logo/marco_logo.svg">
    </div>
    <hr />
    <div class="row no-gutter">
        <app-checkout-user-heading class="w-100"></app-checkout-user-heading>
    </div>
    <app-notification></app-notification>
</div>

<div class="container-lg">
    <div class="row">
        <div class="col mt-5">
            <h3>{{ wizard.currentStep.stepTitle }}</h3>
        </div>
    </div>
    <div class="row" *ngIf="wizard.currentStep.showBreadcrumbs">
        <div class="col mb-4">      
            <app-checkout-breadcrumbs></app-checkout-breadcrumbs>
        </div>
    </div>    
</div>

<div class="container-lg">
    <div class="row">
        <!-- main page content -->
        <div class="col-md-8">
            <router-outlet></router-outlet>
        </div>
        <!-- cart summary -->
        <div class="col-md-4 pt-xs-5" *ngIf="!wizard.isCartEmpty()">
            <app-cart-summary-card></app-cart-summary-card>
        </div>
    </div>
</div>

<!-- minimal checkout footer -->
<div id="foot" class="container-lg mt-5 footer-light-row">
    <div class="row">
        <div class="col-md-6 checkout-footer-image">
            <img class="footer-logo-img" src="/assets/img/logo/marco_logo_ko.svg">
        </div>
        <div class="col-md-6 text-white checkout-footer-text">
            Copyright {{ dayjs().year() }} Marco Rubber and Plastics.<br>
            All Rights Reserved
        </div>
    </div>
</div>