import { FormControl, FormGroup } from '@angular/forms';

export class CustomValidator {
  // Validates phone number
  // Matches (123)-123-1234, 123-123-1234, 1231231234
  static phoneNumberValidator(number: any) {
    if (number.pristine) {
      return null;
    }
    const phoneNumberRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
    number.markAsTouched();
    if (phoneNumberRegex.test(number.value)) {
        return null;
    }
    const intlPhonNumberRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
    if (intlPhonNumberRegex.test(number.value)) {
      return null;
    }
    return {
        invalidNumber: true
    };
  }

  // Validates email address
  static emailAddressValidator(email: any) {
    if (email.pristine) {
      return null;
    }
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    email.markAsTouched();
    if (emailRegex.test(email.value)) {
        return null;
    }
    return {
        invalidEmail: true
    };
  }

  static validatePasswordsComplexity(password: FormControl) {

    // Complexity rules:
    //    at least 1 capital letter
    var hasUpperCase = /[A-Z]/.test(password.value);
    //    at least 1 number
    var hasNumbers = /\d/.test(password.value);
    //    at least 1 special character
    var hasNonalphas = /\W/.test(password.value);
    
    if (!hasUpperCase || !hasNumbers || !hasNonalphas) {
      return {
        complexity: true
      }
    }
  }




  static validatePasswordsAreEqual(formGroup: FormGroup) {
    var pwCtl = formGroup.controls.password;
    var cpwdCtl = formGroup.controls.confirmPassword;

    const valid:boolean = cpwdCtl.value === pwCtl.value;
    if (!valid) {
      cpwdCtl.setErrors( {areEqual: true });
    }
    return valid;
  }
}