import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { CheckoutService } from '../../core/services/checkout.service';
import { CartService } from '../../core/services/cart.service'
import { DateService } from '../../core/services/date.service';
import { CustomerService } from '../../core/services/customer.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderModel } from '../../shared/order.model';
import { CheckoutDetailModel } from '../../shared/checkout-detail.model';
import { AuthService } from '../../core/services/auth.service';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { NotificationService } from '../../core/services/notification.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { FormAddressModel } from 'src/app/shared/address.model';
import { CheckoutStepEnum, CheckoutWizardService } from '../checkout-wizard.service';
import { CartPayloadRfqModel } from 'src/app/shared/cart-payload.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  @ViewChild("checkoutFlowTop", {static: true}) checkoutFlowTop: ElementRef;

  @ViewChild('ccDeleteTemplate') ccDeleteTemplate

  faChevronLeft = faChevronLeft;
  faCreditCard = faCreditCard;
  faInfoCircle = faInfoCircle;
  faCheck = faCheck;
  faCircleNotch = faCircleNotch;
  CheckoutStepEnum = CheckoutStepEnum;

  faTimes = faTimes;

  cardForm: FormGroup;
  taxExemptForm: FormGroup;
  paymentDetailsForm: FormGroup;

  solupayReady = false;
  submitSolupayForm = false;
  solupayValidationPending = false;
  poForm: FormGroup;

  editingAddress:FormAddressModel;
  public editingAddressSubject = new BehaviorSubject<FormAddressModel | null>(null);

  billingSameAsShipping = true;
  applyForCredit = false;

  companyTerms = {
    hasTerms:false,
    termsDescription:null
  };

  showAddressForm = false;
  showTaxExemptCertFrom = false;
  submittingOrder = false;

  checkoutDetails: CheckoutDetailModel;
  shippingMethod = null;
  shippingAccount = null;
  shippingAddress = null;

  hasCardOnFile = false;

  btnSpinny = false;

  isCreditGroupOpen = true;
  isPoGroupOpen = false;
  selectedAddressId;
  selectedTaxCertId;
  taxAmount;
  calculatingTax = false;
  recalculateTax = true;

  paymentErrorMessage = '';
  genericErrorMessage = '';
  sendBackTocart = false;

  orderForSubmission;

  cartForm: FormGroup;

  cart;
  customer;
  orderDetails = null;
  addresses = [];
  taxExemptCertificates = [];
  sentAnalytics = false;

  defaultsInitialized = false;

  forgetCardConfirmModalRef?: BsModalRef;
  
  private ngUnsubscribe = new Subject<void>();



  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    public checkoutService: CheckoutService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private dateService: DateService,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    public wizard:CheckoutWizardService,
    private zone: NgZone,
    private modalService: BsModalService
  ) { 
    this.cartForm = this.formBuilder.group({
      rfqItems: new FormArray([])
    })
  }

  public useCreditTerms() {
    return this.paymentDetailsForm.get('paymentMethod').value === "terms";
  }

  public useNewCard() {
    return this.paymentDetailsForm.get('paymentMethod').value === "newCard";
  }

  public useCardOnFile() {
    return this.paymentDetailsForm.get('paymentMethod').value === "savedCard";
  }

  ngOnInit() {

    this.wizard.orderSubmit.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(submit => {
      if(submit){
        this.submitOrder();
      }
    });

    // initialize forms
    this.paymentDetailsForm = this.formBuilder.group({
      paymentMethod: ['newCard', Validators.required]
    });

    this.cardForm = this.formBuilder.group({
      cardHolderName: [null, [Validators.required]],
      saveCard: [true],
      applyForCredit: [false]
    });

    this.taxExemptForm = this.formBuilder.group({
      taxExemptCertNumber: [''],
      taxExemptFile: ['']
    });

    this.poForm = this.formBuilder.group({
      customerPurchOrdNum: [null]
    });

    this.cartService.cartSubject.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(cart => {
        this.cart = cart;
        if (cart) {
          this.checkoutService.initializeCheckoutDetails(this.cart);
          if (cart.rfqItems) {
            // cart subscription fires a few times (bug?); so reset 
            //  rfq items each time
            while (this.rfqItems.length !== 0) {
              this.rfqItems.removeAt(0);
            }
            cart['rfqItems'].forEach((rfqItem) => {
              this.rfqItems.push(this.createRfqItem(rfqItem))
            });
          }
        }
    });


    this.customerService.userSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
      this.customer = user;
      if (this.customer.last_card_lastfour && this.customer.last_card_type) {
        this.hasCardOnFile = true;
      }
    })

    this.customerService.hasTerms().pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe((termsResponse) => {
      this.companyTerms = termsResponse;
    })

    this.customerService.getTaxExemptCertificates().pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((result) => {
        this.taxExemptCertificates = result;
      })

    this.customerService.getAddresses()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((addresses) => {
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
        }

        this.checkoutService.checkoutDetailsSubject
          .pipe(
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe(checkoutDetails => {
            if (checkoutDetails) {
              this.checkoutDetails = checkoutDetails;

              // here we set up  some cascading defaults;
              //  1. if customer has terms, that is the overriding default
              //  2. else if the customer has a saved card; that is the default; default to resave card
              //  3. otherwise, show the card form and default to saving the card
              this.customerService.hasTerms().pipe(
                takeUntil(this.ngUnsubscribe)
              )
                .subscribe((hasTerms) => {
                  this.companyTerms = hasTerms;
                  if (!this.defaultsInitialized) {
                    // cascade down thru payment options
                    // We prefer : terms > saved cc > new cc 
                    if(checkoutDetails.useCreditTerms && this.companyTerms.hasTerms){
                      this.paymentDetailsForm.get('paymentMethod').setValue('terms')
                      this.setCheckoutDetailItem({ useCreditTerms: true, useNewCard: false, useSavedCard: false });
                    } else if (checkoutDetails.useSavedCard && this.hasCardOnFile){
                      this.paymentDetailsForm.get('paymentMethod').setValue('savedCard')
                      this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: false, useSavedCard: true, saveCard: true });
                    } else if (checkoutDetails.useNewCard) {
                      this.paymentDetailsForm.get('paymentMethod').setValue('newCard')
                      this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: true, useSavedCard: false });
                    } else if (this.companyTerms.hasTerms){
                      this.paymentDetailsForm.get('paymentMethod').setValue('terms')
                      this.setCheckoutDetailItem({ useCreditTerms: true, useNewCard: false, useSavedCard: false });
                    } else if (this.hasCardOnFile) {
                      this.paymentDetailsForm.get('paymentMethod').setValue('savedCard')
                      this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: false, useSavedCard: true, saveCard: true });
                    } else {
                      this.paymentDetailsForm.get('paymentMethod').setValue('newCard')
                      this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: true, useSavedCard: false, saveCard: true });
                    }
                    this.defaultsInitialized = true;
                  }
                });

              if (!checkoutDetails.checkoutDetailId) {
                this.router.navigate(['/checkout/shipping-address'])
              } else {

                if (this.checkoutService.checkoutDetailsExpired(checkoutDetails)) {
                  this.checkoutService.deleteCheckoutDetails(this.cart.cartId, checkoutDetails.checkoutDetailId).pipe(
                    takeUntil(this.ngUnsubscribe)
                  ).subscribe(res => {
                    this.notificationService.broadcastFailureNotification({
                      message: "Your checkout session has expired please review your cart details to proceed",
                      options: {
                        timeout: true
                      }
                    });
                    this.router.navigate(['/cart']);
                  });
                } else {
                  if (!checkoutDetails.shippingAddressId || !checkoutDetails.outboundShippingOption) this.router.navigate(['/checkout/shipping-address']);
                  this.calculateTaxAmount();
                  if (checkoutDetails.taxExemptCertId) {
                    this.selectedTaxCertId = checkoutDetails.taxExemptCertId;
                  } else {
                    // default to auto select first cert if there's at least one and 
                    //   none already selected.
                    if (this.taxExemptCertificates.length && !this.selectedTaxCertId) {
                      this.selectNewTaxExemptCert(this.taxExemptCertificates[0].id, true);
                    }
                  }

                  if (checkoutDetails.applyForCredit) {
                    this.applyForCredit = checkoutDetails.applyForCredit;
                  }

                  if (checkoutDetails.saveCard) {
                    this.cardForm.get('saveCard').setValue(true);
                  } else {
                    this.cardForm.get('saveCard').setValue(false);
                  }

                  if (checkoutDetails.useCreditTerms || checkoutDetails.useCreditTerms === 0) {
                    // this.useCreditTerms = checkoutDetails.useCreditTerms ? true : false;
                  } else if (this.companyTerms.hasTerms){
                    this.setCheckoutDetailItem({useCreditTerms:true});
                  }

                  if (checkoutDetails.shippingAccountId && !this.shippingAccount) {
                    this.customerService.getShippingAccountById(checkoutDetails.shippingAccountId)
                      .pipe(
                        takeUntil(this.ngUnsubscribe)
                      )
                      .subscribe((shippingAccount) => {
                        this.shippingAccount = shippingAccount;
                      });
                  }

                  if (!this.shippingMethod && checkoutDetails.outboundShippingOption) {
                    this.shippingMethod = {
                      outboundShippingCost: checkoutDetails.outboundShippingCost,
                      outboundShippingDurationTerms: checkoutDetails.outboundShippingDurationTerms,
                      outboundShippingEstimatedDays: checkoutDetails.outboundShippingEstimatedDays,
                      outboundShippingName: checkoutDetails.outboundShippingName,
                      outboundShippingOption: checkoutDetails.outboundShippingOption,
                      outboundShippingProvider: checkoutDetails.outboundShippingProvider,
                      outboundShippingProviderImage: checkoutDetails.outboundShippingProviderImage,

                    }
                  }

                  if (!this.shippingAddress && this.addresses.length) {
                    this.shippingAddress = this.addresses.find(address => {
                      return address.customer_address_id === this.checkoutDetails.shippingAddressId;
                    })
                  }

                  if (checkoutDetails.billingAddressId && checkoutDetails.billingAddressId !== this.selectedAddressId) {
                    if (checkoutDetails.billingAddressId === checkoutDetails.shippingAddressId) {
                      this.billingSameAsShipping = true;
                      this.selectedAddressId = checkoutDetails.shippingAddressId;
                    } else {
                      const billingAddressLookup = this.addresses.find(address => {
                        return address.customer_address_id === checkoutDetails.billingAddressId;
                      });

                      if (!billingAddressLookup) {
                        this.selectNewAddress(this.checkoutDetails.shippingAddressId);
                        this.billingSameAsShipping = true;
                      } else {
                        this.selectedAddressId = checkoutDetails.billingAddressId;
                        this.billingSameAsShipping = false;
                      }
                    }
                  } else if (!checkoutDetails.billingAddressId && this.billingSameAsShipping) {
                    this.selectNewAddress(this.checkoutDetails.shippingAddressId);
                    this.billingSameAsShipping = true;
                  }

                  if (checkoutDetails.poNumber) {
                    this.poForm.get('customerPurchOrdNum').setValue(checkoutDetails.poNumber);
                  }

                  // Only send this analytic event when we've loaded a cart with details
                  //  AND these details are not expired.
                  //  Record that the user has reached the review (step 4) phase and is 
                  //  checking out. 

                  if (!this.sentAnalytics) {
                    const evt = {
                      'event' : 'checkout',
                      'ecommerce': {
                        'checkout': {
                          'actionField': { 'step': 4 },
                          'products': []
                        }
                      }
                    };
                    this.cart.items.forEach((item) => {
                      const itemAnalytics = {
                        'name': item.partNumber,
                        'price': item.pricePerUnit,
                        'brand': 'Marco',
                        'category': 'O-Rings',
                        'quantity': item.quantity
                      };
                      if (item.srcList) {
                        itemAnalytics['list'] = item.srcList;
                      }
                      evt.ecommerce.checkout.products.push(itemAnalytics);
                    });
                    this.analyticsService.trackEcom(evt);
                    this.sentAnalytics = true;
                  }
                }
              }
            }
          });
      });
  }

  ngAfterViewInit() {
    if (this.checkoutFlowTop) {
      this.checkoutFlowTop.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }


  //solupay component calls this after getting a token (on order submit)
  solupayTokenReceived(paymentToken) {
    this.submitSolupayForm = false;
    this.orderForSubmission.paymentToken = paymentToken;
    this.sendOrderToBackend();
  }

  scrollToPaymentForm() {
    let el = document.getElementById('paymentDetailsForm');
    el.scrollIntoView({behavior:"smooth"});
  }

  solupayValid(valid){
    this.solupayReady = valid;
    this.cardForm.get('cardHolderName').markAsTouched();
  }

  setSaveCard() {
    this.setCheckoutDetailItem({ saveCard: this.cardForm.get('saveCard').value });
  }

  setApplyForCredit() {
    this.setCheckoutDetailItem({ applyForCredit: this.cardForm.get('applyForCredit').value });
  }


  calculateTaxAmount = ()=>{
    if (!this.checkoutDetails.taxAmount){
      this.calculatingTax = true;
      this.cartService.calculateCartTaxes().pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(res => {
        this.calculatingTax = false;
        this.taxAmount = res['taxAmount'];
      },
        err=>{
          console.error('Error calculating tax', err);
          if(this.recalculateTax){
            this.recalculateTax = false;
            setTimeout(() => {
              this.calculateTaxAmount();
            }, 3000);
          } else {
            this.genericErrorMessage = "We're sorry there was an issue calculating taxes. Please refresh or try again later."
          }
      })
    } else {
      this.taxAmount = this.checkoutDetails.taxAmount;
    }
  }

  setBillingToShippingAddress = () => {
    if (this.billingSameAsShipping) {
      this.selectNewAddress(this.checkoutDetails.shippingAddressId);
      this.toggleAddressForm(false);
    } else if (this.addresses.length) {
      this.selectNewAddress(this.addresses[0].customer_address_id);
    } else {
      this.selectNewAddress(null);
    }
  }

  toggleAddressForm = (bool) => {
    this.showAddressForm = bool;
  }

  toggleTaxExemptForm = (bool) => {
    this.showTaxExemptCertFrom = bool;
  }


  selectNewAddress = (newAddressId) => {
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    checkoutDetailsPayload.billingAddressId = newAddressId;

    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }


  setCheckoutDetailItem = (item) => {
    let checkoutDetails = new CheckoutDetailModel();

    checkoutDetails.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    let checkoutDetailsPayload = Object.assign({}, checkoutDetails, item);
    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  selectNewTaxExemptCert(certId, evt) {
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    // set the certId if checkbox selected, null otherwise - thereby blanking taxId in cart details
    checkoutDetailsPayload.taxExemptCertId = evt ? certId : null;

    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  useCustomerTerms = () => {
    this.setCheckoutDetailItem({useCreditTerms:true});
  }

  useCreditCard = () => {
    this.setCheckoutDetailItem({useCreditTerms:false});
  }

  setSolupayPending(e){
    this.paymentErrorMessage = "";
    this.zone.run(() => {
      this.solupayValidationPending = e;
    });
    this.cardForm.get('cardHolderName').markAsTouched();
  }

  async submitOrder() {
      this.submittingOrder = true;
      this.btnSpinny = true;

      const order: OrderModel = {
        cartId: this.cart.cartId,
      };

      if (this.shippingMethod) {
        if (!order.orderDetails) {
          order.orderDetails = {};
        }
        order.orderDetails.cartTotal = this.computeTotal();
        order.orderDetails.shippingOption = this.checkoutDetails.outboundShippingOption;
        order.orderDetails.shippingCost = this.checkoutDetails.outboundShippingCost;
        order.orderDetails.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
        order.orderDetails.shippingAddressId = this.checkoutDetails.shippingAddressId;
        order.orderDetails.billingAddressId = this.checkoutDetails.billingAddressId;
        order.orderDetails.shippingAccountId = this.checkoutDetails.shippingAccountId;

        if(this.checkoutDetails.poNumber !== this.poForm.get('customerPurchOrdNum').value){
          order.orderDetails.customerPurchOrdNum = this.poForm.get('customerPurchOrdNum').value;
        } else {
          order.orderDetails.customerPurchOrdNum = this.checkoutDetails.poNumber;
        }

        order.orderDetails.saveCard = this.checkoutDetails.saveCard;
      }

      const sa = this.authService.getAttribution();
      order.orderDetails.salesAttribution = sa ? sa.attribution : undefined;


      this.orderForSubmission = order;


      if(this.useCreditTerms()){
        delete order.cardDetails;
        try{
          this.sendOrderToBackend();
        } catch (e) {
          this.wizard.setSubmitOrder(false);
          this.btnSpinny = false;
        }
      } else {
        // if using a new credit card, get solupay token, when token is done it will continue submitting order
        if(this.useNewCard()){
          if(!this.cardForm.valid){
            this.paymentErrorMessage = "Please check required fields";
            this.scrollToPaymentForm();
            this.btnSpinny = false;
            this.wizard.setSubmitOrder(false);              
          } else {
            this.submitSolupayForm = true;
            if(!this.solupayValidationPending && !this.solupayReady){
              this.scrollToPaymentForm();
              this.wizard.setSubmitOrder(false);
            }
          }

        } else {
          // if using a saved credit card, submit order
          order.cardDetails = this.checkoutService.checkoutCardSubject.value.cardDetails;
          this.orderForSubmission.paymentToken = this.checkoutService.checkoutCardSubject.value.paymentToken;
          try {
            this.sendOrderToBackend();
          } catch (e) {
            this.wizard.setSubmitOrder(false);
            this.btnSpinny = false;
            console.error(e);
          }
        }
      }
  }


  sendOrderToBackend(){
    let order = this.orderForSubmission;

    this.cartService.convertToOrder(order, this.cart).subscribe(orderDetails => {
      this.orderDetails = orderDetails;
      this.analyticsService.trackOrder(orderDetails.orderId, order.orderDetails, this.cart)

      const queryParams = {
        orderId: orderDetails.orderId,
      }

      if (orderDetails.rfqId) queryParams['rfqId'] = orderDetails.rfqId;

      this.submittingOrder = false;

      // an order was succesfully completed; clear any attribution tracking:
      this.authService.clearAttribution();
      this.router.navigate(['/checkout/complete'], { queryParams });
    }, (err) => {
      // TODO: This error catch block will be responsible for handling what to do if a submit order fails
      // Currently we just unlock the button but we will need to actually do something to let the customer know
      // what caused the order to fail
      this.btnSpinny = false;
      this.wizard.setSubmitOrder(false);
      console.log(err);
      if(err.error){
        if (err.error.errorType) {
          if (err.error.errorType === 'payment') {
            if (err.error.fieldName) {
              this.paymentErrorMessage = this.genericErrorMessage = err.error.message;
            }
            this.scrollToPaymentForm();
          } else if (err.error.errorType === 'totalMismatch') {
            this.sendBackTocart = true;
          } 
        } else {
          this.paymentErrorMessage = "We're sorry there was an issue submitting your order. Please re-enter your billing information and try again. Error Code: " + err.error.expoErrorCode;
          this.scrollToPaymentForm();
        }
      }
      this.submittingOrder = false;
    });
  }

  computeItemDeliveryTime(item) {
    if (!this.checkoutDetails) return '';
    const deliveryDays = this.checkoutDetails.outboundShippingEstimatedDays;
    return this.dateService.computeItemDeliveryDate(item, this.cart, deliveryDays);
  }

  computeDeliveryTime() {
    if (!this.checkoutDetails) return '';
    const deliveryDays = this.checkoutDetails.outboundShippingEstimatedDays;
    return this.dateService.computeDeliveryDate(this.cart, deliveryDays);
  }

  addNewTaxExemptCert = () => {
    this.toggleTaxExemptForm(true);
  }

  addNewAddress = () => {
    this.editingAddress = null;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  assignEditingAddress = (address) => {
    this.editingAddress = address;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  taxExemptCertCreated = (result) => {
    this.toggleTaxExemptForm(false);
    this.customerService.getTaxExemptCertificates()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((taxExemptCertificates) => {
        this.taxExemptCertificates = taxExemptCertificates;
        this.selectNewTaxExemptCert(result.taxExemptCertificate.insertId, true);
      })
  }

  addressCreated = (result) => {
    if (result.type === 'update') {
      this.addresses = this.addresses.map(address => {
        if (address.customer_address_id === result.address.customer_address_id) {
          return result.address;
        } else {
          return address;
        }
      })

      this.editingAddress = null;
      this.editingAddressSubject.next(this.editingAddress);
    } else {
      this.addresses.push(result.address);
      this.selectNewAddress(result.address.customer_address_id);
    }
    this.toggleAddressForm(false);
  }

  computeTotal = () => {
    if (!this.checkoutDetails) return 0;

    return parseFloat(this.cartTotal()) + parseFloat(this.getEstimatedTax()) + parseFloat(this.checkoutDetails.outboundShippingCost);

  }

  getSubtotal() {
    return this.cartService.cartSubject.value.getTotalAmount();
  }

  cartTotal() {
    return this.cartService.cartSubject.value.getTotalAmount().toFixed(2);
  }

  getItemSubtotalAmount(){
    return this.cartService.cartSubject.value.getItemSubtotalAmount().toFixed(2);
  }

  getShipping() {
    return 0;
  }

  getEstimatedTax() {
    return this.taxAmount;
  }

  getCollectMessaging() {
    if (this.shippingAccount) {
      return this.shippingAccount.accountNumber.replace(/.(?=.{2,}$)/g, '*');
    } else {
      return '';
    }
  }

  determineItemCount(arrayLength) {
    return `item${arrayLength === 1 ? '' : 's'}`;
  }


  navigateToShipping() {
    this.router.navigate(['/checkout/shipping-address']);
  }
  
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  changePaymentMethod(event) {
    //clear out any error messaging
    this.paymentErrorMessage = "";
    switch (event.target.value) {
      case 'terms':
        this.setCheckoutDetailItem({ useCreditTerms: true, useNewCard: false, useSavedCard: false, saveCard: false });
        break;
      case 'newCard':
        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: true, useSavedCard: false });
        break;
      case 'savedCard':
        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: false, useSavedCard: true });
        break;
    }
  }

  savePoNum() {
    this.setCheckoutDetailItem({ poNumber: this.poForm.get('customerPurchOrdNum').value });
  }

  forgetMyCard(){
    this.forgetCardConfirmModalRef = this.modalService.show(this.ccDeleteTemplate, {class: 'modal-md'});
  }

  confirmForgetCard(){
    this.hasCardOnFile = false;
    if(this.paymentDetailsForm.get('paymentMethod').value === 'savedCard'){
      if(this.companyTerms.hasTerms){
        this.paymentDetailsForm.get('paymentMethod').setValue('terms')
        this.changePaymentMethod({target:{value:'terms'}});
      } else {
        this.paymentDetailsForm.get('paymentMethod').setValue('newCard')
        this.changePaymentMethod({target:{value:'newCard'}});
      }
    }
    this.customerService.removeSavedCard().pipe(
      takeUntil(this.ngUnsubscribe)
    )
    .subscribe((res) => {
      this.forgetCardConfirmModalRef?.hide();
    })
  }

  declineForgetCard(){
    this.forgetCardConfirmModalRef?.hide();
  }

  getObfsCard() {
    const cc = this.checkoutService.checkoutCardSubject.value.paymentToken.card.number;
    return cc.slice(-4);
  }

  getCardType() {
    const str = this.checkoutService.checkoutCardSubject.value.paymentToken.card.type;
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
  }

  createRfqItem(rfqItem: CartPayloadRfqModel): FormGroup {
    return this.formBuilder.group({
      itemTitle: [rfqItem.itemTitle, null],
      rfqItemId: [rfqItem.rfqItemId, null],
      thumbnailPath : [rfqItem.thumbnailPath, null],
      categoryUrlSlug :[rfqItem.categoryUrlSlug, null],
      quantity: [rfqItem.quantity, [Validators.required]],
      requestedLeadTime: [rfqItem.requestedLeadTime, [Validators.required]],
      reason: [rfqItem.reason, [Validators.required]],
      comment: [rfqItem.comment, null],
      productId: [rfqItem.productId, null],
      partNumber: [rfqItem.partNumber, null],
    })
  }


  get rfqItems():FormArray {
    return this.cartForm.get('rfqItems') as FormArray;    
  }

  get rfqItemsControlsArray() : FormGroup[] {
    return this.rfqItems.controls as FormGroup[]
  }

  getCardOnFileInfo() {
    if (!this.customer) {
      return "";
    } else {
      const capitalizedCardName = this.customer.last_card_type.charAt(0).toUpperCase() + this.customer.last_card_type.slice(1);
      return capitalizedCardName + " (ending in " + this.customer.last_card_lastfour + ")";
    }
  }

}
