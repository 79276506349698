import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CtaModule } from '../shared/cta/cta.module';

import { ModalModule } from 'ngx-bootstrap/modal';
import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { CartRowItemModule } from '../shared/cart-row-item/cart-row-item.module';

import { AlertModule } from 'ngx-bootstrap/alert';
import { RecommendationsModule } from '../recommendations/recommendations.module';
import { CartSummaryCardModule } from '../checkout/cart-summary-card/cart-summary-card.module';
import { CartRowRfqItemModule } from '../shared/cart-row-rfq-item/cart-row-rfq-item.module';


@NgModule({
  imports: [
    CommonModule,
    CartRoutingModule,
    RecommendationsModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CartRowItemModule,
    CartRowRfqItemModule,
    CartSummaryCardModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    CtaModule
  ],
  exports: [
    CartComponent
  ],
  declarations: [
    CartComponent, ConfirmDeleteComponent
  ],
  entryComponents: [
    ConfirmDeleteComponent
  ]
})
export class CartModule { }
