import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { CartService } from '../core/services/cart.service';
import { CheckoutService } from '../core/services/checkout.service';
import { LocationService } from '../core/services/location.service';
import { CartPayloadRfqModel, CartPayloadItemModel } from '../shared/cart-payload.model';
import { AuthService } from '../core/services/auth.service';
import { DateService } from '../core/services/date.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { RouterExtensionService } from '../core/services/router-extension.service';
import { PreviousUrlModel } from '../shared/router-extension.model';
import { takeUntil } from 'rxjs/operators';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { NotificationService } from '../core/services/notification.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { isPlatformBrowser } from '@angular/common';
import { CheckoutWizardService } from '../checkout/checkout-wizard.service';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  @ViewChild("rfqSection", {static: false}) rfqSection: ElementRef;

  previousUrl = new PreviousUrlModel();

  faExclamationTriangle = faExclamationTriangle;
  faFileAlt = faFileAlt;
  faChevronLeft = faChevronLeft;
  faInfoCircle = faInfoCircle;
  faTimes = faTimes;

  bsModalRef: BsModalRef;
  cart = null;
  cartState = null;
  cartForm: FormGroup;
  isUserLoggedIn = false;
  cartItemAdjustments = new Array;
  failedRecalculations = new Array;
  recalculatingBool: boolean = false;

  removedCartItems: CartPayloadItemModel[] = [];
  removedRfqItems: CartPayloadRfqModel[] = [];
  tempItemRemovedStorage = null;
  showShippingRestrictionMessage = false;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private routerExtensionService: RouterExtensionService,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private router: Router,
    private analyticService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private locationService: LocationService,
    private checkoutWizardService: CheckoutWizardService,
    private title: Title,
  ) {
    this.routerExtensionService.previousCatalogUrlSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(previousUrl => {
      this.previousUrl.url = previousUrl ? previousUrl.url : ['/store'];
      this.previousUrl.paramMap = previousUrl ? previousUrl.paramMap : {};
    });

    this.cartForm = this.formBuilder.group({
      rfqItems: new FormArray([])
    })

    this.authService.isLoggedIn().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(loggedIn => {
      this.isUserLoggedIn = loggedIn;
    })

    this.cartService.priceChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((res) => {
      if (res) this.cartItemAdjustments.push(res);
    })

    this.cartService.recalculatingSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(bool => {
      this.recalculatingBool = bool;
    })

    this.cartService.failedRecalculationsSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((res) => {
      this.failedRecalculations = res;
    })

    this.cartService.cartSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(cart => {
      this.cart = cart;

      this.cartService.checkExpiredCartItems(cart);

      if (cart && cart['rfqItems']) {
        // cart subscription fires a few times (bug?); so reset
        //  rfq items each time
        while (this.rfqItems.length !== 0) {
          this.rfqItems.removeAt(0)
        }

        cart['rfqItems'].forEach((rfqItem) => {
          this.rfqItems.push(this.createRfqItem(rfqItem))
        });
      }

      if (this.tempItemRemovedStorage) {
        this.convertTempStorage();
      }
    });

    this.title.setTitle(`Cart - Marco Rubber`);
  }

  ngOnInit() {
    this.cartService.getCart();
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }


  failedRecalculationCheck(item) {
    return this.failedRecalculations.indexOf(item.itemId) > -1;
  }

  navigateToQuote(quoteItemQuantityBreakGuid) {
    this.cartService.getQuoteGuidByBreakGuid(quoteItemQuantityBreakGuid).subscribe((res:any) => {
      this.router.navigate([`/quotes/${res.guid}`])
    })
  }

  convertTempStorage = () => {
    if (this.tempItemRemovedStorage.itemId) {
      this.removedCartItems.push(this.tempItemRemovedStorage);
    } else if (this.tempItemRemovedStorage.rfqItemId) {
      this.removedRfqItems.push(this.tempItemRemovedStorage);
    }

    this.tempItemRemovedStorage = null;
  }

  computeShippingDate(item) {
    return `Expected to ship ${this.dateService.computeShippingDate(item)}`;
  }

  createRfqItem(rfqItem: CartPayloadRfqModel): FormGroup {

    return this.formBuilder.group({
      itemTitle: [rfqItem.itemTitle, null],
      rfqItemId: [rfqItem.rfqItemId, null],
      thumbnailPath : [rfqItem.thumbnailPath, null],
      categoryUrlSlug :[rfqItem.categoryUrlSlug, null],
      quantity: [rfqItem.quantity, [Validators.required]],
      requestedLeadTime: [rfqItem.requestedLeadTime, [Validators.required]],
      reason: [rfqItem.reason, [Validators.required]],
      comment: [rfqItem.comment, null],
      productId: [rfqItem.productId, null],
      partNumber: [rfqItem.partNumber, null],
    })
  }

  removefromCart = (item) => {
    this.tempItemRemovedStorage = item;
    this.cartService.removeItemFromCart(item.itemId);
    this.cartService.removeItemFromFailedRecalculations(item.itemId);

    const removeFromCartAnalyticEvent = {
      'event': "removeFromCart",
      'ecommerce': {
        'remove': {                               // 'remove' actionFieldObject measures.
          'products': [{                          //  removing a product to a shopping cart.
            'name': item.partNumber,                //  The present UI only allows removal of
            'price': item.price,                  //    one product at a time, that's why this
            'brand': 'Marco',                     //    is a hardcoded one-item array.
            'category': 'O-Ring',                 //  Presently we categorize everything as an
            'variant': item.itemColor,            //    O-RING in GA ecom; this could be enhanced
            'quantity': item.quantity             //    if need be.
          }]
        }
      }
    };
    this.analyticService.trackEcom(removeFromCartAnalyticEvent);
  }

  removeRfqFromCart(item) {
    this.tempItemRemovedStorage = item;
    this.cartService.removeRfqItemFromCart(item.value.rfqItemId);
  }

  displayRemoveFromCartModal(item: any) {
    const initialState = {
      title: item.itemTitle,
      callback: item.rfqItemId ? this.removeRfqFromCart : this.removefromCart,
      item: item,
    };
    this.bsModalRef = this.modalService.show(ConfirmDeleteComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(c => {
          this.validateAllFormFields(c);
        });
      }
    });
  }

  isFieldValid(item, controlName) {
    return !item.get(controlName).valid && item.get(controlName).touched
  }


  navigateBackToProduct(item) {
    this.notificationService.broadcastWarningNotification({
      message: "Please review and select an updated quote option for this item. If these quote options don't meet your needs, you may go back to your cart and remove the item."
    });

    this.router.navigate(['/product', item.partNumber], {
      queryParams: {
        quantity: item.quantity
      }
    });
  }


  ngOnDestroy() {
    this.cartService.clearFailedRecalculationsAndExpiredPrices();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get rfqItems():FormArray {
    return this.cartForm.get('rfqItems') as FormArray;
  }

  get rfqItemsControlsArray() : FormGroup[] {
    return this.rfqItems.controls as FormGroup[]
  }

}
