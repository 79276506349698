import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    TypeaheadModule,
    FormsModule,
    FontAwesomeModule

  ],
  exports: [SearchComponent]
})
export class SearchModule { }
