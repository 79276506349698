import { Component, OnInit, Input, ElementRef, ViewChild  } from '@angular/core';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { ProductService } from '../core/services/product.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';

@Component({
  selector: 'app-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrls: ['./recommendation-item.component.scss']
})
export class RecommendationItemComponent implements OnInit {
  @Input() product;
  @Input() listIdx; // the position in the recommendation list this item appeared: ie 0-3 - used for GA
  // @ViewChild('quantityInput', {static: false}) quantityInput: ElementRef;

  faShippingFast = faShippingFast;
  price = null;
  quantity = 1;
  loading = true;

  constructor(
    private productService: ProductService,
    private analyticsService: AnalyticsService,
    private router:Router
  ) { }


    logClickAndNavigate() {
      const evt = {
        'ecommerce': {
          'event': 'productClick',
          'click': {
            'actionField': { 'list': 'Recommendations' },
            // this will always ever be a single item array as you can
            //  only click one one object at a time with our UI
            'products': [{
              'name': this.product.partNumber,
              'price': this.product.pricePerUnit,
              'brand': 'Marco',
              'category': 'O-Rings',
              'list': 'Recommendations',
              'position': this.listIdx
            }]
          }
        }
      };
      this.analyticsService.trackEcom(evt);
      this.router.navigate(['/product', this.product['_source'].part_number ])
    }


  getPrice(quantity) {
    // this.loading = true;
    // console.log(this.product.dynamic_img)
    // this.productService.getFulfillmentOptions(this.product['_source'].part_number, quantity).subscribe(
    //   fulfillment => {
    //     if (fulfillment && fulfillment.fulfillmentOptions && fulfillment.fulfillmentOptions.length) {
    //       let found = fulfillment.fulfillmentOptions.find(v => {
    //         return v.vendorCode === "MARCO";
    //       })

    //       if (found) {
    //         if (found.quantity < quantity) {
    //           this.price = null
    //         } else {
    //           this.price = found.pricePerUnit;
    //         }
    //       } else {
    //         this.price = null;
    //       }

    //     }
    //     this.loading = false;
    //   },
    //   (err) => {
    //     // this.errorState = err;
    //     // this.rfq = true;
    //     // this.loadingFulFillmentOptions = false;
    //   });
  }

  ngOnInit(): void {
  //   this.getPrice(1);
  }


  // ngAfterViewInit() {
  //   fromEvent(this.quantityInput.nativeElement, 'change')
  //     .pipe(
  //       debounceTime(500)
  //     )
  //     .subscribe(event => {
  //       let newQuantity = parseInt(event['target'].value);
  //       if (!isNaN(newQuantity)) {
  //         this.getPrice(newQuantity)
  //       } else {
  //         this.getPrice(1)
  //       }
  //     });

  //   fromEvent(this.quantityInput.nativeElement, 'keyup')
  //     .pipe(
  //       debounceTime(500)
  //     )
  //     .subscribe(event => {
  //       let newQuantity = parseInt(event['target'].value);
  //       if (!isNaN(newQuantity)) {
  //         this.getPrice(newQuantity)
  //       } else {
  //         this.getPrice(1)
  //       }
  //     });
  //   }
}
