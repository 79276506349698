<!-- product image column -->

<td class="product-detail align-middle">
  <div class="product-item d-flex">
    <a *ngIf="!disableNavigate" class="product-thumb flex-item-center mr-2" [routerLink]="[item.categoryUrlSlug ? '/product/'+item.categoryUrlSlug : '/product', item.partNumber]">
      <img *ngIf="item.thumbnailPath" src="{{item.thumbnailPath}}" alt="{{item.partNumber}}">
    </a>
    <a *ngIf="disableNavigate" class="product-thumb flex-item-center mr-2">
      <img *ngIf="item.thumbnailPath" src="{{item.thumbnailPath}}" alt="{{item.partNumber}}">
    </a>
    <div class="product-info">    
      <ng-container *ngIf="item.categoryUrlSlug">
        <div class="product-title std-label">
          <a *ngIf="!disableNavigate" [routerLink]="['/product',item.categoryUrlSlug, item.partNumber]">
            <span class="mr-2">{{item.partNumber}}</span>
            <span class="mr-2">{{item.itemTitle}}</span>
          </a>
          <a *ngIf="disableNavigate">
            <span class="mr-2">{{item.partNumber}}</span>
            <span class="mr-2">{{item.itemTitle}}</span>
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="!item.categoryUrlSlug">
        <div class="product-title" *ngIf="item.customProductLabel">
          <span class="pointer mr-2" *ngIf="item.quoteItemQuantityBreakSource"> 
            <a (click)="navigateToQuote(item.quoteItemQuantityBreakSource)">{{item.customProductLabel}}</a>
          </span>
          <span class="pointer" *ngIf="!item.quoteItemQuantityBreakSource"> 
            <a *ngIf="!disableNavigate" [routerLink]="['/product', item.partNumber]">
            <span>{{item.customProductLabel}}</span></a>
          </span>
          <a *ngIf="disableNavigate"><span>{{item.customProductLabel}}</span></a>
        </div>
        <div class="product-title custom-part-label" *ngIf="!item.customProductLabel">
          <span class="pointer mr-2" *ngIf="item.quoteItemQuantityBreakSource"> 
            <a (click)="navigateToQuote(item.quoteItemQuantityBreakSource)">{{item.partNumber}}</a>
          </span>
          <span class="pointer" *ngIf="!item.quoteItemQuantityBreakSource"> 
            <a *ngIf="!disableNavigate" class="link-style" [routerLink]="['/product', item.partNumber]">
              <span class="mr-2">{{item.partNumber}}</span>
              <span>{{item.itemTitle}}</span>
            </a>
          </span>
          <a *ngIf="disableNavigate">
            <span class="mr-2">{{item.partNumber}}</span>
            <span class="mr-2">{{item.itemTitle}}</span>
          </a>
        </div>
      </ng-container>
      <span class="shipping-text" *ngIf="shippingDate">
        {{ shippingDate }}
      </span>
      <span class="shipping-text" *ngIf="deliveryDate">
        Expected Delivery: {{ deliveryDate }}
      </span>
      <span class="shipping-text" *ngIf="deliveryRange">
        Expected Delivery {{ item.deliveryRange?.start }} <span style="display:inline;" *ngIf="item.deliveryRange?.end"> - {{ item.deliveryRange?.end }}</span>
      </span>
    </div>
  </div>
</td>
<td data-mobiledisplay="block" data-mobileprefix="QTY:" [attr.data-mobilesuffix]="'(' + pricePerUnit + ' each)'" class="qty-col text-center align-middle">
  {{ item?.quantity | number }}
</td>
<ng-container *ngIf="failedMessage">
  <td colspan="2" class="text-lg">
    <div class="d-flex flex-row flex-align-center">
      <fa-icon class="text-danger mr-1" [icon]="faExclamationTriangle" size="1x"></fa-icon>
      <div class="text-muted">
        This item is no longer available as quoted. 
        <span *ngIf="!item.quoteItemQuantityBreakSource">
          Please <span class="pointer link-style" (click)="navigateBackToProduct(item)">click here</span> for an updated quote.
        </span>
      </div>
    </div>
  </td>
</ng-container>
<ng-container *ngIf="!failedMessage">
  <td class="unitprice-col text-center align-middle">{{ pricePerUnit }}</td>
  <td data-mobileprefix="Subtotal: " class="subtotal-col text-center align-middle">{{ totalPrice }}</td>
</ng-container>
<td data-mobiledisplay="block" class="remove-col align-middle">
   <!-- 'item  remove' is a button on desktop -->
   <fa-icon *ngIf="enableRemove" class="pointer text-primary cart-remove-button" (click)="removeFromCart(item)" [icon]="faTimesCircle"></fa-icon>
   <!-- 'item  remove' is a link on mobile -->
   <a *ngIf="enableRemove" class="pointer text-primary cart-remove-text" (click)="removeFromCart(item)">Remove</a>
</td>
