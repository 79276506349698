import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { VERSION } from '../../../environments/version';

import * as Sentry from "@sentry/browser";

if (environment.enableSentry) {
  Sentry.init({
    dsn: "https://5347443343704810a6c376343fa01499@sentry.io/1521937",
    ignoreErrors:[
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ],
    environment: environment.environment,
    release: VERSION.fullVersion
  });
}

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  
  handleError(error) {
    // always log errors to console; even when using sentry
    console.log(error);
    
    if(error) {
      const chunkFailedMessage = /ChunkLoadError/;
      // this error started happening en masse around Q2 2021 with routes filled with various regex's
      //   this overfilled our sentry so we are squelching them at the ui level:
      const routeFindErrorMsg = /Error: Could not find route/;
      // this error is noisy and pervasive with the prerender bot which is crawling our site;
      //   lets squelch it from sentry:
      const crawlBotErrorMessage = /Failed to register a ServiceWorker: No URL is associated with the caller's document/;

      if (crawlBotErrorMessage.test(error.message)) {
        // do nothing; do not reload, do not log; do not pass to sentry
      } else if (routeFindErrorMsg.test(error.message)) {
        // do nothing; do not reload, do not log; do not pass to sentry
      } else if (chunkFailedMessage.test(error.message)) {
        alert("A new version of the website is available.  Press OK to reload this new version.");
        window.location.reload();
      } else {
        if (environment.enableSentry) {
          Sentry.captureException(error);
        } else if (environment.environment === 'local') {
          throw new Error(error);
        }
      }
    }
  }
}