import { Component, Input, OnInit } from '@angular/core';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faShoppingCart, faCircleNotch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { CartService, CheckoutService, TaxStatusEnum } from '../../core/services';
import { CheckoutStepEnum, CheckoutWizardService } from '../../checkout/checkout-wizard.service';
import { PreviousUrlModel } from '../../shared/router-extension.model';
import { RouterExtensionService } from '../../core/services/router-extension.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faThemeisle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-cart-summary-card',
  templateUrl: './cart-summary-card.component.html',
  styleUrls: ['./cart-summary-card.component.scss']
})
export class CartSummaryCardComponent implements OnInit {

  @Input() proceedDisabled:boolean = true;


  faExclamationTriangle = faExclamationTriangle;
  faFileAlt = faFileAlt;
  faChevronLeft = faChevronLeft;
  faInfoCircle = faInfoCircle;
  faCircleNotch = faCircleNotch;
  faTimes = faTimes;
  faShoppingCart = faShoppingCart;
  faArrowLeft = faArrowLeft;
  previousUrl = new PreviousUrlModel();
  orderBeingSubmitted = null;
  private ngUnsubscribe = new Subject<void>();

  CheckoutStepEnum = CheckoutStepEnum;
  TaxStatusEnum = TaxStatusEnum;

  constructor(
    public wizard:CheckoutWizardService,
    public checkoutService: CheckoutService,
    public cartService: CartService,
    private routerExtensionService: RouterExtensionService
    ) { 

      this.routerExtensionService.previousCatalogUrlSubject.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(previousUrl => {
        this.previousUrl.url = previousUrl ? previousUrl.url : ['/store'];
        this.previousUrl.paramMap = previousUrl ? previousUrl.paramMap : {};
      });
  }

  ngOnInit(): void {
    this.wizard.orderPending.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(submit => {
      this.orderBeingSubmitted = submit;
    });
  }

  determineButton() {
    if (this.cartService.cartSubject.value) {
      if (this.cartService.cartSubject.value.items.length === 0 && this.cartService.cartSubject.value.rfqItems.length > 0) {
        return 'Submit Request';
      } else {
        return 'Proceed to Checkout';
      }
    } else {
      return "";
    }    
  }

  onProceedClicked(e:Event) {
    this.wizard.startCheckoutFlow();
  }

  determineItemCount(arrayLength) {
    return `item${arrayLength === 1 ? '' : 's'}`;
  }

  isTotalUnavailable() {
    return this.wizard.cartService.recalculatingSubject.value || this.wizard.cartService.failedRecalculationsSubject.value.length > 0
  }

  computeSafeTotal():number {

    let total:number = 0;

    if (this.cartService.cartSubject.value.getTotalAmount()) {
      total += this.cartService.cartSubject.value.getTotalAmount();
    }

    if (this.checkoutService.checkoutDetailsSubject.value?.taxAmount) {
      total += +this.checkoutService.checkoutDetailsSubject.value.taxAmount;
    }

    if (this.checkoutService.checkoutDetailsSubject.value?.outboundShippingCost) {
      total += parseFloat(this.checkoutService.checkoutDetailsSubject.value?.outboundShippingCost);
    }

    return total;
  }

  setOrderSubmitting(){
    if(!this.orderBeingSubmitted){
      this.orderBeingSubmitted = true;
      this.wizard.setSubmitOrder(true);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
