import { Injectable } from '@angular/core';
import { NotificationModel } from '../../shared/notification.model';

import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notification:NotificationModel;
  public notificationSubject = new BehaviorSubject<NotificationModel | null>(null);

  constructor() {
  }

  public broadcastSuccessNotification(notification:NotificationModel) {
    notification.options = notification.options || {};
    notification.options.type = 'success';

    this.notification = notification;
    this.notificationSubject.next(this.notification);
  }

  public broadcastWarningNotification(notification:NotificationModel) {
    notification.options = notification.options || {};
    notification.options.type = 'warning';

    this.notification = notification;
    this.notificationSubject.next(this.notification);
  }

  public broadcastFailureNotification(notification:NotificationModel) {
    notification.options = notification.options || {};
    notification.options.type = 'failure';

    this.notification = notification;
    this.notificationSubject.next(this.notification);
  }

  public clearNotification() {
    this.notification = null;
    this.notificationSubject.next(this.notification);
  }
}
