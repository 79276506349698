<ng-container *ngIf="(wizard.cartSubject | async)?.items.length > 0 || (wizard.cartSubject | async)?.rfqItems.length > 0">
  <h4>Order Summary</h4>
  <div class="card order-summary-card">
    <div class="card-body">
      <alert *ngIf="(wizard.cartSubject | async)?.serviceFee" type="primary" [dismissible]="false">
        <p>
          <b class="mr-2">Minimum Order Surcharge</b>
          <fa-icon [icon]="faInfoCircle" size="sm"></fa-icon>
        </p>
        Order minimum not met. Increase quantity or add items to prevent surcharge.
      </alert>


      <table class="table">
        <tbody>

          <!-- subtotal row -->
          <tr *ngIf="(wizard.cartSubject | async)?.items.length > 0">
            <td class="order-summary-item">
              Subtotal ({{ (wizard.cartSubject | async)?.items.length}} {{ determineItemCount( (wizard.cartSubject |
              async).items.length) }}):
            </td>
            <td class="order-summary-item text-right">{{ isTotalUnavailable() ? '--' :
              wizard.cartSubject.value.getItemSubtotalAmount() | currency:'USD' }}
            </td>
          </tr>

          <!-- minimum order row -->
          <tr *ngIf="(wizard.cartSubject | async)?.items.length > 0 && (wizard.cartSubject | async)?.serviceFee">
            <td class="order-summary-item service-fee-warning">${{ (wizard.cartSubject | async)?.serviceFee +
              (wizard.cartSubject | async)?.getItemSubtotalAmount() |
              number : '1.0-0'}} Min Order Surcharge</td>
            <td class="order-summary-item service-fee-warning text-right">{{ isTotalUnavailable() ? '--' :
              (wizard.cartSubject | async)?.serviceFee | currency:'USD' }}
            </td>
          </tr>

          <!-- shipping -->
          <tr *ngIf="(wizard.cartSubject | async)?.items.length > 0">
            <td class="order-summary-item">
              Shipping:
            </td>
            <td class="order-summary-item font-italic text-right">{{ (checkoutService.checkoutDetailsSubject | async) ?
              ((checkoutService.checkoutDetailsSubject | async)?.shippingAccountId ? 'Collect' :
              (checkoutService.checkoutDetailsSubject | async)?.outboundShippingCost | currency:'USD') : "TBD"}}</td>
          </tr>

          <!-- Taxes row -->
          <tr *ngIf="(wizard.cartSubject | async)?.items.length > 0">
            <td class="order-summary-item pb-3">
              Tax:
            </td>
            <td class="order-summary-item pb-3 font-italic text-right">{{ (checkoutService.checkoutDetailsSubject |
              async)?.taxExemptCertId ? "Exempt" : (checkoutService.checkoutDetailsSubject | async)?.taxAmount ? '$' +
              (checkoutService.checkoutDetailsSubject | async)?.taxAmount : "TBD" }}</td>
          </tr>

          <!-- TOTAL -->
          <tr class="border-top" *ngIf="(wizard.cartSubject | async)?.items.length > 0">
            <td class="order-summary-total pt-3">Total:</td>


            <!-- <pre>{{ computeSafeTotal() }}</pre> -->

            <td class="order-summary-total pt-3 text-right">{{ computeSafeTotal() | currency:'USD' }}
            </td>
          </tr>

          <!-- RFQ TOTAL -->
          <tr *ngIf="(wizard.cartSubject | async)?.rfqItems.length > 0">
            <td class="order-summary-item">RFQ Total</td>
            <td class="order-summary-item text-right">
              <span class="count-label rfq-item mr-1">{{(wizard.cartSubject | async)?.rfqItems.length}}</span>{{
              determineItemCount((wizard.cartSubject | async)?.rfqItems.length) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="column">
        <button [disabled]="orderBeingSubmitted" *ngIf="wizard.currentStep?.step === CheckoutStepEnum.REVIEW" class="btn btn-primary w-100"
          (click)="setOrderSubmitting()"><fa-icon *ngIf="orderBeingSubmitted" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>Place Order</button>
      </div>
      <div class="column">
        <button *ngIf="wizard.currentStep?.step === CheckoutStepEnum.CART" class="btn btn-primary w-100"
          (click)="wizard.startCheckoutFlow()" [disabled]="isTotalUnavailable() || proceedDisabled">{{
          determineButton() }}</button>
      </div>
      <div *ngIf="(wizard.cartSubject | async)?.getCartState() === 'hybrid'" class="text-muted my-3">
        *RFQ will be submitted automatically.
      </div>
      <div *ngIf="(wizard.cartSubject | async)?.getCartState() === 'rfqsOnly'" class="text-muted my-3">
        A Marco Rubber & Plastics sales consultant will contact you shortly with a quote.
      </div>
      <alert
        *ngIf="((wizard.cartSubject | async)?.getCartState() === 'itemsOnly' || (wizard.cartSubject | async)?.getCartState() === 'hybrid') && wizard.showShippingRestrictionMessage"
        type="warning" [dismissible]="false">
        <fa-icon [icon]="faInfoCircle" size="sm"></fa-icon> We currently only ship web orders to the United
        States, please <a href="/inquiry-quote.htm">submit an RFQ</a> if you need international shipping.
      </alert>
      <alert
        *ngIf="(wizard.cartSubject | async)?.getCartState() === 'rfqsOnly' || (wizard.cartSubject | async)?.getCartState() === 'hybrid'"
        type="info" [dismissible]="true">
        <fa-icon [icon]="faInfoCircle" size="sm"></fa-icon> Note: You can add multiple items to a request before
        submitting
      </alert>
    </div>
  </div>
  <div *ngIf="wizard.currentStep?.step === CheckoutStepEnum.CART" style="margin:20px;">
    <a class="btn btn-outline-primary w-100" [routerLink]="previousUrl.url" [queryParams]="previousUrl.paramMap">
      <fa-icon [icon]="faArrowLeft" size="sm"></fa-icon>
      Back to Shopping
    </a>
  </div>
  <div style="margin:20px;">
    <div class="column">
      <button *ngIf="wizard.currentStep?.step !== CheckoutStepEnum.CART" class="btn btn-outline-primary w-100"
        (click)="wizard.goStep(CheckoutStepEnum.CART)"><fa-icon [icon]="faShoppingCart" size="sm"></fa-icon>&nbsp;Return To Cart</button>
    </div>
  </div>

</ng-container>
<!-- 

  <pre>{{ (cartService.taxes$ | async) | json }}</pre>

  <div class="card">
    <div class="card-header">
      checkoutDetails
    </div>
    <div class="card-body">
      <pre>
        getTotalAmount(): {{ (cartService.cartSubject | async)?.getTotalAmount() }}
        outboundShippingCost: {{ (checkoutService.checkoutDetailsSubject | async)?.outboundShippingCost }} 
        taxAmount: {{ (checkoutService.checkoutDetailsSubject | async)?.taxAmount }}
      </pre>
    </div>
    <div class="card-body">
      <pre>{{ (checkoutService.checkoutDetailsSubject | async ) | json }}</pre>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      cart
    </div>
    <div class="card-body">
      <pre>{{ (cartService.cartSubject | async ) | json }}</pre>
    </div>
  </div>

 -->