import { Component } from '@angular/core';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  faLinkedin = faLinkedin;

  today = new Date();

  constructor() {
    
  }

  ngOnInit(): void {
  }

}
