import { Component, Output, OnInit, EventEmitter, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-solupay',
  templateUrl: './solupay.component.html',
  styleUrls: ['./solupay.component.scss']
})
export class SolupayComponent implements OnInit {

  private _getToken: boolean;

  @Output() validationChange = new EventEmitter();
  @Output() completePayment = new EventEmitter();
  @Output() validationPending = new EventEmitter();
  @Input() set getToken(getToken: boolean) {
    if (getToken === true && this._getToken === false) {
      window['CollectJS'].startPaymentRequest();
    }
    this._getToken = getToken;
  }

  solupayReady = false;
  ccnumber = { valid: false, msg: '' };
  ccexp = { valid: false, msg: '' };
  cvv = { valid: false, msg: '' };

  constructor(
  ) {
  }

  ngOnInit() {
    // this loads the solupay javascript library exclusively for this 
    //  component.  This is done to avoid loading this script in index.html
    //  where it would be loaded for all pages.

    const s: any = document.createElement('script');
    s.type = 'text/javascript';
    s.setAttribute('data-tokenization-key', environment.SOLUPAY_TOKENIZATION_KEY);
    s.src = 'https://secure.safewebservices.com/token/Collect.js';
    const head = document.getElementsByTagName('head')[0];

    if (s.readyState) {  //IE
      s.onreadystatechange = () => {
        if (s.readyState === "loaded" || s.readyState === "complete") {
          s.onreadystatechange = null;
          this.initSolupay();
        }
      };
    } else {  //Others
      s.onload = () => {
        this.initSolupay();
      };
    }
    head.appendChild(s);

  }

  validationIsPending() {
    this.validationPending.emit(true);
  }

  ngAfterViewInit() {

  }

  initSolupay = () => {
    let solupayComponent = this;
    if (window.hasOwnProperty('CollectJS')) {
      window['CollectJS'].configure({
        variant: 'inline',
        fieldsAvailableCallback: function () {
          var frames = document.querySelectorAll(".solupayIframeContainers iframe.CollectJSInlineIframe");
          for (var i = 0; i < frames.length; i++) {
            frames[i].addEventListener("blur", function (event) {
              solupayComponent.validationIsPending();
            });
          }
        },
        styleSniffer: false,
        callback: (token) => {
          solupayComponent.completePayment.emit(token);
        },
        googleFont: "Exo:400",
        validationCallback: function (field, status, message) {
          solupayComponent.validateSolupayForm(field, status, message);
        },
        invalidCss: {
          "border-color": "#dc3545"
        },
        customCss: {
          "height": "44px",
          "color": "#505050",
          "font-size": "16px",
          "font-family": "Exo",
          "border-width": "1px",
          "border-color": "#e0e0e0",
          "border-style": "solid",
          "border-radius": "5px",
          "padding": "0.375rem 0.75rem"
        },
        focusCss: {
          "border-color": "#0055ff",
          "border-radius": "5px",
          "border-style": "solid",
          "border-width": "1px",
          "font-weight": "400",
          "outline": "none",
          "background-color": "rgba(#0055ff, .02)"
        },
        fields: {
          ccnumber: {
            placeholder: 'XXXX XXXX XXXX XXXX',
            selector: '#cc_number'
          },
          ccexp: {
            placeholder: 'MM/YY',
            selector: '#cc_exp'
          },
          cvv: {
            placeholder: 'XXX',
            selector: '#_cvv'
          }
        },
      });
    }
  }

  validateSolupayForm(field, status, message) {
    this[field].valid = status;
    // store and show validation error if field invalid
    if (message === 'Success')
      this[field].msg = '';
    else
      this[field].msg = message;
    this.solupayReady = this.ccnumber.valid && this.ccexp.valid && this.cvv.valid;
    this.validationPending.emit(false);
    this.validationChange.emit(this.solupayReady);
  }
 
}
