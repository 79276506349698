<ng-container *ngIf="showMessage">
  <div class="hidden-md-up"></div>
  <h4>Email not recognized. Please fill out the form below to proceed.</h4>
  <p>Registration takes less than a minute but gives you full control over your orders.</p>
</ng-container>
<div class="card mt-3">
  <div class="card-body">
    <h4 class="mb-2" *ngIf="!update">Create Account</h4>
    <h4 class="mb-2" *ngIf="update">Update Account</h4>
    <p *ngIf="!update">Registration takes less than a minute but gives you full control over your orders.</p>
    <form class="row" [formGroup]="registerForm">
      <div class="col-sm-6">
        <div class="form-group" formGroupName="customerGroup">
        <input type="hidden" formControlName="salesAttribution" name="salesAttribution" />
        <label for="reg-fn">First Name</label>
          <input
            class="form-control" 
            type="text"
            id="reg-fn"
            name="first_name"
            formControlName="first_name"
            required
          >
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" formGroupName="customerGroup">
          <label for="reg-ln">Last Name</label>
          <input
            class="form-control" 
            type="text"
            id="reg-ln"
            name="last_name"
            formControlName="last_name"
            required
          >
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" formGroupName="customerGroup">
          <label for="reg-email">E-mail Address</label>
          <input
            class="form-control"
            type="email"
            name="email" 
            id="reg-email"
            formControlName="email"
            required
          >
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group" formGroupName="customerGroup">
          <label for="reg-phone">Phone Number</label>
          <input
            class="form-control"
            type="text"
            name="phone"
            id="reg-phone"
            formControlName="phone"
          >
        </div>
      </div>
      <div class="col-12">
          <div class="form-group" formGroupName="customerGroup">
            <label for="reg-company">Company</label>
            <input
              class="form-control"
              type="text"
              name="company_name"
              id="reg-company"
              formControlName="company_name"
            >
          </div>
      </div>
      <div class="col-sm-6" *ngIf="!update">
        <div class="form-group" formGroupName="passwordGroup" [customErrorMessages]="customErrorMessages">
          <label for="reg-pass">Password</label>
          <input
            class="form-control"
            type="password"
            name="password"
            id="reg-pass"
            formControlName="password"
            required
          >
        </div>
      </div>
      <div class="col-sm-6" *ngIf="!update">
        <div class="form-group" formGroupName="passwordGroup" [customErrorMessages]="customErrorMessages">
          <label for="reg-pass-confirm">Confirm Password</label>
          <input
            class="form-control"
            type="password"
            id="reg-pass-confirm" 
            formControlName="confirmPassword"
            required
          >
        </div>
      </div>
      <div class="col-12 text-center text-sm-right mb-2">
        <button
          [disabled]="this.registerForm.invalid"
          class="btn btn-primary margin-bottom-none w-100"
          (click)="onSubmit()"
        >{{ buttonState }}</button>
      </div>
    </form>
    <p *ngIf="registrationError" class="error-message">{{ registrationErrorMessage }}</p>
    <p *ngIf="showSwitchText">Already have an account? <a class="text-decoration-none" [routerLink]="['/signin']">Sign in.</a></p>
  </div>
</div>
