
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../core/services/customer.service';
import { NotificationService } from '../../core/services/notification.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-tax-exempt-certificate',
  templateUrl: './tax-exempt-certificate.component.html',
  styleUrls: ['./tax-exempt-certificate.component.scss']
})
export class TaxExemptCertificateComponent implements OnInit {
  @Output() taxExemptCertCreated = new EventEmitter();
  @Output() formVisibility = new EventEmitter();

  title: string = "Add a New Tax Exempt Certificate";
  form: FormGroup;
  taxExemptFileData;
  hasFile: boolean = false;
  savingCert: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private notificationService: NotificationService,
    private http: HttpClient
  ) {
    
  }

  ngOnInit() {
    this.form = this.formBuilder.group({ 
      certificateNumber: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]]
    });
  }

  close() {
    this.formVisibility.emit(false);
  }

  // hat tip -> https://netbasal.com/how-to-implement-file-uploading-in-angular-reactive-forms-89a3fffa1a03
  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.taxExemptFileData = file;
      this.hasFile = true;
    } else {
      this.hasFile = false;
    }
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  save() {
    this.validateAllFormFields(this.form);
    if (this.form.invalid) {
      console.log("invalid form", this.form);
      return;
    }

    this.savingCert = true;

    const formData: FormData = new FormData();

    if(this.hasFile){
      formData.append('certificateFile', this.taxExemptFileData);
    }

    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'multipart/form-data;'
    }); 

    let options = {
      headers: httpHeaders
    };

    formData.append('certificateData', new Blob([JSON.stringify(this.form.value)], { type: 'application/json' }));

    this.http.post(`${environment.CUSTOMERS_API_URL}/tax_exempt_certificate`, formData, options).subscribe((result) => {
        this.savingCert = false;
      if (result) {
        this.taxExemptCertCreated.emit({
          taxExemptCertificate: result,
          type: 'create'
        });

        this.notificationService.broadcastSuccessNotification({
          message: 'Successfully added tax exempt certificate.',
          options: {
            timeout: true
          }
        });
      }
    });
  }
}
