<div class="modal-body">
  <div class="d-flex flex-row justify-content-between flex-align-center mb-4">
    <h4>Confirm</h4>
    <fa-icon [icon]="faTimes" (click)="bsModalRef.hide()" size="lg"></fa-icon>
  </div>
  <div class="mb-4">
    Are you sure you want to remove <b>{{ title }}</b> from your cart?
  </div>
  <div class="d-flex flex-row justify-content-between">
    <button class="btn btn-primary mr-2" (click)="executeCallback()">Remove Item</button>
    <button class="btn btn-light gray-border" (click)="bsModalRef.hide()">Cancel</button>
  </div>
</div>