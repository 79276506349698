import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  // constructor(public router:Router) { }

  ngOnInit() {
    // this creates a custom error with the requested route so it 
    //  can be caught and aggregated by sentry.
    // throw new Error(`Could not find route: ${this.router.url}`);
  }

}
