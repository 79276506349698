import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormAddressModel } from '../../shared/address.model';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CheckoutStepEnum, CheckoutWizardService } from '../checkout-wizard.service';
import { CheckoutDetailModel } from '../../shared/checkout-detail.model';
import { CheckoutService, CustomerService, NotificationService } from '../../core/services';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/analytics/analytics.service';


@Component({
  selector: 'app-shipping-address',
  templateUrl: './shipping-address.component.html',
  styleUrls: ['./shipping-address.component.scss']
})
export class ShippingAddressComponent implements OnInit {

  faPlus=faPlus;
  private ngUnsubscribe = new Subject<void>();
  editingAddress:FormAddressModel;
  public editingAddressSubject = new BehaviorSubject<FormAddressModel | null>(null);

  selectedAddressId = null;

  public CheckoutStepEnum = CheckoutStepEnum;
  checkoutDetails: CheckoutDetailModel;

  cart;
  sentAnalytics;

  constructor(
    private customerService: CustomerService,
    private checkoutService: CheckoutService,
    private notificationService: NotificationService,
    private analyticsService: AnalyticsService,
    private router: Router,
    public wizard:CheckoutWizardService
  ) { }

  // @Input() addresses;

  addresses = [];

  showAddressForm:boolean = false;

  ngOnInit(): void {

    this.wizard.cartService.cartSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(cart => {
      this.cart = cart;

      this.checkoutService.initializeCheckoutDetails(this.cart);

      this.customerService.getAddresses()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((addresses) => {
        if (addresses && addresses.length > 0) {
          this.addresses = addresses.filter(address => {
            return address.address_type === 'shipping'
          });
        } else {
          this.addNewAddress()
        }

        this.checkoutService.checkoutDetailsSubject
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(checkoutDetails => {
          if (checkoutDetails) {
            this.checkoutDetails = checkoutDetails;
            if (!checkoutDetails.checkoutDetailId) return;
            // if their checkout details have expired, bounce them back to the cart page
            if(this.checkoutService.checkoutDetailsExpired(checkoutDetails)){
              this.checkoutService.deleteCheckoutDetails(this.cart.cartId, checkoutDetails.checkoutDetailId).pipe(
                takeUntil(this.ngUnsubscribe)
              ).subscribe(res => {
                this.router.navigate(['/cart']);
                this.notificationService.broadcastFailureNotification({
                  message:"Your checkout session has expired please review your cart details to proceed",
                  options: {
                    timeout: true
                  }
                });
              })
            } else {
              // not expired and all data loaded; UI is in steady state;
              //  Now is a good time to fire the Google Analytics Enhanced 
              //  E-Commerce 'Checkout' event to begin checkout flow analytics
              if (this.cart && this.cart.checkoutDetailId && this.cart.items && this.cart.items.length > 0 && !this.sentAnalytics) {
                const evt = {
                  'event' : 'checkout',
                  'ecommerce': {
                    'checkout': {
                      'actionField': { 'step': 1 },
                      'products': []
                    }
                  }
                };
          
                this.cart.items.forEach((item) => {
                  const itemAnalytics = {
                    'name': item.partNumber,
                    'price': item.pricePerUnit,
                    'brand': 'Marco',
                    'category': 'O-Rings',
                    'quantity': item.quantity
                  };
                  evt.ecommerce.checkout.products.push(itemAnalytics);
                });
                this.analyticsService.trackEcom(evt);
                this.sentAnalytics = true;
              }
            }
    
            // if (!this.outboundShippingOption && checkoutDetails.outboundShippingOption) {
            //   this.outboundShippingOption = checkoutDetails.outboundShippingOption;
            // }
    
            // if (!this.shippingAccountOption && checkoutDetails.shippingAccountId) {
            //   this.shippingCollect = true;
            //   this.toggleShippingCollect();
            // }
    
            if (checkoutDetails.shippingAddressId && checkoutDetails.shippingAddressId !== this.selectedAddressId) {
              this.selectedAddressId = checkoutDetails.shippingAddressId;
              // this.getShippingOptions();
            } else if (!this.selectedAddressId && this.addresses.length) {
              this.selectNewAddress(addresses[0].customer_address_id);
            }
          }
        });
      });
    });



  }

  addNewAddress() {
    this.editingAddress = null;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  toggleAddressForm(bool) {
    this.showAddressForm = bool;
  }

  assignEditingAddress(address) {
    this.editingAddress = address;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  // selectNewAddress(tmp) {
  //   console.log("SELECT new address")
  // }

  selectNewAddress(newAddressId) {
    if (newAddressId !== this.checkoutDetails.shippingAddressId) {

      // this.shippingAddressesLoading = true;

      let checkoutDetailsPayload = new CheckoutDetailModel();
      checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
      checkoutDetailsPayload.shippingAddressId = newAddressId;
  
      this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
    } 
  }

  addressCreated(result) {
    if (result.type === 'update') {
      this.addresses = this.addresses.map(address => {
        if (address.customer_address_id === result.address.customer_address_id) {
          return result.address;
        } else {
          return address;
        }
      })

      this.editingAddress = null;
      this.editingAddressSubject.next(this.editingAddress);
    } else {
      this.addresses.push(result.address);
      this.selectNewAddress(result.address.customer_address_id);
    }
    this.toggleAddressForm(false);
  }


}
