import dayjs from 'dayjs';

export class UserAttributionModel {

    source: string = '';                // Source of traffic (e.g., "Organic", "Direct")
    customer_id?: number;               // User ID or session ID (can be tied to a user table)
    guest_id?: string;                  // Guest ID
    referrer_url?: string;              // URL of the referrer, if applicable
    utm_source?: string;                // UTM source (e.g., 'google', 'newsletter')
    utm_medium?: string;                // UTM medium (e.g., 'cpc', 'email')
    utm_campaign?: string;              // UTM campaign (e.g., 'spring_sale', 'product_launch')
    utm_term?: string;                  // UTM term (used for tracking paid search keywords)
    utm_content?: string;               // UTM content (used to differentiate ads or links)
    landing_page: string = '';          // The page where the user landed on the site
    gclid?: string;                     // Google click identifier
    attribution_end?: dayjs.Dayjs;      // Date or time when attribution ends
    

    constructor() {
    }


    // by default, an attribution lasts 30 days
    static getDefaultUserAttributionEnd():dayjs.Dayjs {
        return dayjs().add(30, 'days');
    }


}