<div class="w-100">
  <div class="d-flex pl-15">
    <h4>{{ addressTitle }}</h4>
  </div>
  <hr class="pb-1">
  <form [formGroup]="form" (ngSubmit)="validateAddress()">
    

    <div class="form-group">
      <label for="inputAddress">Nickname <span class="text-muted">(optional)</span></label>
      <input type="text" class="form-control" id="nickname" formControlName="nickname" name="nickname">
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-fn">First Name</label>
        <input type="email" class="form-control" id="checkout-fn" formControlName="firstName" name="firstName">
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Last Name</label>
        <input type="text" class="form-control" id="checkout-ln" formControlName="lastName" name="lastName">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="phoneNumber">Phone</label>
        <input type="phone" class="form-control" placeholder="XXX-XXX-XXXX" id="checkout-phone" formControlName="phoneNumber" name="phoneNumber" required>
        <!-- <div *ngIf="form.controls['phoneNumber'].hasError('invalidNumber') && form.controls['phoneNumber'].touched" class="form-warning">
          Please enter a valid phone number.
        </div> -->
      </div>
      <div class="form-group col-md-6">
        <label for="inputPassword4">Company <span class="text-muted">(optional)</span></label>
        <input type="text" class="form-control" id="inputPassword4" id="checkout-company" formControlName="company" name="company">
      </div>
    </div>

    <div class="form-group">
      <label for="inputAddress">Address 1</label>
      <input type="text" class="form-control" placeholder="Street Address" id="checkout-address1" formControlName="address1" name="address1">
    </div>
    <div class="form-group">
      <label for="inputAddress2">Address 2 <span class="text-muted">(optional)</span></label>
      <input type="text" class="form-control" placeholder="Apartment, studio, or floor" id="checkout-address2" formControlName="address2" name="address2">
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-city">City</label>
        <input type="text" class="form-control" id="checkout-city" formControlName="city" name="city">
      </div>
      <div class="form-group col-md-4">
        <label for="checkout-state">State</label>
        <select class="form-control" id="checkout-state" formControlName="state" name="state">
          <option>Choose State</option>
          <option>AL</option>
          <option>AK</option>
          <option>AZ</option>
          <option>AR</option>
          <option>CA</option>
          <option>CO</option>
          <option>CT</option>
          <option>DE</option>
          <option>FL</option>
          <option>GA</option>
          <option>HI</option>
          <option>ID</option>
          <option>IL</option>
          <option>IN</option>
          <option>IA</option>
          <option>KS</option>
          <option>KY</option>
          <option>LA</option>
          <option>ME</option>
          <option>MD</option>
          <option>MA</option>
          <option>MI</option>
          <option>MN</option>
          <option>MS</option>
          <option>MO</option>
          <option>MT</option>
          <option>NE</option>
          <option>NV</option>
          <option>NH</option>
          <option>NJ</option>
          <option>NM</option>
          <option>NY</option>
          <option>NC</option>
          <option>ND</option>
          <option>OH</option>
          <option>OK</option>
          <option>OR</option>
          <option>PA</option>
          <option>RI</option>
          <option>SC</option>
          <option>SD</option>
          <option>TN</option>
          <option>TX</option>
          <option>UT</option>
          <option>VT</option>
          <option>VA</option>
          <option>WA</option>
          <option>WV</option>
          <option>WI</option>
          <option>WY</option>
        </select>
        <div *ngIf="addressCorrections.state" class="form-modified">
          Your state was modified.
        </div>
      </div>
      <div class="form-group col-md-2">
        <label for="inputZip">Zip</label>
        <input type="text" class="form-control" id="checkout-zip" formControlName="zipCode" name="zipCode">
        <div *ngIf="addressCorrections.zipCode" class="form-modified">
          Your zip code was modified.
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="inputAddress">Country</label>
      <select class="form-control" id="checkout-country" formControlName="country" name="country">
        <option>Choose country</option>
        <option>USA</option>
      </select>

    </div>

    <div class="d-flex align-right paddin-top-1x mt-4" *ngIf="addressChanges">
      <p class="form-modified">We found an issue with the address provided and have attempted to correct it. Please review the changes above and verify or edit the address.</p>
    </div>
    <div class="d-flex align-right paddin-top-1x mt-4">
      <ng-container *ngIf="!validatingAddress">
        <button *ngIf="!addressChanges" [disabled]="!form.valid" class="btn btn-primary mr-2">
          Save Address
        </button> 
        <button *ngIf="addressChanges" [disabled]="!form.valid" class="btn btn-warning mr-2">
          Confirm Change
        </button> 
        <button (click)="close()" class="btn btn-info">
          Cancel
        </button> 
      </ng-container>
      <button *ngIf="validatingAddress" class="btn btn-primary" disabled>
        <div class="spinner-border spinner-border-sm text-white mr-2" role="status"></div>
        Validating
      </button>
    </div>
    <div *ngIf="poBoxWarning" class="text-danger text-bold">
      Sorry, we currently do not support shipping to PO Boxes. Please enter a new address to continue.
    </div>
  </form>
</div>



<ng-template #addressConfirmTemplate>
  <div class="modal-body text-center">
    <p>We were unable to confirm this address.  Are you sure it's correct?</p>
    <button type="button" class="btn btn-default" (click)="confirmAddressCorrect()" >Yes - Continue</button>
    <button type="button" class="btn btn-primary" (click)="declineAddress()" >No - let me make changes</button>
  </div>
</ng-template>