import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  dataLayer: any[] = (window as any).dataLayer || [];

  constructor() {
  }
  

  public trackEcom(evt: any) {
    // this fixes duplicate purchase problem: (see: https://stackoverflow.com/questions/66972023/google-analytics-duplicate-transaction)
    //  and https://developers.google.com/tag-manager/enhanced-ecommerce#checkout
    this.dataLayer.push({ ecommerce: null });
    // this leverages a custom GTM trigger to forward 
    //  this realtime event directly to GA without having to rely history/page
    //  trigger; which is ineffective on multi event-per-page workflows; eg checkout
    evt['event'] = 'GAEvent';
    evt['eventCategory'] = 'ecommerce tracking'; // so GA event reporiting doesn't show 'Undefined'
    this.dataLayer.push(evt);
  }

  public track(evt: any) {
    this.dataLayer.push(evt);
  }

  public trackOrder(orderId, orderDetails, cart) {
    const evt = {
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': orderId,
            'affiliation': 'Marcorubber Online Store',
            'revenue': orderDetails.cartTotal,
            'tax': 0,  // TODO: link to taxes once implemented
            'shipping': orderDetails.shippingCost,
          },
          'products': []
        }
      }
    };

    cart.items.forEach((item) => {
      const itemAnalytics = {
        'name': item.partNumber,
        'price': item.pricePerUnit,
        'brand': 'Marco',
        'category': 'O-Rings',
        'quantity': item.quantity
      };
      evt.ecommerce.purchase.products.push(itemAnalytics);
    });

    // record purchase
    this.trackEcom(evt);
  }

  public trackEvent(eventCategory, eventAction, eventLabel, eventValue) {
    this.track({
      event: 'GAEvent',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    });
  }
}
