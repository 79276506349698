import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';

import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MetaGuard implements CanActivate, CanActivateChild {
  constructor(private readonly metaService: Meta,
    private titleService: Title) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    
    const url = state.url;

    // see if this route has metadata defined
    const metaSettings = route.hasOwnProperty('data') ? route['data'] : undefined;
    
    if (metaSettings && metaSettings.meta) {
      for (let key in <Object>metaSettings.meta) {
        const val = metaSettings.meta[key];
        if (val) {
          if (key === 'title') {
            this.titleService.setTitle(val);
          } else if (key === 'description') { 
            this.addOrModifyTag({ name: 'description', content: val });
            this.addOrModifyTag({ property: 'og:description', content: val });
          } else {
            this.addOrModifyTag({ name: key, content: val });
          }
        }
      }
    } else {
      console.warn(`Route ${url} has no SEO data`);
    }
    return true;
  }

  private addOrModifyTag(tag: MetaDefinition) {
    if (!this.metaService.updateTag(tag)) {
      this.metaService.addTag(tag);
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}