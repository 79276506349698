<div style="width: 100%">
    <hr class="pb-1">
    <div class="d-flex">
      <h5>{{ title }}</h5>
    </div>
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="form-group">
        <label for="certificateNumber">Certificate Number</label>
        <input class="form-control" type="text" id="certificateNumber" formControlName="certificateNumber" name="certificateNumber">
        <div *ngIf="form.controls['certificateNumber'].hasError('required') && form.controls['certificateNumber'].touched" class="form-warning">
          Field is required.
        </div>
      </div>

      <div class="form-group">
        <label class="mr-2" for="taxExemptFile">Upload Signed Certificate: </label>
        <input type="file" (change)="onFileChange($event)" name="taxExemptfile" id="taxExemptfile"/>
      </div>

      <div class="d-flex align-right pt-1 mt-4 mb-2">
        <button *ngIf="!savingCert" class="btn btn-primary  mr-2 ">
          Save Certificate
        </button>
        <button *ngIf="savingCert" class="btn btn-primary  mr-2" disabled>
          <div class="spinner-border spinner-border-sm text-white mr-2" role="status"></div>
          Save Certificate
        </button>
        <button (click)="close()" class="btn btn-info">
          Cancel
        </button>
      </div>
    </form>
  </div>
    