
export class FulfillmentOptionModel {
    productSubtotal: string;
    pricePerUnit: number;
    totalPrice: number = 0;
    product: object =  {};
    quantity = 0;
    itemDetails: object = {};
    logisticsFee: number = 0;
}

export class FullRfqModel {
    product: object = {};
    quantity = 0;
    reason: string; 
    requestedLeadTime: string;
    comment?: string;
}


export class CartPayloadModel {
    guestId?: number;
    cartId?: number;
    customerId?: number;
    checkoutDetailId?:number;
    items: CartPayloadItemModel[] = [];
    rfqItems: CartPayloadRfqModel[] = [];
    serviceFee?: number;

    constructor(
      data?: Partial<CartPayloadModel>
    ) {
      if (data) Object.assign(this, data);
    }

    public updateOrAddCartItems(itemToUpdateOrAdd, cart) {
      let newCartItems = cart.items.map(cartItem => {
        if (cartItem.productId === itemToUpdateOrAdd.productId) {
          let newCartItem = new CartPayloadItemModel();
          Object.keys(cartItem).forEach((itemKey)=>{
            newCartItem[itemKey] = itemToUpdateOrAdd[itemKey] || cartItem[itemKey];
          });
    
          // We know pricing isn't expired because we just added it, so overwrite this value no matter what
          newCartItem.pricingExpired = 0;
          cartItem = newCartItem;
        }
        return cartItem;
      });

      cart.items = newCartItems;
    
      return cart;
    }

    public updateOrAddRfqItems(rfqToUpdateOrAdd, cart) {
      let newRfqItem = new CartPayloadRfqModel({
        cartId: cart.cartId,
        rfqItemId: 0,
        productId: rfqToUpdateOrAdd.product._id,
        quantity: rfqToUpdateOrAdd.quantity,
        requestedLeadTime: rfqToUpdateOrAdd.requestedLeadTime,
        reason: rfqToUpdateOrAdd.reason,
        yearlyQuantity: 0,
        partNumber: '',
        targetPrice: 0,
        comment: rfqToUpdateOrAdd.comment,
        itemTitle: '',
      });

      cart.rfqItems.push(newRfqItem);

      let newCartItems = cart.items.filter(cartItem => {
        return cartItem.productId != rfqToUpdateOrAdd.product._id
      });
    
      cart.items = newCartItems;
    
      return cart;
    }

    public removeItemFromCart(itemId, cart) {
      let newCartItems = cart.items.filter(cartItem => {
        return cartItem.itemId !== itemId
      })

      cart.items = newCartItems;

      return cart;
    }

    public removeRfqItemFromCart(rfqItemId, cart) {
      let newRfqItems = cart.rfqItems.filter(rfqItem => {
        return rfqItem.rfqItemId !== rfqItemId
      })

      cart.rfqItems = newRfqItems;

      return cart;
    }


    public getCartState() {
      let itemsLength = this.items.length;
      let rfqItemsLength = this.rfqItems.length;
      if (itemsLength === 0 && rfqItemsLength > 0) {
        return 'rfqsOnly';
      } else if (itemsLength > 0 && rfqItemsLength > 0) {
        return 'hybrid';
      } else {
        return 'itemsOnly';
      }
    }

  
    getTotalAmountStr() {
      return this.getTotalAmount().toFixed(2);
    }
  
    getItemSubtotalAmountStr(){
      return this.getItemSubtotalAmount().toFixed(2);
    }

    public getItemSubtotalAmount(): number {
      let itemSubtotal =  this.items.reduce((prev, curr: any) => {
        return prev + +curr.totalPrice;
      }, 0);
      
      return itemSubtotal;
    }
  
    public getTotalAmount(): number {
      let itemSubtotal =  this.items.reduce((prev, curr: any) => {
        return prev + +curr.totalPrice;
      }, 0);
  
      if(this.serviceFee){
        itemSubtotal += +this.serviceFee;
      }
      return itemSubtotal;
    }
  

}

export class CartPayloadItemModel {
    cartId: number;
    itemId: number;
    productId: number;
    quantity: number;
    price: string;
    pricePerUnit: number;
    logisticsFee: number;
    totalPrice: number;
    weight: number;
    title: string;
    partNumber: string;
    productDetails: object;
    itemDetails: object[];
    pricingExpired?: number;
    customProductLabel: string;
    quoteItemQuantityBreakSource: any;
    itemTitle: string;
    categoryUrlSlug: string;
  }

export class CartPayloadRfqModel {
    cartId: number;
    rfqItemId: number;
    productId: number;
    quantity: number;
    requestedLeadTime: string;
    reason: string;
    yearlyQuantity: number;
    partNumber: string;
    targetPrice: number;
    comment: string;
    itemTitle: string;
    thumbnailPath?: string;
    categoryUrlSlug?: string; 
    customProductLabel?: string;
    quoteItemQuantityBreakSource?: any;

    constructor(
      data?: CartPayloadRfqModel
    ) {
      if (data) Object.assign(this, data);
    }
}
