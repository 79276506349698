export const environment = {
  production: true,
  enableSentry: true,
  enableAdminTool: true,
  enableServiceWorker: true,
  baseUrl: 'https://www.marcorubber.com',
  API_URL: 'https://www.marcorubber.com/api/pricing',
  CUSTOMERS_API_URL: 'https://www.marcorubber.com/api/customer',
  INTEGRATION_API_URL: 'https://www.marcorubber.com/api/integration',
  capchaSiteKey: '6Let2scZAAAAAKdBC9oeRvsChzzmr6lyaJLWq_O2',
  capchav2SiteKey: '6LcMthYUAAAAADi-mVvawXjecfy0lY7AmbiX-C56',	
  SOLUPAY_TOKENIZATION_KEY: '2B7NYt-tvRK67-7tTU9h-9xrs67',
  VERSION: '1.0.12',
  environment: 'production',
  enableDrift: true
};
