import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PricingApiService } from './pricing-api.service';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor(
    private apiService: PricingApiService, 
  ) { }

  public submitFeedback(feedback): Observable<any> {
    return this.apiService.post('/feedback', feedback).pipe(catchError(this.formatErrors));
  }
}
