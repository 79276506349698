import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { LoginModel } from '../../shared/login.model';
import { CustomerService } from '../../core/services/customer.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import dayjs from 'dayjs';
import { SalesAttributionModel } from '../../shared/sales-attribution.model';
import { UserAttributionModel } from 'src/app/shared/user-attribution-model';
import { AttributionService } from './attribution.service';

import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthService {

  private formatErrors(error: any) {
    return throwError(error);
  }

  public loggedInStatusSubject = new BehaviorSubject<boolean>(this.hasCustomerToken());

  // loginExpiredStatusSubject is true when we have an actual customer, but their token has expired
  public loginExpiredStatusSubject = new BehaviorSubject<boolean>(false);

  public entitlementSubject = new BehaviorSubject<boolean>(false);

  public hasTokenSubject = new BehaviorSubject<boolean>(false);
  public logoutSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private jwtHelper: JwtHelperService,
    private attributionService: AttributionService,
    private customerService: CustomerService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute,
  ) {
  }

  login(customer: LoginModel) {
    const decoded = this.jwtHelper.decodeToken();
    let guestId;
    if(decoded && decoded.user){
      guestId = decoded.user.guest_id;
    }
    const post = this.customerService.login(customer).pipe(shareReplay());
    this.loggedInStatusSubject.next(true);
    post.subscribe(res => {
      this.setSession(res);
      let decodedUser = this.jwtHelper.decodeToken();
      let customerId;
      if(decodedUser && decodedUser.user && guestId){
        customerId = decodedUser.user.customer_id;
        this.attributionService.guestConvert(customerId, guestId).subscribe();
      }
      this.customerService.getCustomer();
      this.checkEntitlement();
    });
    return post;
  }


  // this is called on inital page load; refresh tokens accordingly
  //  and perform the intital load of the customer and cart if 
  //  appropriate to seed the cache of these objects
  initializeUser() {
    this.checkToken(); // fix any expirations
    if (isPlatformBrowser(this.platformId)) {
      if (this.hasCustomerToken()) {
        this.loggedInStatusSubject.next(true);
        this.customerService.getCustomer();
        this.checkEntitlement();
        this.hasTokenSubject.next(true);
      } else if (this.hasGuestToken()) {
        this.hasTokenSubject.next(true);
      } else {
        this.registerGuest().subscribe(() => {
          this.hasTokenSubject.next(true);
        })
      }
    }
  }

  checkEntitlement = () => {
    if (environment.enableAdminTool && this.loggedInStatusSubject.getValue()) {
      this.customerService.getEntitlement('view_admin_ui').subscribe(res => {
        if (res) {
          this.entitlementSubject.next(true);
        } else {
          this.entitlementSubject.next(false);
        }
      }, (e) => {
        console.log(e);
        this.entitlementSubject.next(false);
      })
    }
  }

  checkToken() {
    const token = this.jwtHelper.decodeToken();

    // Scenario 1: Check to make sure there is a token and that the token is not expired
    if (token && !this.jwtHelper.isTokenExpired()) {
      let tokenExpiration = dayjs.unix(token.exp);

      // Get the difference in minutes between the token expiration and right now
      let tokenAgeInMinutes = tokenExpiration.diff(dayjs(), 'minutes');

      // If the token is older than 5 minutes then refresh it (note - 1440 minutes is 24 hours)
      if (tokenAgeInMinutes < 1435) {
        if (token.user.customer_id) {
          // This token belongs to a user. Let's refresh the user token
          this.refreshUserToken();
        } else if (token.user.guest_id) {
          // This token belongs to a guest. Let's refresh the guest token
          this.refreshGuestToken();
        }
      }
    } else if (token  && token.user.guest_id && this.jwtHelper.isTokenExpired()) {
      // Scenario 2: Check if there is a token present, the token is expired and the user is a guest
      this.refreshGuestToken();
    } else if (token && token.user.customer_id && this.jwtHelper.isTokenExpired()) {
      // Scenario 2: Check if there is a token present, the token is expired and the user is a customer
      this.logout();
    }
  }


  refreshUserToken() {
    const post = this.customerService.refreshToken().pipe(shareReplay());
    post.subscribe(res => this.setSession(res));
    return post;
  }

  refreshGuestToken() {
    const post = this.customerService.refreshGuestToken().pipe(shareReplay());
    post.subscribe(res => this.setSession(res));
    return post;
  }

  registerGuest() {
    const guestObs = this.customerService.registerGuest();
    guestObs.subscribe(res => {
      this.setSession(res)
    });
    return guestObs;
  }

  private setSession(authResult) {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.setItem('id_token', authResult.token);
    }
  }

  setAttribution(attribution: SalesAttributionModel) {
    if (attribution) {
      window.localStorage.setItem('attribution', attribution.attribution);
      window.localStorage.setItem('attribution_end', JSON.stringify(attribution.attributionEnd.valueOf()));
      window.localStorage.setItem('attribution_url', attribution.url);

      const decoded = this.jwtHelper.decodeToken();

      if (decoded?.user) {
        if (decoded.user.customer_id) {
          attribution.customerId = +decoded.user.customer_id;
        }
        if (decoded.user.guest_id) {
          attribution.guestId = decoded.user.guest_id;
        }
        if (attribution.guestId || attribution.customerId) {
          this.attributionService.saveAttribution(attribution).subscribe();
        }
      }

    }
  }
  


  public getAttribution(): SalesAttributionModel {
    let sa: SalesAttributionModel;
    const attr = window.localStorage.getItem('attribution');
    const attrEnd = dayjs(JSON.parse(window.localStorage.getItem('attribution_end')));
    if (attr && attrEnd) {
      if (dayjs().isAfter(attrEnd)) {
        this.clearAttribution();
      } else {
        sa = new SalesAttributionModel();
        sa.attribution = attr;
        sa.attributionEnd = attrEnd;
        sa.url = window.localStorage.getItem('attribution_url');
      }
    }
    return sa;
  }

  public setUserAttribution() {

    //get stored attribution, if it doesnt exist or is expired, get new attribution

    // First see if user already has attribution
    const attributionSource = window.localStorage.getItem('attribution_source');
    const attributionTrackEnd = dayjs(JSON.parse(window.localStorage.getItem('attribution_track_end')));

    if (attributionSource && attributionTrackEnd) {
      //if current attribution is expired
      if (dayjs().isAfter(attributionTrackEnd)) {
        // we might want to clear in the future, but for now if its expired, keep going and set it again
      } else {
        return attributionSource;
      }
    }

    let userAttribution = new UserAttributionModel();
    

    // Source is our main concern here, everything else is nice to have
    // Check if there's a referrer
    const referrer = document.referrer || '';
    let source: string | null = null;

    // If a referrer is detected, check if it's an organic search or another site
    if (referrer) {
      if (referrer.includes('google.com') || referrer.includes('bing.com')) {
        source = 'Organic';
      } else {
        source = `Referral`; // Non-organic, other referrer
      }
      userAttribution.referrer_url = referrer;
    }

    // Get UTM parameters from the query string
    this.route.queryParams.subscribe(params => {
      const utmSource = params['utm_source'];
      const utmMedium = params['utm_medium'];
      const utmCampaign = params['utm_campaign'];
      const gclid = params['gclid'];

      // If UTM parameters are present, this overrides the referrer
      if (utmSource) {
        source = `Paid Search`;
        userAttribution.utm_source = utmSource;
      }

      if (utmMedium) {
        userAttribution.utm_medium = utmMedium;
      }
      if (utmCampaign) {
        userAttribution.utm_campaign = utmCampaign;
      }
      if (gclid) {
        userAttribution.gclid = gclid;
      }

      // If no source was determined so far, it's assumed to be direct
      if (!source) {
        source = 'Direct Traffic';
      }

      userAttribution.landing_page = window.location.href;

      userAttribution.source = source;

      const decoded = this.jwtHelper.decodeToken();  

      if (decoded?.user) {
        if (decoded.user.customer_id) {
          userAttribution.customer_id = +decoded.user.customer_id;
        }
        if (decoded.user.guest_id) {
          userAttribution.guest_id = decoded.user.guest_id;
        }
        if (userAttribution.guest_id || userAttribution.customer_id) {

          // Store the determined source in localStorage for session tracking
          window.localStorage.setItem('attribution_source', source);
          window.localStorage.setItem('attribution_track_end', JSON.stringify(UserAttributionModel.getDefaultUserAttributionEnd().valueOf()));
          this.attributionService.saveUserAttribution(userAttribution).subscribe();
        }
      }

    });
  }

  // we clear attribution 1) upon purchase 2) if we detect that its expired
  //  which is checked on item add && customer create
  public clearAttribution() {
    window.localStorage.removeItem('attribution');
    window.localStorage.removeItem('attribution_end');
    window.localStorage.removeItem('attribution_url');
  }


  public extendAttribution(newEndDt: dayjs.Dayjs) {
    window.localStorage.setItem('attribution_end', JSON.stringify(newEndDt.valueOf()));
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      window.localStorage.removeItem('id_token');
      window.localStorage.removeItem('customer_id');
      // Note; do not clear attribution - rather, set it going forward
      this.loggedInStatusSubject.next(false);
      this.loginExpiredStatusSubject.next(false);
      this.registerGuest().subscribe(() => {
        this.customerService.clearCustomer();
        this.checkEntitlement();  
      });
    }
  }


  isLoggedIn(): Observable<boolean> {
    return this.loggedInStatusSubject.asObservable().pipe(catchError(this.formatErrors));;
  }

  customerLoginExpired(): Observable<boolean> {
    return this.loginExpiredStatusSubject.asObservable().pipe(catchError(this.formatErrors));;
  }

  public hasCustomerToken():boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const decoded = this.jwtHelper.decodeToken();
    return !!decoded?.user?.customer_id;
  }

  public hasGuestToken():boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    const decoded = this.jwtHelper.decodeToken();
    return !!decoded?.user?.guest_id;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

}
