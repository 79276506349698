import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

import { AnalyticsService } from '../../analytics/analytics.service';

@Injectable({ providedIn: 'root' })
export class LocationService { 

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) { }


  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  public isDomestic(){

    if (this.isBrowser()){

      let usTimeZones = [
        'America/Adak',
        'America/Anchorage',
        'America/Atka',
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Fort_Wayne',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Indianapolis',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Knox_IN',
        'America/Los_Angeles',
        'America/Louisville',
        'America/Menominee',
        'America/Metlakatla',
        'America/New_York',
        'America/Nome',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Phoenix',
        'America/Shiprock',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Honolulu',
        'Pacific/Johnston'
      ];

      let userTimezone = dayjs.tz.guess();

      if(usTimeZones.indexOf(userTimezone) === -1){
        this.analyticsService.trackEvent('Set international', userTimezone, null, null);
      }

      return usTimeZones.indexOf(userTimezone) > -1;
    } else { // default to domestic behavior if its ssr
      return true;
    }
  }
  
}
