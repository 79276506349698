<div id="content" class="container pb-3 pt-3 mb-2">
  <div class="row">
    <div class="col-md-6 offset-md-3 py-5">
      <div class="card">
        <div class="card-body">
          <h4 class="mb-4">Sign In</h4>
          <form class="row" [formGroup]="loginForm">
            <div class="form-group col-12">
              <label for="signin-email">E-mail Address</label>
              <input
                type="text"
                id="signin-email"
                formControlName="email"
                name="email"
                class="form-control"
                type="email"
                placeholder="Email"
                required
              >
            </div>
            <div class="form-group col-12">
              <label for="signin-pwd">Password</label>
              <input
                class="form-control"
                formControlName="password"
                id="signin-pwd"
                type="password"
                placeholder="Password"
                required
              >
            </div>
            <div class="col-12 d-flex flex-wrap justify-content-between mb-4">
              <div class="custom-control custom-checkbox align-middle">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  id="remember_me"
                  checked
                >
                <label class="custom-control-label" for="remember_me">Remember me</label>
              </div>
              <div class="link-style" [routerLink]="['/forgot-password']">
                Forgot password?
              </div>
            </div>
            <div *ngIf="loginError" class="col-12 d-flex flex-wrap justify-content-between pb-1">
              <div>
                {{loginErrorMessage}}
              </div>
            </div>
            <div class="col-12 text-center mb-4">
              <button
                [disabled]="this.loginForm.invalid"
                class="btn btn-primary margin-bottom-none w-100"
                (click)="customerLogin()"
              >Login</button>
            </div>
          </form>
          <p *ngIf="showSwitchText">Don't have an account? <a class="link-style" [routerLink]="['/register']">Create one now.</a></p>
        </div>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>
</div>