<div class="container-fluid pb-3 mb-2 pt-1">
  <div class="row">
    <div class="col-md-12">
      <ng-container *ngIf="validEmail || (customerLoginExpired | async)">
        <app-signin-form [returnUrl]="returnTo" [email]="form.value.email"></app-signin-form>
      </ng-container>
      <ng-container *ngIf="!(customerLoginExpired | async)">
        <ng-container *ngIf="!validEmail && !loaded">
          <div class="card">
            <form class="row card-body" [formGroup]="form">
              <div class="col-md-12">
                <h4>Please enter your email to begin.</h4>
                <div class="form-group col-12">
                  <label for="signin-email">E-mail Address</label>
                  <input type="text" id="signin-email" formControlName="email" name="email" class="form-control" type="email" placeholder="Email" required>
                </div>
                <div class="col-12 text-center text-sm-right">
                  <button class="btn btn-primary margin-bottom-none" (click)="validateEmail()">Continue</button>
                </div>
              </div>
            </form>
          </div>
        </ng-container>
        <ng-container *ngIf="!validEmail && loaded">
          <div class="container pb-3 mb-2">
            <div class="row">
              <div class="col-md-10 md-offset-2">
                <app-register-form [returnUrl]="returnTo" [email]="form.value.email" [showMessage]=true></app-register-form>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>