import { Component, OnInit } from '@angular/core';
import { CheckoutStepEnum, CheckoutWizardService } from '../checkout-wizard.service';

@Component({
  selector: 'app-shipping-address-edit',
  templateUrl: './shipping-address-edit.component.html',
  styleUrls: ['./shipping-address-edit.component.scss']
})
export class ShippingAddressEditComponent implements OnInit {

  CheckoutStepEnum = CheckoutStepEnum;

  constructor(public wizard:CheckoutWizardService) { 

  }

  ngOnInit(): void {
  }



  addressCreated(event:Event) {
    console.log("Address created")
  }

  toggleAddressForm(event:Event) {
    console.log("toggle form")
    this.wizard.goStep(CheckoutStepEnum.CHOOSE_ADDRESS);
  }
}
