import { Component, OnInit, PLATFORM_ID, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { CheckoutService } from '../../core/services/checkout.service';
import { CartService } from '../../core/services/cart.service'
import { DateService } from '../../core/services/date.service';
import { CustomerService } from '../../core/services/customer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckoutDetailModel } from '../../shared/checkout-detail.model';
import { AuthService } from '../../core/services/auth.service';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from '../../core/services/notification.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { FormAddressModel } from 'src/app/shared/address.model';
import { CheckoutWizardService, CheckoutStepEnum } from '../checkout-wizard.service';




@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  @ViewChild("checkoutFlowTop", { static: true }) checkoutFlowTop: ElementRef;

  faChevronLeft = faChevronLeft;
  faCreditCard = faCreditCard;
  faInfoCircle = faInfoCircle;
  faCircleNotch = faCircleNotch;
  CheckoutStepEnum = CheckoutStepEnum;

  solupayReady = false;
  submitSolupayForm = false;
  solupayValidationPending = false;

  cardForm: FormGroup;
  taxExemptForm: FormGroup;
  paymentDetailsForm: FormGroup;

  editingAddress: FormAddressModel;
  public editingAddressSubject = new BehaviorSubject<FormAddressModel | null>(null);

  billingSameAsShipping = true;


  hasTerms = false;
  hasCardOnFile = false;


  showAddressForm = false;
  showTaxExemptCertFrom = false;

  checkoutDetails: CheckoutDetailModel;
  shippingMethod = null;
  shippingAccount = null;
  shippingAddress = null;

  isCreditGroupOpen = true;
  isPoGroupOpen = false;
  selectedAddressId;
  selectedTaxCertId;
  taxAmount;
  recalculateTax = true;

  paymentErrorMessage = '';
  genericErrorMessage = '';
  sendBackTocart = false;

  orderForSubmission;

  cart;
  customer;
  orderDetails = null;
  addresses = [];
  taxExemptCertificates = [];
  sentAnalytics = false;
  solupayTokenRecieved = false;

  defaultsInitialized = false;

  reviewOrderPressed = false;
  btnSpinny = false;


  private ngUnsubscribe = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    public checkoutService: CheckoutService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private dateService: DateService,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    public checkoutWizard: CheckoutWizardService,
    private zone: NgZone
  ) {
  }

  public useCreditTerms() {
    return this.paymentDetailsForm.get('paymentMethod').value === "terms";
  }

  public useNewCard() {
    return this.paymentDetailsForm.get('paymentMethod').value === "newCard";
  }

  public useCardOnFile() {
    return this.paymentDetailsForm.get('paymentMethod').value === "savedCard";
  }

  ngOnInit() {
    // initialize forms
    this.paymentDetailsForm = this.formBuilder.group({
      paymentMethod: ['newCard', Validators.required],
      customerPurchOrdNum: [null]
    });

    this.cardForm = this.formBuilder.group({
      cardHolderName: [null, [Validators.required]],
      saveCard: [true],
      applyForCredit: [false]
    });

    this.taxExemptForm = this.formBuilder.group({
      taxExemptCertNumber: [''],
      taxExemptFile: ['']
    });

    // clear any previously acquired payment tokens
    this.checkoutService.checkoutCardSubject.next({ loading: true });

    this.cartService.cartSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(cart => {
      this.cart = cart;
      if (cart)
        this.checkoutService.initializeCheckoutDetails(this.cart);
    });


    this.customerService.userSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cust => {
      this.customer = cust;
      if (this.customer.last_card_lastfour && this.customer.last_card_type) {
        this.hasCardOnFile = true;
      }
    })



    this.customerService.getTaxExemptCertificates().pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((result) => {
        this.taxExemptCertificates = result;
      })

    this.customerService.getAddresses()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((addresses) => {
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
        }

        this.checkoutService.checkoutDetailsSubject
          .pipe(
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe(checkoutDetails => {
            if (checkoutDetails) {
              this.checkoutDetails = checkoutDetails;

              // here we set up  some cascading defaults;
              //  1. if customer has terms, that is the overriding default
              //  2. else if the customer has a saved card; that is the default; default to resave card
              //  3. otherwise, show the card form and default to saving the card
              this.customerService.hasTerms().pipe(
                takeUntil(this.ngUnsubscribe)
              )
                .subscribe((hasTerms) => {
                  this.hasTerms = hasTerms;
                  if (!this.defaultsInitialized) {
                    if (this.hasTerms && checkoutDetails.useCreditTerms) {
                      // if this user has terms set up, set the radio state as the default and update the checkout details
                      this.paymentDetailsForm.get('paymentMethod').setValue('terms')
                      this.setCheckoutDetailItem({ useCreditTerms: true, useNewCard: false, useSavedCard: false, saveCard: true });
                    } else {
                      if (this.hasCardOnFile) {
                        this.paymentDetailsForm.get('paymentMethod').setValue('savedCard')
                        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: false, useSavedCard: true, saveCard: true });
                      } else {
                        this.paymentDetailsForm.get('paymentMethod').setValue('newCard')
                        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: true, useSavedCard: false, saveCard: true });
                      }
                    }
                    this.defaultsInitialized = true;
                  }
                });

              if (!checkoutDetails.checkoutDetailId) {
                this.router.navigate(['/checkout/shipping-address'])
              } else {

                if (this.checkoutService.checkoutDetailsExpired(checkoutDetails)) {
                  this.checkoutService.deleteCheckoutDetails(this.cart.cartId, checkoutDetails.checkoutDetailId).pipe(
                    takeUntil(this.ngUnsubscribe)
                  ).subscribe(res => {
                    this.notificationService.broadcastFailureNotification({
                      message: "Your checkout session has expired please review your cart details to proceed",
                      options: {
                        timeout: true
                      }
                    });
                    this.router.navigate(['/cart']);
                  });
                } else {
                  if (!checkoutDetails.shippingAddressId || !checkoutDetails.outboundShippingOption) this.router.navigate(['/checkout/shipping-address']);
                  this.calculateTaxAmount();
                  if (checkoutDetails.taxExemptCertId) {
                    this.selectedTaxCertId = checkoutDetails.taxExemptCertId;
                  } else {
                    // default to auto select first cert if there's at least one and 
                    //   none already selected.
                    if (this.taxExemptCertificates.length && !this.selectedTaxCertId) {
                      this.selectNewTaxExemptCert(this.taxExemptCertificates[0].id, true);
                    }
                  }

                  if (checkoutDetails.applyForCredit) {
                    this.cardForm.get('applyForCredit').setValue(checkoutDetails.applyForCredit);
                  }

                  if (checkoutDetails.saveCard) {
                    this.cardForm.get('saveCard').setValue(true);
                  } else {
                    this.cardForm.get('saveCard').setValue(false);
                  }

                  if (checkoutDetails.poNumber) {
                    this.paymentDetailsForm.get('customerPurchOrdNum').setValue(checkoutDetails.poNumber);
                  }

                  if (checkoutDetails.shippingAccountId && !this.shippingAccount) {
                    this.customerService.getShippingAccountById(checkoutDetails.shippingAccountId)
                      .pipe(
                        takeUntil(this.ngUnsubscribe)
                      )
                      .subscribe((shippingAccount) => {
                        this.shippingAccount = shippingAccount;
                      });
                  }

                  if (!this.shippingMethod && checkoutDetails.outboundShippingOption) {
                    this.shippingMethod = {
                      outboundShippingCost: checkoutDetails.outboundShippingCost,
                      outboundShippingDurationTerms: checkoutDetails.outboundShippingDurationTerms,
                      outboundShippingEstimatedDays: checkoutDetails.outboundShippingEstimatedDays,
                      outboundShippingName: checkoutDetails.outboundShippingName,
                      outboundShippingOption: checkoutDetails.outboundShippingOption,
                      outboundShippingProvider: checkoutDetails.outboundShippingProvider,
                      outboundShippingProviderImage: checkoutDetails.outboundShippingProviderImage,

                    }
                  }

                  if (!this.shippingAddress && this.addresses.length) {
                    this.shippingAddress = this.addresses.find(address => {
                      return address.customer_address_id === this.checkoutDetails.shippingAddressId;
                    })
                  }

                  if (checkoutDetails.billingAddressId && checkoutDetails.billingAddressId !== this.selectedAddressId) {
                    if (checkoutDetails.billingAddressId === checkoutDetails.shippingAddressId) {
                      this.billingSameAsShipping = true;
                      this.selectedAddressId = checkoutDetails.shippingAddressId;
                    } else {
                      const billingAddressLookup = this.addresses.find(address => {
                        return address.customer_address_id === checkoutDetails.billingAddressId;
                      });

                      if (!billingAddressLookup) {
                        this.selectNewAddress(this.checkoutDetails.shippingAddressId);
                        this.billingSameAsShipping = true;
                      } else {
                        this.selectedAddressId = checkoutDetails.billingAddressId;
                        this.billingSameAsShipping = false;
                      }
                    }
                  } else if (!checkoutDetails.billingAddressId && this.billingSameAsShipping) {
                    this.selectNewAddress(this.checkoutDetails.shippingAddressId);
                    this.billingSameAsShipping = true;
                  }

                  // Only send this analytic event when we've loaded a cart with details
                  //  AND these details are not expired.
                  //  Record that the user has reached the review (step 2) phase and is 
                  //  checking out.  This was revised to send the products array every time
                  //  we send a checkout event.
                  if (!this.sentAnalytics) {
                    const evt = {
                      'event': 'checkout',
                      'ecommerce': {
                        'checkout': {
                          'actionField': { 'step': 3 },
                          'products': []
                        }
                      }
                    };
                    this.cart.items.forEach((item) => {
                      const itemAnalytics = {
                        'name': item.partNumber,
                        'price': item.pricePerUnit,
                        'brand': 'Marco',
                        'category': 'O-Rings',
                        'quantity': item.quantity
                      };
                      evt.ecommerce.checkout.products.push(itemAnalytics);
                    });
                    this.analyticsService.trackEcom(evt);
                    this.sentAnalytics = true;
                  }
                }
              }
            }
          });
      });


    // Set up a single subscription to the card state to 
    //  transit to review step upon submission request
    //  paired with async card validation / token generation.
    //  and subscribe to result in order to continue
    this.checkoutService.checkoutCardSubject
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        if (res.paymentToken && this.solupayReady && this.btnSpinny) {
          // we have a paymentoken now, we can proceed to review; nb this has to
          //   be run outside of the ng cd loop:
          this.zone.run(() => {
            this.checkoutWizard.goStep(CheckoutStepEnum.REVIEW)
          });
        } else if (!res.paymentToken || !this.solupayReady) {
          // this catches certain validation failures and resets
          this.reviewOrderPressed = false;
          this.btnSpinny = false;
        }
      });


  }

  ngAfterViewInit() {
    // if (this.checkoutFlowTop) {
    //   this.checkoutFlowTop.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
    // }
  }

  calculateTaxAmount() {
    if (!this.checkoutDetails.taxAmount) {
      this.cartService.fetchTaxes();
      this.cartService.taxes$.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(res => {
        this.taxAmount = res['taxAmount'];
      },
        err => {
          console.error('Error calculating tax', err);
          if (this.recalculateTax) {
            this.recalculateTax = false;
            setTimeout(() => {
              this.calculateTaxAmount();
            }, 3000);
          } else {
            this.genericErrorMessage = "We're sorry there was an issue calculating taxes. Please refresh or try again later."
          }
        })
    } else {
      this.taxAmount = this.checkoutDetails.taxAmount;
    }
  }

  setBillingToShippingAddress = () => {
    if (this.billingSameAsShipping) {
      this.selectNewAddress(this.checkoutDetails.shippingAddressId);
      this.toggleAddressForm(false);
    } else if (this.addresses.length) {
      this.selectNewAddress(this.addresses[0].customer_address_id);
    } else {
      this.selectNewAddress(null);
    }
  }

  toggleAddressForm = (bool) => {
    this.showAddressForm = bool;
  }

  toggleTaxExemptForm = (bool) => {
    this.showTaxExemptCertFrom = bool;
  }


  selectNewAddress = (newAddressId) => {
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    checkoutDetailsPayload.billingAddressId = newAddressId;

    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }


  setCheckoutDetailItem = (item) => {
    let checkoutDetails = new CheckoutDetailModel();

    checkoutDetails.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    let checkoutDetailsPayload = Object.assign({}, checkoutDetails, item);
    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  selectNewTaxExemptCert(certId, evt) {
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    // set the certId if checkbox selected, null otherwise - thereby blanking taxId in cart details
    checkoutDetailsPayload.taxExemptCertId = evt ? certId : null;

    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  useCustomerTerms() {
    this.setCheckoutDetailItem({ useCreditTerms: true });
  }

  useCreditCard() {
    this.setCheckoutDetailItem({ useCreditTerms: false });
  }

  savePoNum() {
    this.setCheckoutDetailItem({ poNumber: this.paymentDetailsForm.get('customerPurchOrdNum').value });
  }


  solupayTokenReceived(paymentToken) {
    this.submitSolupayForm = false;
    this.checkoutService.checkoutCardSubject.next({ loading: false, paymentToken, cardDetails: this.cardForm.value });
  }



  computeItemDeliveryTime(item) {
    if (!this.checkoutDetails) return '';
    const deliveryDays = this.checkoutDetails.outboundShippingEstimatedDays;
    return this.dateService.computeItemDeliveryDate(item, this.cart, deliveryDays);
  }

  computeDeliveryTime() {
    if (!this.checkoutDetails) return '';
    const deliveryDays = this.checkoutDetails.outboundShippingEstimatedDays;
    return this.dateService.computeDeliveryDate(this.cart, deliveryDays);
  }

  addNewTaxExemptCert() {
    this.toggleTaxExemptForm(true);
  }

  addNewAddress() {
    this.editingAddress = null;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  assignEditingAddress(address) {
    this.editingAddress = address;
    this.editingAddressSubject.next(this.editingAddress);
    this.toggleAddressForm(true);
  }

  taxExemptCertCreated(result) {
    this.toggleTaxExemptForm(false);
    this.customerService.getTaxExemptCertificates()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((taxExemptCertificates) => {
        this.taxExemptCertificates = taxExemptCertificates;
        this.selectNewTaxExemptCert(result.taxExemptCertificate.insertId, true);
      })
  }

  addressCreated(result) {
    if (result.type === 'update') {
      this.addresses = this.addresses.map(address => {
        if (address.customer_address_id === result.address.customer_address_id) {
          return result.address;
        } else {
          return address;
        }
      })

      this.editingAddress = null;
      this.editingAddressSubject.next(this.editingAddress);
    } else {
      this.addresses.push(result.address);
      this.selectNewAddress(result.address.customer_address_id);
    }
    this.toggleAddressForm(false);
  }

  computeTotal() {
    if (!this.checkoutDetails) return 0;

    return parseFloat(this.cartTotal()) + parseFloat(this.getEstimatedTax()) + parseFloat(this.checkoutDetails.outboundShippingCost);

  }

  getSubtotal() {
    return this.cartService.cartSubject.value.getTotalAmount();
  }

  cartTotal() {
    return this.cartService.cartSubject.value.getTotalAmount().toFixed(2);
  }

  getItemSubtotalAmount() {
    return this.cartService.cartSubject.value.getItemSubtotalAmount().toFixed(2);
  }

  getShipping() {
    return 0;
  }

  getEstimatedTax() {
    return this.taxAmount;
  }

  getCollectMessaging() {
    if (this.shippingAccount) {
      return this.shippingAccount.accountNumber.replace(/.(?=.{2,}$)/g, '*');
    } else {
      return '';
    }
  }

  determineItemCount(arrayLength) {
    return `item${arrayLength === 1 ? '' : 's'}`;
  }


  navigateToShipping() {
    this.router.navigate(['/checkout/shipping-address']);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changePaymentMethod(event) {
    // console.log("SEAN event", event.target.value);

    switch (event.target.value) {
      case 'terms':
        this.setCheckoutDetailItem({ useCreditTerms: true, useNewCard: false, useSavedCard: false, saveCard: false });
        break;
      case 'newCard':
        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: true, useSavedCard: false });
        break;
      case 'savedCard':
        this.setCheckoutDetailItem({ useCreditTerms: false, useNewCard: false, useSavedCard: true });
        break;
    }

  }

  setSaveCard() {
    this.setCheckoutDetailItem({ saveCard: this.cardForm.get('saveCard').value });
  }

  setApplyForCredit() {
    this.setCheckoutDetailItem({ applyForCredit: this.cardForm.get('applyForCredit').value });
  }







  setSolupayPending(e) {
    this.zone.run(() => {
      this.solupayValidationPending = e;
      if (this.btnSpinny)
        this.btnSpinny = e;
      // reset any previously acquired tokens
      // this.checkoutService.checkoutCardSubject.next({ loading: true });
    });
    this.cardForm.get('cardHolderName').markAsTouched();
  }

  solupayValid(valid) {
    this.solupayReady = valid;
    if (!valid) {
      this.btnSpinny = false;
      this.checkoutService.checkoutCardSubject.next({ loading: true });
    }
    this.cardForm.get('cardHolderName').markAsTouched()
    if (valid) {
      this.submitSolupayForm = true
    }
    if (this.reviewOrderPressed && valid) {
      this.btnSpinny = true;
    }
    if (this.reviewOrderPressed && !valid) {
      this.reviewOrderPressed = false;
    }

  }

  async billingComplete() {
    if (this.useNewCard() && !this.checkoutService.checkoutCardSubject.value.paymentToken) {
      this.paymentErrorMessage = '';
      this.genericErrorMessage = '';
      this.reviewOrderPressed = true;
    } else if (!this.useNewCard() || this.checkoutService.checkoutCardSubject.value.paymentToken) {
      // we're using terms, or a saved card, or we have a token because the solupay token returned
      //  before the user hit the 'review' button so let's go directly to the next step
      this.checkoutWizard.goStep(CheckoutStepEnum.REVIEW);
    }
  }

}
