import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../../core/services';

@Component({
  selector: 'app-checkout-user-heading',
  templateUrl: './checkout-user-heading.component.html',
  styleUrls: ['./checkout-user-heading.component.scss']
})
export class CheckoutUserHeadingComponent implements OnInit {

  constructor(
    public customerService: CustomerService
  ) { }

  ngOnInit(): void {

  }

}
