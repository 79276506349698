
<div
  class="alert toast-container fade-in"
  [ngClass]="{
    'show-notification': notification,
    'hide-notification': !notification,
    'toast-top': notification?.options.layout === 'top',
    'toast-bottom': notification?.options.layout === 'bottom',
    'failure-notification': notification?.options.type === 'failure',
    'success-notification': notification?.options.type === 'success',
    'warning-notification': notification?.options.type === 'warning'
  }"
  role="alert"
>
  <div
    class="d-flex"
  >
    <div *ngIf="notification?.message">{{notification?.message}}</div>
    <div *ngIf="notification?.htmlMessage" [innerHtml]="notification?.htmlMessage"></div>
    <div *ngIf="!notification?.options.timeout" class="flex-align-right pointer" (click)="close()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </div>
  </div>
</div>


