import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CheckoutRoutingModule } from './checkout-routing.module';
import { ReviewComponent } from './review/review.component';
import { ShippingMethodComponent } from './shipping-method/shipping-method.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './signin/signin.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedFormsModule } from '../forms/forms.module';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { CartRowItemModule } from '../shared/cart-row-item/cart-row-item.module';
import { SolupayComponent } from './solupay/solupay.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CheckoutUserHeadingComponent } from './checkout-user-heading/checkout-user-heading.component';
import { CheckoutBreadcrumbsComponent } from './checkout-breadcrumbs/checkout-breadcrumbs.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { BillingComponent } from './billing/billing.component';
import { ShippingAddressEditComponent } from './shipping-address-edit/shipping-address-edit.component';
import { CartModule } from '../cart/cart.module';
import { CartSummaryCardModule } from './cart-summary-card/cart-summary-card.module';
import { CartRowRfqItemModule } from '../shared/cart-row-rfq-item/cart-row-rfq-item.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedFormsModule,
    ReactiveFormsModule,
    CheckoutRoutingModule,
    AccordionModule.forRoot(),
    FontAwesomeModule,
    TooltipModule.forRoot(),
    CartRowItemModule,
    CartRowRfqItemModule,
    CreditCardDirectivesModule,
    AlertModule.forRoot(),
    CartSummaryCardModule,
    CartModule
  ],
  declarations: [
    ReviewComponent,
    ShippingMethodComponent,
    SignInComponent,
    SolupayComponent,
    CheckoutUserHeadingComponent,
    CheckoutBreadcrumbsComponent,
    ShippingAddressComponent,
    BillingComponent,
    ShippingAddressEditComponent
  ],
  exports: [
    CheckoutUserHeadingComponent,
    CheckoutBreadcrumbsComponent
  ]
})
export class CheckoutModule { }
