import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { PricingApiService } from './pricing-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RecommendationService {
  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor (
    private apiService: PricingApiService
  ) {}

  getRecommendations(payload) {
    return this.apiService.post(`/recommendations`, payload).pipe(catchError(this.formatErrors));
  }
}
