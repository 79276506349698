<div style="height: 450px" class="card mt-5">
  <div *ngIf="!product.empty" style="display: flex; justify-content: space-between; flex-direction: column; flex: 1; padding: 10px">
    <div>
      <div class="link-style" [routerLink]="['/product', product['_source'].part_number ]" style="font-size: 18px; font-weight: bold;">{{product['_source'].part_number}}</div>
      <div style="color: #6fa453; font-size: 16px; font-weight: bold">
        In Stock
      </div>
      <div class="pointer" [routerLink]="['/product', product['_source'].part_number ]" style="display: flex; justify-content: center; margin-top: 10px; margin-bottom: 10px;">
        <img style="width: 50%;" [src]="product.dynamic_img" />
      </div>
      <div style="height: 80px; overflow: hidden; font-size: 14px">{{product["_source"].title}}</div>
      <div style="height: 120px; overflow: hidden;">
        <div *ngFor="let recommendationType of product.recommendation_type">
          <span *ngIf="recommendationType === 'Higher & Lower Temps'" class="mt-2 flex-1 btn btn-md product-results-button high-low-temperature">{{recommendationType}}</span>
          <span *ngIf="recommendationType === 'Higher Temperature'" class="mt-2 flex-1 btn btn-md product-results-button higher-temperature">{{recommendationType}}</span>
          <span *ngIf="recommendationType === 'Lower Temperature'" class="mt-2 flex-1 btn btn-md product-results-button lower-temperature">{{recommendationType}}</span>
          <span *ngIf="recommendationType === 'Industry Compliances'" class="mt-2 flex-1 btn btn-md product-results-button industry-compliances">{{recommendationType}}</span>
          <span *ngIf="recommendationType === 'Specialty Resistances'" class="mt-2 flex-1 btn btn-md product-results-button specialty-resistances">{{recommendationType}}</span>
        </div>
      </div>
    </div>
    <div>
      <button (click)="logClickAndNavigate()" class="btn btn-primary btn-block btn-sm m-0" data-toast data-toast-type="success">
        View Item
      </button>
    </div>
  </div>
</div>