export class ApiAddressModel {
  customer_address_id?: string;
  customer_id?: string;
  address_type?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  company?: string;
  nickname?: string;
  country?: string;
  isResidential?: number;
  validationOverridden?: boolean;
}

export class FormAddressModel {
  customerAddressId?: string;
  customerId?: string;
  addressType?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  company?: string;
  nickname?: string;
  country?: string;
  isResidential?: number;
  validationOverridden?: boolean;
}
