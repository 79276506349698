import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { PricingApiService } from './pricing-api.service';
import { AuthService } from '../../core/services/auth.service';
import { CartPayloadItemModel, FulfillmentOptionModel, FullRfqModel, CartPayloadModel,
  CartPayloadRfqModel } from '../../shared/cart-payload.model';
import { OrderModel } from '../../shared/order.model';
import { RfqModel } from '../../shared/rfq.model';
import { SalesAttributionModel } from '../../shared/sales-attribution.model';
import { Observable, throwError } from 'rxjs';
import { CustomerService } from '.';

export enum TaxStatusEnum {
  TBD = "TBD",
  EXEMPT = "Exempt",
  CALCULATED = "Calculated"
}

export interface CartTaxResponseModel {
  taxAmount?: number;
}

export interface CartTaxModel extends CartTaxResponseModel {
  taxStatus: TaxStatusEnum
}


@Injectable({ providedIn: 'root' })
export class CartService {
  private cartPayload = new CartPayloadModel();
  public cartSubject: BehaviorSubject<CartPayloadModel | null> = new BehaviorSubject<CartPayloadModel | null>(null);

  public priceChanges = new BehaviorSubject(null);

  private failedRecalculations = [];
  public failedRecalculationsSubject = new BehaviorSubject(this.failedRecalculations);

  public cartMergeSubject = new BehaviorSubject({});

  public recalculatingSubject = new BehaviorSubject(false);

  private expiredItemPriceIds = new Array;

  private lastKnownGuestCartId:any;

  public readonly taxes$: BehaviorSubject<CartTaxModel | null> = new BehaviorSubject<CartTaxModel>({ taxStatus: TaxStatusEnum.TBD });


  private formatErrors(error: any) {
    return  throwError(error);
  }


  constructor (
        private apiService: PricingApiService, 
        private authService: AuthService,
        private customerService: CustomerService
      ) {      
        this.customerService.userSubject.subscribe((cust) => {
          this.getCart();
        });
  }

  public convertGuestCart(){
    if(this.lastKnownGuestCartId){
      const cartConvertSubject = this.convertGuestCartToCustomerCart(this.lastKnownGuestCartId);
      cartConvertSubject.subscribe((res)=>{
        this.lastKnownGuestCartId = null;
        this.getCart().subscribe((res)=>{
          this.cartMergeSubject.next(true);
        })
      });
    } else {
      this.cartMergeSubject.next(false);
    }
    return this.cartMergeSubject;
  }

  public deleteCheckoutDetails() {
    this.cartPayload.checkoutDetailId = null;
    this.taxes$.next({ taxStatus: TaxStatusEnum.TBD });
    this.cartSubject.next(this.cartPayload);
  }

  public addOptionToCart(it: FulfillmentOptionModel) {
    const itemPayload = new CartPayloadItemModel();
      itemPayload.price = it.productSubtotal.toString();
      itemPayload.pricePerUnit = it.pricePerUnit;
      itemPayload.totalPrice = it.totalPrice;
      itemPayload.logisticsFee = it.logisticsFee;
      itemPayload.quantity = it.quantity;
      itemPayload.weight = it.product['_source'].weight_lbs * it.quantity
      itemPayload.productId = it.product['_source'].product_id;
      itemPayload.title = it.product['_source'].part_number;
      itemPayload.itemDetails = [it.itemDetails];
    if (this.cartPayload.cartId) {
      //we already have a cart, lets add the item to the cart
      itemPayload.cartId = this.cartPayload.cartId;
      this.saveItemToCart(itemPayload);
    } else {
      // we dont have a cart, lets create
      this.cartPayload.items.push(itemPayload);
      this.createCart().subscribe((res)=>{
        itemPayload.cartId = res.cartId;
        this.saveItemToCart(itemPayload);
      });
    }
  }


  private saveItemToCart(item:CartPayloadItemModel){
    this.cartPayload = this.cartPayload.updateOrAddCartItems(item, this.cartPayload);
    this.cartSubject.next(this.cartPayload);

    this.apiService.post('/cart/cart_item', item).subscribe((res)=>{
      this.getCart();
      this.authService.extendAttribution(SalesAttributionModel.getAttributionEndAfterItemAdd());
    }, error => {
      console.log("Error adding item to cart", error);
    });
  }

  public removeItemFromCart(itemId:number){
    this.cartPayload = this.cartPayload.removeItemFromCart(itemId, this.cartPayload);
    this.cartSubject.next(this.cartPayload);
    
    this.apiService.delete('/cart/cart_item/'+itemId).subscribe((res)=>{
      this.getCart();
    });
  }

  public removeItemFromFailedRecalculations(itemId:number){
    this.failedRecalculations = this.failedRecalculations.filter(id => id !== itemId);
    this.failedRecalculationsSubject.next(this.failedRecalculations);
  }

  public addRfqToCart(it: FullRfqModel) {
    this.cartPayload = this.cartPayload.updateOrAddRfqItems(it, this.cartPayload);
    this.cartSubject.next(this.cartPayload);
    
    const rfqItemPayload = new CartPayloadRfqModel();
    rfqItemPayload.quantity = it.quantity;
    rfqItemPayload.productId = it.product['_source'].product_id;
    rfqItemPayload.requestedLeadTime = it.requestedLeadTime;
    rfqItemPayload.reason = it.reason;
    rfqItemPayload.comment = it.comment;
    if (this.cartPayload.cartId) {
      //we already have a cart, lets add the item to the cart
      rfqItemPayload.cartId = this.cartPayload.cartId;
      this.saveRfqToCart(rfqItemPayload);
    } else {
      // we dont have a cart, lets create
      this.createCart().subscribe((res)=>{
        rfqItemPayload.cartId = res.cartId;
        this.saveRfqToCart(rfqItemPayload);
      });
    }
  }

  public saveRfqToCart(rfqItem:CartPayloadRfqModel) {
    this.apiService.post('/cart/rfq_item', rfqItem).subscribe((res)=>{
      this.getCart();
      this.authService.extendAttribution(SalesAttributionModel.getAttributionEndAfterItemAdd());
    });
  }

  public updateRfqItemInCart(rfqItem:CartPayloadRfqModel) {
    this.apiService.put('/cart/rfq_item', rfqItem).subscribe((res)=>{
      this.getCart();
    });
  }

  public removeRfqItemFromCart(rfqItemId:number) {
    this.cartPayload = this.cartPayload.removeRfqItemFromCart(rfqItemId, this.cartPayload);
    this.cartSubject.next(this.cartPayload);

    this.apiService.delete('/cart/rfq_item/'+rfqItemId).subscribe((res)=>{
      this.getCart();
    });
  }
  
  public getQuoteGuidByBreakGuid(breakGuid:number) {
    return this.apiService.get(`/quote_item_quantity_break/quote_guid/${breakGuid}`);
  }

  private createCart() {
    const createObserver = this.apiService.post('/cart', this.cartPayload).pipe(shareReplay());
    createObserver.subscribe(res => {
      this.cartPayload = new CartPayloadModel(res);
    })

    return createObserver;
  }

  public checkExpiredCartItems(cart){
    if(cart && cart.items){
      cart.items.forEach((item) => {
        if(item.pricingExpired === 1 && this.expiredItemPriceIds.indexOf(item.itemId) < 0){
          this.expiredItemPriceIds.push(item.itemId);
          this.regenerateItemPrice(item);
        }
      });
    }
  }

  public clearFailedRecalculationsAndExpiredPrices() {
    this.expiredItemPriceIds = new Array();
    this.priceChanges.next(null)
    this.failedRecalculations = new Array();
    this.failedRecalculationsSubject.next(this.failedRecalculations);
  }

  public regenerateItemPrice(cartItem: CartPayloadItemModel) {
    this.recalculatingSubject.next(true);
    const regenerateObserver = this.apiService.post('/cart/regenerate_item_price', cartItem).pipe(shareReplay());

    regenerateObserver.subscribe(
      res => {
        if (res.success === true) {
          if (res.fulfillmentDifferences) this.priceChanges.next(res);
        } else if (res.success === false) {
          this.failedRecalculations.push(cartItem.itemId);
          this.failedRecalculationsSubject.next(this.failedRecalculations);
        }
        this.recalculatingSubject.next(false);
        this.getCart();
      },
      err =>{
        console.log('error updating pricing');
      });
    return this.cartSubject;
  }

  public getCart(): Observable<any> {
    const cartObserver = this.apiService.get('/cart/user').pipe(
      shareReplay(),
      catchError(this.formatErrors)
    );

    cartObserver.subscribe(
      res => {
        if (!res || res === "Cart not found") {
          this.clearCart();
        } else {
          let newCart:CartPayloadModel = new CartPayloadModel(res);

          if(newCart.guestId){
            this.lastKnownGuestCartId = newCart.cartId;
          }

          this.cartPayload = newCart;
          this.cartSubject.next(this.cartPayload);
        }
      },
      err =>{
        this.clearCart();
      });
    return cartObserver;
  }

  public convertToOrder(orderModel: OrderModel, cart) {
    const createObserver = this.apiService.post('/order', orderModel).pipe(shareReplay());

    createObserver.subscribe(order => {
        this.getCart();
    })

    return createObserver;
  }

  public convertGuestCartToCustomerCart(guestCartId) {
    return this.apiService.post('/cart/convert', {cartId:guestCartId});
  }

  public convertToRfq(rfqModel: RfqModel) {
    return this.apiService.post('/rfq', rfqModel);
  }

  public getRfq(rfqId: string) {
    return this.apiService.get<RfqModel>('/rfq/'+rfqId);
  }

  public getOrder(orderId: string):Observable<OrderModel> {
    return this.apiService.get<OrderModel>('/order/'+orderId);
  }

  public calculateCartTaxes():Observable<CartTaxResponseModel> {
    return this.apiService.get<CartTaxResponseModel>('/cart/taxes');
  }



  fetchTaxes() {
    this.calculateCartTaxes().subscribe(taxes => {
      const foo = { taxStatus: TaxStatusEnum.CALCULATED, taxAmount: taxes.taxAmount}
      this.taxes$.next(foo);
    });
  }

  get cartTaxes(): Observable<CartTaxModel> {
    return this.taxes$.asObservable();
  }

  public addBreaksToCart(quoteId, guids) {
    return this.apiService.post('/quote/add_to_cart', { quoteId, qtyBreaks: guids }).pipe(catchError(this.formatErrors));
  }



  public clearCart(){
    this.cartPayload = new CartPayloadModel();
    this.cartSubject.next(this.cartPayload);
  }


}
