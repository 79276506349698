<div class="d-flex flex-column container mt-5" style="overflow-x: auto">
  <h3>{{header}}</h3>
  <div class="d-flex flex-row row">
    <div class="flex-and-center">
      <fa-icon *ngIf="currentPage != 0" class="link-style pointer fa-fw" (click)="previousPage()" [icon]="faChevronLeft" size="lg" [fixedWidth]="true"></fa-icon>
    </div>
    <div *ngFor="let product of currentView; index as idx" class="rec-col col-lg-3 col-md-6 col-sm-12 mb-3">
      <ng-container *ngIf="!product.empty">
        <ng-container *ngIf="product['_source'].product_type === 'Accessories'">
          <app-recommendation-item-accessory [product]="product" [listIdx]="idx">
          </app-recommendation-item-accessory>  
        </ng-container>
        <ng-container *ngIf="product['_source'].product_type === 'Orings'">
          <app-recommendation-item [product]="product" [listIdx]="idx">
          </app-recommendation-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="product.empty">
        <div style="height: 450px" class="mt-4">
        </div>
      </ng-container>
    </div>
    <div class="flex-and-center" style="width: 13px">
      <fa-icon *ngIf="currentPage != totalPages" class="link-style pointer fa-fw" (click)="nextPage()"  [icon]="faChevronRight" [fixedWidth]="true"></fa-icon>
    </div>
  </div>
</div>