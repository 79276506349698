import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SearchService } from '../core/services/search.service';
import { environment } from 'src/environments/environment';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @ViewChild("searchInput", {static: true}) searchInput: ElementRef;

  faMagnifyingGlass = faMagnifyingGlass

  constructor(
    public searchService: SearchService,
  ) { }

  ngOnInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(100)
      )
      .subscribe(event => {
        if (event['target'].value === '') {
          this.searchService.clearSearch();
          return;
        }
        if (event['keyCode'] !== 13) { return; }
        this.search(event);
      });
  }

  focus(event) {
    event.stopPropagation()
  }

  submitSearch = (search) => {
    this.searchService.clearSearch();

    var url = new URL(environment.baseUrl + '/search-results');
    if (search) {
      const params = new URLSearchParams(`search=${search}`); 
      url.search = params.toString();
    }

    window.document.location.href = url.toString()
  }

  navigate = (page, queryParams) => {
    this.searchService.clearSearch();

    var url = new URL(environment.baseUrl + `${page}`);
    if (queryParams) {
      const params = new URLSearchParams(queryParams); 
      url.search = params.toString();
    }

    window.document.location.href = url.toString();
  }

  search(event?) {
    if (!event) {
      if (this.searchInput.nativeElement.value && this.searchInput.nativeElement.value != "") {
        this.submitSearch(this.searchInput.nativeElement.value)
        this.searchInput.nativeElement.value = "";
      }
    } else if(event.keyCode == 13) {
      this.submitSearch(event.target.value);
      event.target.value = '';
    }
  }

}
