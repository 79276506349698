import { Routes, UrlSegment } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MetaGuard } from './shared/meta-guard/meta-guard';

export function comparePages(url) {
  return url.length > 0 && url[0].path.indexOf('-vs-') > -1 ? {consumed: [new UrlSegment(url[0].path, url[0].parameters)]} : null;
}

// Routes for main layout with header and footer.
export const Main_ROUTES: Routes = [
  
  
  {
    path: 'checkout/complete',
    loadChildren: () => import('./checkout/complete/complete.module').then(m => m.CompleteModule),

  },

  
  // Applicaton Routes:
  {
    path: "quotes",
    loadChildren: () => import('./quote/quote.module').then(m => m.QuoteModule),
  },
  {
    path: "order",
    loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'line',
    loadChildren: () => import('./line/line.module').then(m => m.LineModule),
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then(m => m.CartModule),
  },
  {
    path: 'o-ring-store.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./configurator/configurator.module').then(m => m.ConfiguratorModule),
  },
  {
    path: 'search-results',
    loadChildren: () => import('./search-results/search-results.module').then(m => m.SearchResultsModule),
  },
  {
    matcher: comparePages,
    loadChildren: () => import('./compare/compare.module').then(m => m.CompareModule),
  },
  {
    path: 'signin',
    loadChildren: () => import('./user-signin/user-signin.module').then(m => m.UserSigninModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then(m => m.LogoutModule),
  },
  {
    path: 'chemical-compatibility',
    loadChildren: () => import('./chemical-resistance/chemical-resistance.module').then(m => m.ChemicalResistanceModule),
  },
  {
    path: '404',    
    component: PageNotFoundComponent	
  },
  {
    path: 'industrial-seals',
    loadChildren: () => import('./site/industrial-seals/industrial-seals.module').then(m => m.IndustrialSealsModule),
  },
  {
    path: 'high-temperature-seals',
    loadChildren: () => import('./site/high-temperature/high-temperature.module').then(m => m.HighTemperatureModule),
  },
  {
    path: 'low-temperature-seals',
    loadChildren: () => import('./site/low-temperature/low-temperature.module').then(m => m.LowTemperatureModule),
  },
  {
    path: 'custom-molding-process',
    loadChildren: () => import('./site/custom-molding-process/custom-molding-process.module').then(m => m.CustomMoldingProcessModule),
  },

  // site routes
  {
    path: '',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'aflas-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/aflas-o-rings/aflas-o-rings.module').then(m => m.AflasORingsModule),
  },
  {
    path: 'altymid.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/altymid/altymid.module').then(m => m.AltymidModule),
  },
  {
    path: 'bumper-feet.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/bumper-feet/bumper-feet.module').then(m => m.BumperFeetModule),
  },
  {
    path: 'buna-n-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/buna-n-o-rings/buna-n-o-rings.module').then(m => m.BunaNORingsModule),
  },
  {
    path: 'chemraz-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/chemraz-o-rings/chemraz-o-rings.module').then(m => m.ChemrazORingsModule),
  },
  {
    path: 'chemraz-o-rings-semiconductor.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/chemraz-o-rings-semiconductor/chemraz-o-rings-semiconductor.module').then(m => m.ChemrazORingsSemiconductorModule),
  },
  {
    path: 'cpi.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/cpi/cpi.module').then(m => m.CpiModule),
  },
  {
    path: 'custom-molded-rubber-gasket-products.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/custom-molded-rubber-gasket-products/custom-molded-rubber-gasket-products.module').then(m => m.CustomMoldedRubberGasketProductsModule),
  },
  {
    path: 'custom-molded-rubber-products.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/custom-molded-rubber-products/custom-molded-rubber-products.module').then(m => m.CustomMoldedRubberProductsModule),
  },
  {
    path: 'custom-molded-rubber-tolerances.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/custom-molded-rubber-tolerances/custom-molded-rubber-tolerances.module').then(m => m.CustomMoldedRubberTolerancesModule),
  },
  {
    path: 'custom-plastic-parts.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/custom-plastic-parts/custom-plastic-parts.module').then(m => m.CustomPlasticPartsModule),
  },
  {
    path: 'emi-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/emi-o-rings/emi-o-rings.module').then(m => m.EmiORingsModule),
  },
  {
    path: 'epdm-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/epdm-o-rings/epdm-o-rings.module').then(m => m.EpdmORingsModule),
  },
  {
    path: 'hnbr-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/hnbr-o-rings/hnbr-o-rings.module').then(m => m.HnbrORingsModule),
  },
  {
    path: 'neoprene-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/neoprene-o-rings/neoprene-o-rings.module').then(m => m.NeopreneORingsModule),
  },
  {
    path: 'butyl-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/butyl-o-rings/butyl-o-rings.module').then(m => m.ButylORingsModule),
  },
  {
    path: 'sbr-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/sbr-o-rings/sbr-o-rings.module').then(m => m.SbrORingsModule),
  },
  {
    path: 'fabric-over-foam-emi-gasket.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/fabric-over-foam-emi-gasket/fabric-over-foam-emi-gasket.module').then(m => m.FabricOverFoamEmiGasketModule),
  },
  {
    path: 'fda-o-rings-food-grade.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/fda-o-rings-food-grade/fda-o-rings-food-grade.module').then(m => m.FdaORingsFoodGradeModule),
  },
  {
    path: 'fkm-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/fkm-o-rings/fkm-o-rings.module').then(m => m.FkmORingsModule),
  },
  {
    path: 'ffkm-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/ffkm-o-rings/ffkm-o-rings.module').then(m => m.FfkmORingsModule),
  },
  {
    path: 'fluorosilicone-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/fluorosilicone-o-rings/fluorosilicone-o-rings.module').then(m => m.FluorosiliconeORingsModule),
  },
  {
    path: 'shipping-returns.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/shipping/shipping.module').then(m => m.ShippingModule),
  },
  {
    path: 'terms-conditions.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/terms/terms.module').then(m => m.TermsModule),
  },
  {
    path: 'about-us.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/founders-legacy/founders-legacy.module').then(m => m.FoundersLegacyModule),
  },
  {
    path: 'form-confirmation',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/form-confirmation/form-confirmation.module').then(m => m.FormConfirmationModule),
  },
  {
    path: 'form-confirmation-home',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/form-confirmation/form-confirmation.module').then(m => m.FormConfirmationModule),
  },
  {
    path: 'form-confirmation-quote',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/form-confirmation-quote/form-confirmation-quote.module').then(m => m.FormConfirmationQuoteModule),
  },
  {
    path: 'glide-ring-cap-seal.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/glide-ring-cap-seal/glide-ring-cap-seal.module').then(m => m.GlideRingCapSealModule),
  },
  {
    path: 'inquiry-application.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/inquiry-application/inquiry-application.module').then(m => m.InquiryApplicationModule),
  },
  {
    path: 'inquiry-general.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/inquiry-general/inquiry-general.module').then(m => m.InquiryGeneralModule),
  },
  {
    path: 'inquiry-quote.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/inquiry-quote-light/inquiry-quote-light.module').then(m => m.InquiryQuoteLightModule),
  },
  {
    path: 'inquiry-semi.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/inquiry-semi/inquiry-semi.module').then(m => m.InquirySemiModule),
  },
  {
    path: 'lubricant-dow-silicone-gel-based-fda-and-nsf-compliant.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/lubricant-dow-silicone-gel-based-fda-and-nsf-compliant/lubricant-dow-silicone-gel-based-fda-and-nsf-compliant.module').then(m => m.LubricantDowSiliconeGelBasedFdaAndNsfCompliantModule),
  },
  {
    path: 'markez-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/markez-o-rings/markez-o-rings.module').then(m => m.MarkezORingsModule),
  },
  {
    path: 'value-added-services.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/marco-value-add/marco-value-add.module').then(m => m.MarcoValueAddModule),
  },
  {
    path: 'new-silicone-rubber-products.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/silicone-rubber-products/silicone-rubber-products.module').then(m => m.SiliconeRubberProductsModule),
  },
  {
    path: 'diaphragms.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/diaphragms/diaphragms.module').then(m => m.DiaphragmsModule),
  },
  {
    path: 'profiles-cord.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/profiles-cords/profiles-cords.module').then(m => m.ProfilesCordsModule),
  },
  {
    path: 'markez-ffkm.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/markez-resource-center/markez-resource-center.module').then(m => m.MarkezResourceCenterModule),
  },
  {
    path: 'medical-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/medical-o-rings/medical-o-rings.module').then(m => m.MedicalORingsModule),
  },
  {
    path: 'metal-detectable-o-rings-sanitary-gaskets.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/metal-detectable-o-rings-sanitary-gaskets/metal-detectable-o-rings-sanitary-gaskets.module').then(m => m.MetalDetectableORingsSanitaryGasketsModule),
  },
  {
    path: 'nsf-o-rings-drinking-water.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/nsf-o-rings-drinking-water/nsf-o-rings-drinking-water.module').then(m => m.NsfORingsDrinkingWaterModule),
  },
  {
    path: 'o-lube-884-2.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-lube/o-lube.module').then(m => m.OLubeModule),
  },
  {
    path: 'o-ring-backup-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-backup-rings/o-ring-backup-rings.module').then(m => m.ORingBackupRingsModule),
  },
  {
    path: 'o-ring-chemical-compatibility-chart.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-chemical-compatibility-chart/o-ring-chemical-compatibility-chart.module').then(m => m.ORingChemicalCompatibilityChartModule),
  },
  {
    path: 'o-ring-coatings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-coatings/o-ring-coatings.module').then(m => m.ORingCoatingsModule),
  },
  {
    path: 'o-ring-cord.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-cord/o-ring-cord.module').then(m => m.ORingCordModule),
  },
  {
    path: 'o-ring-design-service.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-design-service/o-ring-design-service.module').then(m => m.ORingDesignServiceModule),
  },
  {
    path: 'o-ring-design-technical-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-design-technical-index/o-ring-design-technical-index.module').then(m => m.ORingDesignTechnicalIndexModule),
  },
  {
    path: 'o-ring-extraction-install-tools.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-extraction-install-tools/o-ring-extraction-install-tools.module').then(m => m.ORingExtractionInstallToolsModule),
  },
  {
    path: 'o-ring-failure.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-failure/o-ring-failure.module').then(m => m.ORingFailureModule),
  },
  {
    path: 'o-ring-groove-design-clearance-gap.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-clearance-gap/o-ring-groove-design-clearance-gap.module').then(m => m.ORingGrooveDesignClearanceGapModule),
  },
  {
    path: 'o-ring-groove-design-considerations.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-considerations/o-ring-groove-design-considerations.module').then(m => m.ORingGrooveDesignConsiderationsModule),
  },
  {
    path: 'o-ring-groove-design-directory.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-directory/o-ring-groove-design-directory.module').then(m => m.ORingGrooveDesignDirectoryModule),
  },
  {
    path: 'o-ring-groove-design-dovetail.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-dovetail/o-ring-groove-design-dovetail.module').then(m => m.ORingGrooveDesignDovetailModule),
  },
  {
    path: 'o-ring-groove-design-dynamic-piston.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-dynamic-piston/o-ring-groove-design-dynamic-piston.module').then(m => m.ORingGrooveDesignDynamicPistonModule),
  },
  {
    path: 'o-ring-groove-design-face-seal.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-face-seal/o-ring-groove-design-face-seal.module').then(m => m.ORingGrooveDesignFaceSealModule),
  },
  {
    path: 'o-ring-groove-design-static-piston.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-groove-design-static-piston/o-ring-groove-design-static-piston.module').then(m => m.ORingGrooveDesignStaticPistonModule),
  },
  {
    path: 'o-ring-inspection-service.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-inspection-service/o-ring-inspection-service.module').then(m => m.ORingInspectionServiceModule),
  },
  {
    path: 'o-ring-inventory-service.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-inventory-service/o-ring-inventory-service.module').then(m => m.ORingInventoryServiceModule),
  },
  {
    path: 'o-ring-kf-centering-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-kf-centering-rings/o-ring-kf-centering-rings.module').then(m => m.ORingKfCenteringRingsModule),
  },
  {
    path: 'o-ring-kits.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-kits/o-ring-kits.module').then(m => m.ORingKitsModule),
  },
  {
    path: 'o-ring-kits-accessories.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-kits-accessories/o-ring-kits-accessories.module').then(m => m.ORingKitsAccessoriesModule),
  },
  {
    path: 'o-ring-lubrication.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-lubrication/o-ring-lubrication.module').then(m => m.ORingLubricationModule),
  },
  {
    path: 'o-ring-material-list.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-material-list/o-ring-material-list.module').then(m => m.ORingMaterialListModule),
  },
  {
    path: 'o-ring-material-quick-reference.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-material-quick-reference/o-ring-material-quick-reference.module').then(m => m.ORingMaterialQuickReferenceModule),
  },
  {
    path: 'o-ring-material-selection-comparison.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-material-selection-comparison/o-ring-material-selection-comparison.module').then(m => m.ORingMaterialSelectionComparisonModule),
  },
  {
    path: 'o-ring-mil-specs-chart.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-mil-specs-chart/o-ring-mil-specs-chart.module').then(m => m.ORingMilSpecsChartModule),
  },
  {
    path: 'o-ring-packaging-service.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-packaging-service/o-ring-packaging-service.module').then(m => m.ORingPackagingServiceModule),
  },
  {
    path: 'o-ring-permeation.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-permeation/o-ring-permeation.module').then(m => m.ORingPermeationModule),
  },
  {
    path: 'o-ring-seal-product-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-seal-product-index/o-ring-seal-product-index.module').then(m => m.ORingSealProductIndexModule),
  },
  {
    path: 'o-ring-services-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-services-index/o-ring-services-index.module').then(m => m.ORingServicesIndexModule),
  },
  {
    path: 'o-ring-shelf-life.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-shelf-life/o-ring-shelf-life.module').then(m => m.ORingShelfLifeModule),
  },
  {
    path: 'o-ring-size-chart-as568.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-size-chart-as568/o-ring-size-chart-as568.module').then(m => m.ORingSizeChartAs568Module),
  },
  {
    path: 'universal-o-ring-size-chart.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/oring-standard-size-chart/oring-standard-size-chart.module').then(m => m.OringStandardSizeChartModule),
  },
  {
    path: 'o-ring-size-chart-british.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-size-chart-british/o-ring-size-chart-british.module').then(m => m.ORingSizeChartBritishModule),
  },
  {
    path: 'o-ring-size-chart-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-size-chart-index/o-ring-size-chart-index.module').then(m => m.ORingSizeChartIndexModule),
  },
  {
    path: 'o-ring-size-chart-japanese.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-size-chart-japanese/o-ring-size-chart-japanese.module').then(m => m.ORingSizeChartJapaneseModule),
  },
  {
    path: 'o-ring-size-chart-metric.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-size-chart-metric/o-ring-size-chart-metric.module').then(m => m.ORingSizeChartMetricModule),
  },
  {
    path: 'o-ring-splicing-kit.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-splicing-kit/o-ring-splicing-kit.module').then(m => m.ORingSplicingKitModule),
  },
  {
    path: 'o-ring-tolerance-as568.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-tolerance-as568/o-ring-tolerance-as568.module').then(m => m.ORingToleranceAs568Module),
  },
  {
    path: 'o-ring-tolerance-general-purpose.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-tolerance-general-purpose/o-ring-tolerance-general-purpose.module').then(m => m.ORingToleranceGeneralPurposeModule),
  },
  {
    path: 'o-ring-tolerance-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-ring-tolerance-index/o-ring-tolerance-index.module').then(m => m.ORingToleranceIndexModule),
  },
  {
    path: 'o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-rings/o-rings.module').then(m => m.ORingsModule),
  },
  {
    path: 'o-rings-basic-seals-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/o-rings-basic-seals-index/o-rings-basic-seals-index.module').then(m => m.ORingsBasicSealsIndexModule),
  },
  {
    path: 'high-pressure-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/high-pressure-o-rings/high-pressure-o-rings.module').then(m => m.HighPressureORingsModule),
  },
  {
    path: 'oil-seal-radial-shaft-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/oil-seal-radial-shaft-seals/oil-seal-radial-shaft-seals.module').then(m => m.OilSealRadialShaftSealsModule),
  },
  {
    path: 'oil-gas-o-ring-seal-industry.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/oil-gas-o-ring-seal-industry/oil-gas-o-ring-seal-industry.module').then(m => m.OilGasORingSealIndustryModule),
  },
  {
    path: 'oring-material-test-data-explanation.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/oring-material-test-data-explanation/oring-material-test-data-explanation.module').then(m => m.OringMaterialTestDataExplanationModule),
  },
  {
    path: 'performance-seal-index.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/performance-seal-index/performance-seal-index.module').then(m => m.PerformanceSealIndexModule),
  },
  {
    path: 'parker-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/parker-o-rings/parker-o-rings.module').then(m => m.ParkerORingsModule),
  },
  {
    path: 'polyurethane-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/polyurethane-o-rings/polyurethane-o-rings.module').then(m => m.PolyurethaneORingsModule),
  },
  {
    path: 'precision-metal-components.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/precision-metal-components/precision-metal-components.module').then(m => m.PrecisionMetalComponentsModule),
  },
  {
    path: 'quad-ring-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/quad-ring-seals/quad-ring-seals.module').then(m => m.QuadRingSealsModule),
  },
  {
    path: 'quality-systems.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/quality-systems/quality-systems.module').then(m => m.QualitySystemsModule),
  },
  {
    path: 'quality-systems-thanks.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/file-download-thanks/file-download-thanks.module').then(m => m.FileDownloadThanksModule),
  },
  {
    path: 'size-chart-download-thanks.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/file-download-thanks/file-download-thanks.module').then(m => m.FileDownloadThanksModule),
  },
  {
    path: 'rod-wipers-seal.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rod-wipers-seal/rod-wipers-seal.module').then(m => m.RodWipersSealModule),
  },
  {
    path: 'rubber-extrusion-tolerances.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-extrusion-tolerances/rubber-extrusion-tolerances.module').then(m => m.RubberExtrusionTolerancesModule),
  },
  {
    path: 'rubber-extrusions.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-extrusions/rubber-extrusions.module').then(m => m.RubberExtrusionsModule),
  },
  {
    path: 'rubber-fastener-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-fastener-seals/rubber-fastener-seals.module').then(m => m.RubberFastenerSealsModule),
  },
  {
    path: 'rubber-gaskets.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-gaskets/rubber-gaskets.module').then(m => m.RubberGasketsModule),
  },
  {
    path: 'rubber-grommets.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-grommets/rubber-grommets.module').then(m => m.RubberGrommetsModule),
  },
  {
    path: 'rubber-sheeting.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-sheet/rubber-sheet.module').then(m => m.RubberSheetModule),
  },
  {
    path: 'rubber-tubing.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-tubing/rubber-tubing.module').then(m => m.RubberTubingModule),
  },

  {
    path: 'rubber-roll-tolerances.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/rubber-roll-tolerances/rubber-roll-tolerances.module').then(m => m.RubberRollTolerancesModule),
  },
  {
    path: 'semiconductor-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/semiconductor-o-rings/semiconductor-o-rings.module').then(m => m.SemiconductorORingsModule),
  },
  {
    path: 'silicone-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/silicone-o-rings/silicone-o-rings.module').then(m => m.SiliconeORingsModule),
  },
  {
    path: 'sitemap.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/sitemap/sitemap.module').then(m => m.SitemapModule),
  },
  {
    path: 'slit-valve-seal.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/slit-valve-seal/slit-valve-seal.module').then(m => m.SlitValveSealModule),
  },
  {
    path: 'spliced.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/spliced/spliced.module').then(m => m.SplicedModule),
  },
  {
    path: 'spring-energized-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/spring-energized-seals/spring-energized-seals.module').then(m => m.SpringEnergizedSealsModule),
  },
  {
    path: 'spring-energized-seal-groove-design.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/spring-energized-seal-groove-design/spring-energized-seal-groove-design.module').then(m => m.SpringEnergizedSealGrooveDesignModule),
  },
  {
    path: 'square-ring-seal-size-chart.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/square-ring-seal-size-chart/square-ring-seal-size-chart.module').then(m => m.SquareRingSealSizeChartModule),
  },
  {
    path: 'square-ring-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/square-ring-seals/square-ring-seals.module').then(m => m.SquareRingSealsModule),
  },
  {
    path: 'teflon-encapsulated-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/teflon-encapsulated-o-rings/teflon-encapsulated-o-rings.module').then(m => m.TeflonEncapsulatedORingsModule),
  },
  {
    path: 'teflon-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/teflon-o-rings/teflon-o-rings.module').then(m => m.TeflonORingsModule),
  },
  {
    path: '3a-o-rings-dairy.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/three-a-o-rings-dairy/three-a-o-rings-dairy.module').then(m => m.ThreeAORingsDairyModule),
  },
  {
    path: 'trademarks.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/trademarks/trademarks.module').then(m => m.TrademarksModule),
  },
  {
    path: 'tri-clamp-sanitary-gasket.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/tri-clamp-sanitary-gasket/tri-clamp-sanitary-gasket.module').then(m => m.TriClampSanitaryGasketModule),
  },
  {
    path: 'u-cup-seals.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/u-cup-seals/u-cup-seals.module').then(m => m.UCupSealsModule),
  },
  {
    path: 'viton-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/viton-o-rings/viton-o-rings.module').then(m => m.VitonORingsModule),
  },
  {
    path: 'viton-etp-o-rings.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/viton-etp-o-rings/viton-etp-o-rings.module').then(m => m.VitonEtpORingsModule),
  },
  {
    path: 'x-ring-size-chart.htm',
    canActivateChild: [MetaGuard],
    loadChildren: () => import('./site/x-ring-size-chart/x-ring-size-chart.module').then(m => m.XRingSizeChartModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];
