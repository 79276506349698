<div class="py-5 footer-container container-fluid font-16">
  <div class="container">
    <div class="flex-1">
      <img src="/assets/img/logo/marco_logo_white.svg" width="230" height="45" alt="">
    </div>
    <!-- <div class="add-flex flex-row justify-content-between mt-20"> -->
    <div class="footer-links-container">
      <div class="mt-20">
        Marco Rubber & Plastics is an industry leader in
        custom sealing solutions that go beyond the limits
        of catalog commodities. Since 1980, our solutions
        have delivered reliable, long-lasting success for
        customers worldwide. We offer the largest inventory
        of specialty and standard sealing solutions backed by
        best-in-class technology, service and support.
      </div>
      <div class="footer-links mt-20">
        <div class="mr-20">
          <a [routerLink]="['/quality-systems.htm']">
            <img width="100" height="150" src="https://www.marcorubber.com/assets/img/NQA_ISO9001_CMYK.jpg">
          </a>
        </div>
        <div class="add-flex flex-column">
          <a class="footer-link" [routerLink]="['/about-us.htm']">About Us</a>
          <a class="footer-link" [routerLink]="['/o-ring-seal-product-index.htm']">Products</a>
          <a class="footer-link" [routerLink]="['/o-ring-services-index.htm']">Services</a>
          <a class="footer-link" [routerLink]="['/o-ring-store.htm']">Shop Online</a>
          <a class="footer-link" [routerLink]="['/inquiry-general.htm']">Contact Us</a>
        </div>
      </div>
      <div class="mt-20">
        <div>
          <a class="btn btn-primary add-flex flex-1 justify-content-center text-white" [routerLink]="['/inquiry-quote.htm']">Request A Quote</a>
        </div>
        <div class="mt-10">
          <a href="tel:6034683600" class="text-bold">Give us a call: (603) 468-3600</a>
        </div>
      </div>
    </div>
    <div class="mt-20 mb-10" style="border-top: 1px solid white"></div>
    <div class="sub-footer-links">
      <div class="add-flex flex-row">
        <a class="mr-10" href="https://www.linkedin.com/company/marco-rubber-&-plastics" target="_blank">
          <fa-icon [icon]="faLinkedin"></fa-icon>
        </a>
        <a class="mr-10" [routerLink]="['/terms-conditions.htm']">Terms & Conditions</a>
        <a class="mr-10" [routerLink]="['/shipping-returns.htm']">Shipping & Returns</a>
        <a [routerLink]="['/sitemap.htm']">Sitemap</a>
      </div>
      <div>
        Copyright {{ today.getFullYear() }} Marco Rubber and Plastics. All Rights Reserved.
      </div>
    </div>
  </div>