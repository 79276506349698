import dayjs from 'dayjs';

export class SalesAttributionModel {
    attribution: string;
    url: string;
    attributionEnd: dayjs.Dayjs;
    guestId?: string;
    customerId?: number;

    constructor(url?:string, attribution?:string, attributionEnd?:dayjs.Dayjs) {
        this.url = url;
        this.attribution = attribution;
        this.attributionEnd = attributionEnd;
    }

    // by default, an attribution lasts 24 hours
    static getDefaultAttributionEnd():dayjs.Dayjs {
        return dayjs(24, 'hours');
    }

    // if an item is added to a cart; an attribution is extended 30 days
    static getAttributionEndAfterItemAdd():dayjs.Dayjs {
        return dayjs(30, 'days');
    }

}