export const environment = {
  production: true,
  enableSentry: false,
  enableAdminTool: true,
  enableServiceWorker: true,
  capchaSiteKey: '6Let2scZAAAAAKdBC9oeRvsChzzmr6lyaJLWq_O2',
  capchav2SiteKey: '6LcMthYUAAAAADi-mVvawXjecfy0lY7AmbiX-C56',	
  baseUrl: 'https://development.marcorubber.com',
  API_URL: 'https://development.marcorubber.com/api/pricing',
  CUSTOMERS_API_URL: 'https://development.marcorubber.com/api/customer',
  INTEGRATION_API_URL: 'https://development.marcorubber.com/api/integration',
  SOLUPAY_TOKENIZATION_KEY: '28w2vu-SqpDUr-97r886-YzAUE6',
  VERSION: '1.0.12',
  environment: 'development',
  enableDrift: true
};