import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

import { PricingApiService } from './pricing-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompareService {
  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor (
    private apiService: PricingApiService
  ) {}

  getEntities(postData) {
    return this.apiService.post(`/query/es`, postData).pipe(catchError(this.formatErrors));
  }

  getAssociatedData(postData) {
    return this.apiService.post(`/material_type/extra`, postData).pipe(catchError(this.formatErrors));
  }

  getMaterialTypeDropdownOptions() {
    return this.apiService.post(`/material_type/query`, {
      filters: [],
      pageNum: 0,
      numberOfResults: 30
    }).pipe(catchError(this.formatErrors));
  }

}
