import { Component, OnInit } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CheckoutStepEnum, CheckoutWizardService } from '../checkout-wizard.service';

@Component({
  selector: 'app-checkout-breadcrumbs',
  templateUrl: './checkout-breadcrumbs.component.html',
  styleUrls: ['./checkout-breadcrumbs.component.scss']
})
export class CheckoutBreadcrumbsComponent implements OnInit {

  faChevronRight = faChevronRight;

  constructor(
    public wizard: CheckoutWizardService
  ) { }

  CheckoutStepEnum = CheckoutStepEnum;

  ngOnInit(): void {
  }

  navigateCheckoutStep(stepENUM){
    if(stepENUM !== this.wizard.currentStep.step){
      this.wizard.goStep(stepENUM);
    }
  }
}
