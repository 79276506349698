import { Injectable } from '@angular/core';
import { PricingApiService } from './pricing-api.service';
import { SalesAttributionModel } from '../../shared/sales-attribution.model';
import { UserAttributionModel } from 'src/app/shared/user-attribution-model';

@Injectable({ providedIn: 'root' })
export class AttributionService {

  constructor(    private apiService: PricingApiService, 
    ) { }

  saveAttribution(attribution:SalesAttributionModel) {
    return this.apiService.post('/attribution', attribution);
  }

  saveUserAttribution(attribution:UserAttributionModel) {
    return this.apiService.post('/user_attribution', attribution);
  }

  guestConvert(customerId, guestId) {
    return this.apiService.post('/guest_convert', {customer_id: customerId, guest_id:guestId});
  }
}
