import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService, CartService, CustomerService } from '../../core/services';
import { environment } from '../../../environments/environment';
import { combineLatest } from 'rxjs';
import { faShoppingCart, faCircleUser, faEnvelope, faAngleRight, faMagnifyingGlass, faAngleDown, faBars, faCircleNotch, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss'
  ]
})
export class HeaderComponent {

  menuMap = {
    'Static Seals': [
      { name: 'O-Rings', url: ['/o-rings.htm'] },
      { name: 'Markez® FFKM O-Rings', url: ['/markez-o-rings.htm'] },
      { name: 'Square Rings', url: ['/square-ring-seals.htm'] },
      { name: 'Back-up Rings', url: ['/o-ring-backup-rings.htm'] },
      { name: 'Encapsulated', url: ['/teflon-encapsulated-o-rings.htm'] },
      { name: 'Large and Giant', url: ['/spliced.htm'] },
      { name: 'Conductive and Shielding', url: ['/emi-o-rings.htm'] },
      { name: 'Metal Detectable', url: ['/metal-detectable-o-rings-sanitary-gaskets.htm'] },
      { name: 'Kits and Accessories', url: ['/o-ring-kits-accessories.htm'] },
    ],
    'Dynamic Seals': [
      { url: ['/quad-ring-seals.htm'], name: 'X-Rings' },
      { url: ['/spring-energized-seals.htm'], name: 'Spring Energized' },
      { url: ['/u-cup-seals.htm'], name: 'U-Cup and Lip Seals' },
      { url: ['/oil-seal-radial-shaft-seals.htm'], name: 'Oil and Shaft Seals' },
      { url: ['/glide-ring-cap-seal.htm'], name: 'Cap Seals' },
      { url: ['/o-ring-kf-centering-rings.htm'], name: 'Centering Rings' },
    ],
    'Sheets and Extrusions': [
      { url: ['/rubber-sheeting.htm'], name: 'Sheets' },
      { url: ['/o-ring-cord.htm'], name: 'O-ring Cord' },
      { url: ['/profiles-cord.htm'], name: 'Profiled Cord' },
      { url: ['/rubber-tubing.htm'], name: 'Tubing' },
    ],
    'Gaskets': [
      { url: ['/rubber-gaskets.htm'], name: 'Gaskets' },
      { url: ['/tri-clamp-sanitary-gasket.htm'], name: 'Sanitary Gaskets' },
      { url: ['/fabric-over-foam-emi-gasket.htm'], name: 'Conductive and Shielding' },
      { url: ['/rubber-fastener-seals.htm'], name: 'Fastener' },
    ],
    'Hardware': [
      { url: ['/rubber-grommets.htm'], name: 'Grommets' },
      { url: ['/bumper-feet.htm'], name: 'Bumpers' },
      { url: ['/square-ring-seals.htm'], name: 'Washers' },
      { url: ['/diaphragms.htm'], name: 'Diaphragms' },
    ],
    'Custom': [
      { url: ['/custom-molded-rubber-products.htm'], name: 'Rubber' },
      { url: ['/custom-plastic-parts.htm'], name: 'Plastic' },
      { url: ['/precision-metal-components.htm'], name: 'Metal' },
    ],
    'Services': [
      { url: ['/o-ring-packaging-service.htm'], name: 'Packaging' },
      { url: ['/o-ring-coatings.htm'], name: 'Coating' },
      { url: ['/o-ring-inspection-service.htm'], name: 'Inspection' },
      { url: ['/o-ring-inventory-service.htm'], name: 'Stocking Agreements' },
      { url: ['/o-ring-design-service.htm'], name: 'Design Engineering' },
      { url: ['/inquiry-quote.htm'], name: 'Technical Assistance' },
    ],
    'Applications': [
      { url: ['/high-temperature-seals'], name: 'High Temperature' },
      { url: ['/low-temperature-seals'], name: 'Low Temperature' },
      { url: ['/markez-o-rings.htm'], name: 'Caustic & Corrosive' },
      { url: ['/high-pressure-o-rings.htm'], name: 'High Pressure' },
    ],
    'Industries': [
      { url: ['/industrial-seals'], name: 'Industrial' },
      { url: ['/cpi.htm'], name: 'Chemical Processing' },
      { url: ['/oil-gas-o-ring-seal-industry.htm'], name: 'Oil & Gas' },
      { url: ['/semiconductor-o-rings.htm'], name: 'Semicon & Solar' },
      { url: ['/medical-o-rings.htm'], name: 'Medical & Pharma' },
      { url: ['/fda-o-rings-food-grade.htm'], name: 'Food & Beverage' },
      { url: ['/o-ring-mil-specs-chart.htm'], name: 'Aerospace & Automotive' },
      { url: ['/o-ring-mil-specs-chart.htm'], name: 'Military' },
    ],
    'Guides': [
      { url: ['/o-ring-size-chart-index.htm'], name: 'O-Ring Sizes' },
      { url: ['/o-ring-tolerance-index.htm'], name: 'Tolerances' },
      { url: ['/o-ring-groove-design-directory.htm'], name: 'Groove Design' },
      { url: ['/o-ring-chemical-compatibility-chart.htm'], name: 'Chemical Resistance' },
      { url: ['/o-ring-shelf-life.htm'], name: 'Shelf Life' },
      { url: ['/o-ring-failure.htm'], name: 'Failure Analysis' },
      { url: ['/o-ring-mil-specs-chart.htm'], name: 'Mil-Specs' },
    ],
    'Materials': [
      { url: ['/o-ring-material-selection-comparison.htm'], name: 'Material Comparison' },
      { url: ['/viton-o-rings.htm'], name: 'Viton® FKM' },
      { url: ['/buna-n-o-rings.htm'], name: 'Buna-N NBR' },
      { url: ['/silicone-o-rings.htm'], name: 'Silicone VMQ' },
      { url: ['/fluorosilicone-o-rings.htm'], name: 'Fluorosilicone FVMQ' },
      { url: ['/epdm-o-rings.htm'], name: 'EPDM' },
      { url: ['/aflas-o-rings.htm'], name: 'Aflas® FEPM TFE/P' },
      { url: ['/teflon-o-rings.htm'], name: 'TeflonN® PTFE' },
      { url: ['/polyurethane-o-rings.htm'], name: 'Polyurethane' },
      { url: ['/parker-o-rings.htm'], name: 'Parker Materials' },
      { url: ['/hnbr-o-rings.htm'], name: 'HNBR' },
      { url: ['/butyl-o-rings.htm'], name: 'Butyl' },
      { url: ['/neoprene-o-rings.htm'], name: 'Neoprene' },
      { url: ['/sbr-o-rings.htm'], name: 'SBR' },
      { url: ['/emi-o-rings.htm'], name: 'Conductive Rubber' },
    ],
    'Perfluoroelastomer FFKM': [
      { url: ['/markez-ffkm.htm'], name: 'Markez® FFKM' },
      { url: ['/markez-o-rings.htm'], name: 'Markez® Comparison' },
      { url: ['/chemraz-o-rings.htm'], name: 'Chemraz® FFKM' },
    ],
    'Compliances & Certifications': [
      { url: ['/fda-o-rings-food-grade.htm'], name: 'FDA (Food & Beverage)' },
      { url: ['/nsf-o-rings-drinking-water.htm'], name: 'NSF (Drinking Water)' },
      { url: ['/3a-o-rings-dairy.htm'], name: 'USDA 3A (Dairy)' },
      { url: ['/o-ring-mil-specs-chart.htm'], name: 'Mil-Specs' },
      { url: ['/quality-systems.htm'], name: 'REACH & ROHS' },
      { url: ['/quality-systems.htm'], name: 'ISO 9001' },
    ],
    'About Us': [
      { url: ['/about-us.htm'], name: 'About Us' },
      { url: ['/terms-conditions.htm'], name: 'Terms & Conditions' },
      { url: ['/shipping-returns.htm'], name: 'Shipping & Returns' },
      { url: ['/quality-systems.htm'], name: 'Quality Systems' },
    ]
  }

  environment = environment;

  // new: porting these in 
  adminUrl = `/admin/index?nocache=${(new Date()).getTime()}`;
  faShoppingCart = faShoppingCart;
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;
  faCircleUser = faCircleUser;
  faMagnifyingGlass = faMagnifyingGlass;
  faBarsStaggered = faBarsStaggered;
  faEnvelope = faEnvelope;
  faCircleNotch = faCircleNotch;
  faBars = faBars;
  isCollapsed = true;
  mobileIsCollapsed = true;


  mobileMenuId = null;
  mobileSubMenuId = null;
  // end new variables

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private customerService: CustomerService,
    private cartService: CartService
  ) {


  }

  sharedData: any = {};
  updated: Date;

  ngOnInit() {
    // set up subscriptions; we listen to these app-wide listeners for 
    //  changes to this data which in turn drive navbar state from the 
    //  'outside' of the component.  This is so the component doesn't
    //  have to fetch its own data - at least when consumed by the angular
    //  project.  We forkjoin to wait until all have emitted at least one
    //  object before sending a full data package to header's input 

    const sub$ = [
      this.authService.loggedInStatusSubject,
      this.customerService.userSubject,
      this.authService.entitlementSubject,
      this.cartService.cartSubject
    ];

    combineLatest(sub$).subscribe(d => {
      this.sharedData.isLoggedIn = d[0];
      this.sharedData.customer = d[1];
      this.sharedData.isAdmin = d[2];
      this.sharedData.cart = d[3];

      // this.header.setSharedData(this.sharedData);
      // console.log(this.sharedData)
    });
  }

  private siteToBlogHostnameMapper = {
    "www.": "www.",
    "development.": "development.",
    "stage.": "development.",
  }

  close() {
    this.isCollapsed = true;
  }

  setMenuCollapseState(event, id, sub) {
    event.stopPropagation()
    if (sub) this.mobileSubMenuId = id === this.mobileSubMenuId ? null : id;
    else {
      this.mobileSubMenuId = null;
      this.mobileMenuId = id === this.mobileMenuId ? null : id;
    }
  }

  navigateToBlog() {
    window.location.href = this.getBlogUrl();
  }

  getBlogUrl() {
    return "https://" + window.location.hostname.replace(/www\.|stage\.|development\./, (match) => {
      return this.siteToBlogHostnameMapper[match];
    }) + "/blog/";
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId)
  }

}
