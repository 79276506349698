// core libs
import { BrowserModule, BrowserTransferStateModule, Meta, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { RouterModule } from '@angular/router';
import { SentryErrorHandler } from './core/injectables/sentry';

// Global app / root components

import { JwtModule } from '@auth0/angular-jwt';

import { environment } from '../environments/environment.development';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent } from './layout/header/header.component';
import { NotificationComponent } from './notification/notification.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { StoreLayoutComponent } from './layout/store-layout/store-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalContentTypeInterceptorModule } from './shared/global-content-type-interceptor/global-content-type-interceptor.module';
import { CheckoutLayoutComponent } from './layout/checkout-layout/checkout-layout.component';
import { CheckoutModule } from './checkout/checkout.module';
import { CartSummaryCardModule } from './checkout/cart-summary-card/cart-summary-card.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SearchModule } from './search/search.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const whitelistedDomains = [new RegExp('[\s\S]*')] as RegExp[];

export function jwtTokenGetter() {
  if (window.localStorage) { // might not have it; SSR & secure browsers
    const tkn = window.localStorage.getItem('id_token');
    if (tkn) {
      return tkn;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HeaderComponent,
    NotificationComponent,
    FooterComponent,
    MainLayoutComponent,
    StoreLayoutComponent,
    CheckoutLayoutComponent
  ],
  imports: [
    AppRoutingModule,
    SearchModule,
    BsDropdownModule,
    CollapseModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule, // confirm this is OK before pushing
    BrowserTransferStateModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        authScheme: "", // normally jwt schemes use a 'Bearer ' prefix; we dont use that here
        allowedDomains: ['localhost:4200', new RegExp('^[a-z]+\.marcorubber\.com$'), 'localhost:3101', 'localhost:3100']
      }
    }),
    GlobalContentTypeInterceptorModule,
    RouterModule,
    CheckoutModule,
    CartSummaryCardModule,
    BrowserModule.withServerTransition({ appId: 'exp-web-ui' }),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.enableServiceWorker, registrationStrategy: 'registerWhenStable' })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    Meta,
    Title
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

