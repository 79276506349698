import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../../core/services/customer.service';
import { ShippingAccountModel } from '../../shared/shipping-account.model';
import { NotificationService } from '../../core/services/notification.service';

@Component({
  selector: 'app-shipping-account-form',
  templateUrl: './shipping-account-form.component.html',
  styleUrls: ['./shipping-account-form.component.scss']
})
export class ShippingAccountFormComponent implements OnInit {
  // @Input() addressType: string;
  @Input() shippingAccount;
  @Output() shippingAccountCreated = new EventEmitter();
  @Output() formVisibility = new EventEmitter();

  shippingCarriers = ['UPS', 'FedEx'];

  title: string = "Enter a New Shipping Account";
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private notificationService: NotificationService,
  ) {
    
  }

  ngOnInit() {
    this.form = this.formBuilder.group({ 
      customerShippingAccountId: [null],
      accountNumber: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+$')]],
      type: [null, [Validators.required]],
      customerId: [null],
    });

    this.shippingAccount.subscribe(shippingAccount => {
      this.initializeForm(shippingAccount);
    });
  }

  initializeForm(shippingAccountToInitialize) {
    if (!shippingAccountToInitialize) {
      this.form.controls['type'].enable();
      const shippingAccount = new ShippingAccountModel();
      
      shippingAccount.customerShippingAccountId = null;
      shippingAccount.accountNumber = null;
      shippingAccount.customerId = null;
      shippingAccount.type = null;

      this.form.patchValue(shippingAccount);
    } else {
      this.form.controls['type'].disable();
      const shippingAccount = new ShippingAccountModel();

      shippingAccount.customerShippingAccountId = shippingAccountToInitialize.customerShippingAccountId || "";
      shippingAccount.accountNumber = shippingAccountToInitialize.accountNumber || "";
      shippingAccount.customerId = shippingAccountToInitialize.customerId || "";
      shippingAccount.type = shippingAccountToInitialize.type || null;

      this.form.patchValue(shippingAccount);
      this.title = "Edit Shipping Account";
    }
  }

  close() {
    this.formVisibility.emit(false);
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  save() {
    this.validateAllFormFields(this.form);
    if (this.form.invalid) {
      console.log("invalid form", this.form);
      return;
    }

    if (this.form.value.customerShippingAccountId) {
      this.customerService.updateShippingAccount(this.form.value).subscribe(result => {
        if (result) {
          this.shippingAccountCreated.emit({
            shippingAccount: result,
            type: 'update'
          });

          this.form.reset();
          this.notificationService.broadcastSuccessNotification({
            message: 'Successfully updated shipping account..',
            options: {
              timeout: true
            }
          });
        }
      })
    } else {
      this.customerService.createShippingAccount(this.form.value).subscribe(result => {
        if (result) {
          this.shippingAccountCreated.emit({
            shippingAccount: result,
            type: 'create'
          });

          this.form.reset();
          this.notificationService.broadcastSuccessNotification({
            message: 'Successfully added shipping account.',
            options: {
              timeout: true
            }
          });
        }
      });
    }
  }
}
