import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ShippingApiService } from './shipping-api.service';


@Injectable({ providedIn: 'root' })
export class ShippingService {
  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor (
    private apiService: ShippingApiService
  ) {}

  getShippingRatesForAddress(addressId, weightLbs, lengthIn, widthIn, heightIn): Observable<any> {
    return this.apiService.get(`/shipping_costs?addressId=${addressId}` +
      `&weightLbs=${weightLbs}&lengthIn=${lengthIn}` +
      `&widthIn=${widthIn}&heightIn=${heightIn}`).pipe(catchError(this.formatErrors));;
  }

}
