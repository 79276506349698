<div class="search-header-wrapper input-group add-flex">
  <input
    (click)="focus($event)"
    [(ngModel)]="searchService.typeAheadModel"
    [adaptivePosition]="true"
    [typeahead]="searchService.dataSource"
    [typeaheadAsync]="true"
    typeaheadOptionField="name"
    typeaheadGroupField="group"
    [typeaheadIsFirstItemActive]="false"
    [typeaheadItemTemplate]="customItemTemplate"
    #searchInput
    class="form-control search-header-input"
  >
  <div class="input-group-append">
    <button class="search-icon-append border-0 pointer" id="basic-addon1" (click)="search()">
      <fa-layers [fixedWidth]="true" [fixedWidth]="true">
        <fa-icon style="color: white;" [icon]="faMagnifyingGlass"></fa-icon>
      </fa-layers>
    </button>
  </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
  <div class="mb-0 search-item" (click)="navigate(model.url, model.queryParams)">
    <div [innerHTML]="model.highlight && model.highlight.title ? model.highlight.title : model.name"></div>
    <div *ngIf="model.results" class="search-count">({{ model.results | number }} Product Results)</div>
  </div>
</ng-template>