import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShippingMethodComponent } from './shipping-method/shipping-method.component';
import { ReviewComponent } from './review/review.component';
import { SignInComponent } from './signin/signin.component';
import { ShippingAddressComponent } from './shipping-address/shipping-address.component';
import { ShippingAddressEditComponent } from './shipping-address-edit/shipping-address-edit.component';
import { BillingComponent } from './billing/billing.component';

const routes: Routes = [
  {
    path: 'signin',
    component: SignInComponent
  },
  {
    path: 'shipping-address',
    component: ShippingAddressComponent
  },
  {
    path: 'shipping-address-edit',
    component: ShippingAddressEditComponent
  },
  {
    path: 'shipping-address-new',
    component: ShippingAddressEditComponent
  },
  {
    path: 'shipping-method',
    component: ShippingMethodComponent
  },
  {
    path: 'billing',
    component: BillingComponent
  },
  {
    path: 'review',
    component: ReviewComponent
  }
];

export const routerModuleForChild = RouterModule.forChild(routes)

@NgModule({
  imports: [routerModuleForChild],
  exports: [RouterModule]
})
export class CheckoutRoutingModule { }
