export class ProductResult {
    id: number
    product_type: string
    display_name: string
    filter_value: string
    filter_type: string
    filter_category: string
    filter_field: string
    _source: {
        available_quantity: number
        brand_code: string
        brand_full_name: string
        brand_id: string
        brand_owner: string
        brand_web_description_long: string
        brand_web_description_short: string
        carbon_conductive: string
        coating: string
        coating_color: string
        color: string
        color_code: string
        common_metric_size: string
        compliance_data: [{
            compliance_web_description_short;
        }]
        component_material: string
        cross_section: string
        cross_section_inch: string
        cure_system_code: string
        cure_system_full_name: string
        cure_system_id: string
        cure_system_web_description_long: string
        cure_system_web_description_short: string
        datasheet_avail: number
        // electrical_property_data: [{…}]
        emi_filler_code: string
        emi_filler_full_name: string
        emi_filler_web_description_long: string
        emi_filler_web_description_short: string
        explosive_decrompression_resistance: string
        fastest_lead_time: number
        hardness_code: string
        hardness_full_name: string
        hardness_scale: string
        hardness_web_description_long: string
        hardness_web_description_short: string
        high_temperature_c: number
        high_temperature_f: number
        image_url: string
        increased_acrylonitrile: string
        inside_diameter: string
        inside_diameter_inch: string
        internal_description: string
        internal_lubricant_code: string
        internal_lubricant_full_name: string
        internal_lubricant_web_description_long: string
        internal_lubricant_web_description_short: string
        legacy_military_spec: string
        low_temperature_c: number
        low_temperature_f: number
        material_code: string
        material_search_description: string
        material_subtype_full_name: string
        material_subtype_web_description: string
        material_type_full_name: string
        material_type_search_grid_name: string
        material_type_short_name: string
        material_type_web_description: string
        metal_detectable: string
        mil_spec_code: string
        mil_spec_full_name: string
        mil_spec_web_description_long: string
        mil_spec_web_description_short: string
        military_aerospace_spec: string
        netsuite_product_subtype_id: number
        netsuite_product_type_segment_id: number
        nonconductive_insulating: string
        obsolete_material_replacement: string
        oem_material_code: string
        oem_material_full_name: string
        oem_material_web_description_long: string
        oem_material_web_description_short: string
        outside_diameter: string
        outside_diameter_inch: string
        part_number: string
        private_label_brand: string
        product_id: number
        product_search_description: string
        product_search_visible: number
        product_segment_name: string
        product_subtype_name: string
        product_type: string
        product_type_description: string
        product_visibility: number
        relative_price: number
        shelf_life_years: number
        size_code: string
        sizing_spec_boss: string
        sizing_spec_default: string 
        specialFeatures: any
        specialty_filler_code: string
        specialty_filler_full_name: string
        specialty_filler_web_description_long: string
        specialty_filler_web_description_short: string
        specialty_resistance_data: [{
            specialty_resistances_code: number
            specialty_resistances_full_name: string
            specialty_resistances_web_description_long: string
            specialty_resistances_web_description_short: string
        }]
        specific_gravity: string
        standard_size_british: string
        standard_size_five_dash: string
        standard_size_id: number
        standard_size_japanese: string
        standard_size_k_dash: string
        standard_size_usa: string
        subtype_code: string
        subtype_id: string
        surface_lubricant: string
        temperature_data_source: string
        tight_tolerance: number
        type_code: string
        type_id: number
        weight_lbs: string
        image_product_detail: string
        image_product_thumbnail: string

    }
}

export class FilteredProductResult {
    hits: { 
        hits: ProductResult[]
        total: number
    };
    aggregations:any;
}
