import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { RouterExtensionService } from '../../core/services/router-extension.service';
import { PreviousUrlModel } from '../../shared/router-extension.model';
import { CartService } from '../../core/services/cart.service';

@Component({
  selector: 'app-signin-form',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninFormComponent implements OnInit {
  @Input() returnUrl: string;
  @Input() showSwitchText: boolean = false;
  @Input() email: string;
  previousUrl = new PreviousUrlModel();

  loginForm: FormGroup;
  loginError = false;
  loginErrorMessage = '';

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private routerExtensionService: RouterExtensionService,
    private router: Router
  ) {
    this.routerExtensionService.previousUrlSubject.subscribe(previousUrl => {
      this.previousUrl.url = previousUrl ? previousUrl.url : ['/store'];
      this.previousUrl.paramMap = previousUrl ? previousUrl.paramMap : {};
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.maxLength(20)]]
    });
  }

  ngOnInit() {
    if (this.email) {
      this.loginForm.patchValue({email: this.email});
    }
  }

  customerLogin() {
    const credentials = this.loginForm.value;

    this.loginError = false;
    this.authService.login(credentials).subscribe(
      result => {
        this.cartService.convertGuestCart().subscribe((res)=>{
          if (result) {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(this.previousUrl['url'], { queryParams: this.previousUrl['paramMap'] });
            }
          }
        });
      },
      error => {
        console.log(error);
        this.loginError = true;
        this.loginErrorMessage = 'Incorrect login credentials';
    });
  }
}
