<!-- DESKTOP HEADER BEGIN -->
<div class="container-fluid uppercase p-0 desktop-header">
  <div class="container marco-navbar-header p-0">
    <a href="/"><img src="https://www.marcorubber.com/assets/img/logo/marco_logo.svg" width="230" height="45"
        alt=""></a>
    <div class="add-flex flex-row text-align-center">
      <div class="px-15 py-0 pointer">
        <button (click)="isCollapsed = !isCollapsed" class="nav-button font-12 font-weight-550">
          <ng-container
            *ngTemplateOutlet="headerIconLink; context: { name: 'Search', icon: faMagnifyingGlass }"></ng-container>
        </button>
      </div>
      <div class="px-15 py-0 pointer">
        <button [routerLink]="['/inquiry-general.htm']" class="nav-button font-12 font-weight-550">
          <ng-container
            *ngTemplateOutlet="headerIconLink; context: { name: 'Contact', icon: faEnvelope }"></ng-container>
        </button>
      </div>
      <div *ngIf="sharedData.isLoggedIn" class="px-15 py-0 pointer dropdown" dropdown (mouseleave)="dp0.hide()"
        triggers="hover" #dp0="bs-dropdown">
        <div>
          <button dropdownToggle class="nav-button font-12 font-weight-550">
            <ng-container
              *ngTemplateOutlet="headerIconLink; context: { name: 'Account', icon: faCircleUser }"></ng-container>
          </button>
          <ul class="dropdown-menu user-menu" *dropdownMenu>
            <li class="pt-5"><a class="brand-link" [routerLink]="['/profile/orders']">Orders</a></li>
            <li class="pt-5"><a class="brand-link" [routerLink]="['/profile/main']">Profile</a></li>
            <li class="pt-5"><a class="brand-link" [routerLink]="['/logout']">Logout</a></li>
            <li class="pt-5" *ngIf="sharedData.isAdmin"><a class="brand-link" [href]="adminUrl">Admin</a></li>
          </ul>
        </div>
      </div>
      <div *ngIf="!sharedData.isLoggedIn" class="px-15 py-0 pointer">
        <button [routerLink]="['/signin']" class="nav-button font-12 font-weight-550">
          <ng-container
            *ngTemplateOutlet="headerIconLink; context: { name: 'Log In', icon: faCircleUser }"></ng-container>
        </button>
      </div>
      <div class="px-15 py-0 pointer">
        <ng-container *ngTemplateOutlet="cartButton; context: { text: true }"></ng-container>
      </div>
      <div class="align-center add-flex pl-15">
        <a class="btn btn-primary text-white" [routerLink]="['/inquiry-quote.htm']">Request Quote</a>
      </div>
    </div>
  </div>
  <div class="container mb-10" *ngIf="!isCollapsed">
    <app-search></app-search>
  </div>
  <nav class="desktop-nav position-relative">
    <div class="container  add-flex flex-row justify-content-between">
      <div class="px-15 py-0 pointer dropdown position-static flex-1" dropdown (mouseleave)="dp1.hide()"
        triggers="hover" #dp1="bs-dropdown">
        <div>
          <button class="nav-link" dropdownToggle>
            Products
          </button>
          <ul class="dropdown-menu marco-dropdown-content border-0 border-radius-0" *dropdownMenu>
            <div class="container">
              <div class="marco-dropdown-subnav">
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Static Seals', links: menuMap['Static Seals'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Dynamic Seals', links: menuMap['Dynamic Seals'] || [] }"></ng-container>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Sheets and Extrusions', links: menuMap['Sheets and Extrusions'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Gaskets', links: menuMap['Gaskets'] || [] }"></ng-container>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Hardware', links: menuMap['Hardware'] || [] }"></ng-container>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Custom', links: menuMap['Custom'] || [] }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="panel;"></ng-container>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="px-15 py-0 pointer dropdown position-static flex-1" dropdown (mouseleave)="dp2.hide()"
        triggers="hover" #dp2="bs-dropdown">
        <div>
          <button class="nav-link" dropdownToggle>
            Solutions
          </button>
          <ul class="dropdown-menu marco-dropdown-content border-0 border-radius-0" *dropdownMenu>
            <div class="container">
              <div class="marco-dropdown-subnav">
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Services', links: menuMap['Services'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Applications', links: menuMap['Applications'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Industries', links: menuMap['Industries'] || [] }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="panel;"></ng-container>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="px-15 py-0 pointer dropdown position-static flex-1" dropdown (mouseleave)="dp3.hide()"
        triggers="hover" #dp3="bs-dropdown">
        <div>
          <button class="nav-link" dropdownToggle>
            References
          </button>
          <ul class="dropdown-menu marco-dropdown-content border-0 border-radius-0" *dropdownMenu>
            <div class="container">
              <div class="marco-dropdown-subnav">
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Guides', links: menuMap['Guides'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Materials', links: menuMap['Materials'] || [] }"></ng-container>
                </div>
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Perfluoroelastomer FFKM', links: menuMap['Perfluoroelastomer FFKM'] || [] }"></ng-container>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'Compliances & Certifications', links: menuMap['Compliances & Certifications'] || [] }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="panel;"></ng-container>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="px-15 py-0 pointer dropdown position-static flex-1" dropdown (mouseleave)="dp4.hide()"
        triggers="hover" #dp4="bs-dropdown">
        <div>
          <button class="nav-link" dropdownToggle>
            About Us
          </button>
          <ul class="dropdown-menu marco-dropdown-content border-0 border-radius-0" *dropdownMenu>
            <div class="container">
              <div class="marco-dropdown-subnav">
                <div>
                  <ng-container *ngTemplateOutlet="desktopListItem; context: { name: 'About Us', links: menuMap['About Us'] || [] }"></ng-container>
                </div>
                <ng-container *ngTemplateOutlet="panel;"></ng-container>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div class="px-15 py-0 pointer position-static flex-1">
        <button class="nav-link" [routerLink]="['/o-ring-store.htm']">Shop Online</button>
      </div>
    </div>
  </nav>
</div>
<!-- DESKTOP HEADER END -->

<!-- MOBILE HEADER BEGIN -->
<div class="container-fluid uppercase py-0 px-15 mobile-header position-relative font-16 font-weight-550 uppercase">
  <div class="container marco-navbar-header p-0">
    <div class="py-0 pointer position-static dropdown " dropdown >
      <div>
        <button dropdownToggle class="nav-button font-12 font-weight-550 font-navy pointer">
          <fa-icon [icon]="faBars" class="font-26 pointer"></fa-icon>
        </button>
        <nav class="dropdown-menu marco-dropdown-content mobile-nav border-0 border-radius-0 p-10" *dropdownMenu>
          <app-search></app-search>
          <ul class="pl-10 list-style-none">
            <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Products', variable: mobileMenuId, sub: false}"></ng-container>
            <ul [isAnimated]="true" [collapse]="mobileMenuId != 'Products'" class="pl-10 list-style-none">
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Static Seals', variable: mobileSubMenuId, sub: true, links: menuMap['Static Seals'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Dynamic Seals', variable: mobileSubMenuId, sub: true, links: menuMap['Dynamic Seals'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Sheets and Extrusions', variable: mobileSubMenuId, sub: true, links: menuMap['Sheets and Extrusions'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Gaskets', variable: mobileSubMenuId, sub: true, links: menuMap['Gaskets'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Hardware', variable: mobileSubMenuId, sub: true, links: menuMap['Hardware'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Custom', variable: mobileSubMenuId, sub: true, links: menuMap['Custom'] || []}"></ng-container>
            </ul>
            <ng-container
              *ngTemplateOutlet="mobileListItem; context: { name: 'Solutions', variable: mobileMenuId }"></ng-container>
            <ul [isAnimated]="true" [collapse]="mobileMenuId != 'Solutions'" class="pl-10 list-style-none">
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Services', variable: mobileSubMenuId, sub: true, links: menuMap['Services'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Applications', variable: mobileSubMenuId, sub: true, links: menuMap['Applications'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Industries', variable: mobileSubMenuId, sub: true, links: menuMap['Industries'] || []}"></ng-container>
            </ul>
            <ng-container
              *ngTemplateOutlet="mobileListItem; context: { name: 'References', variable: mobileMenuId }"></ng-container>
            <ul [isAnimated]="true" [collapse]="mobileMenuId != 'References'" class="pl-10 list-style-none">
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Guides', variable: mobileSubMenuId, sub: true, links: menuMap['Guides'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Materials', variable: mobileSubMenuId, sub: true, links: menuMap['Materials'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Perfluoroelastomer FFKM', variable: mobileSubMenuId, sub: true, links: menuMap['Perfluoroelastomer FFKM'] || []}"></ng-container>
              <ng-container *ngTemplateOutlet="mobileListItem; context: { name: 'Compliances & Certifications', variable: mobileSubMenuId, sub: true, links: menuMap['Compliances & Certifications'] || []}"></ng-container>
            </ul>
            <ng-container
              *ngTemplateOutlet="mobileListItem; context: { name: 'About Us', variable: mobileMenuId }"></ng-container>
            <ul [isAnimated]="true" [collapse]="mobileMenuId != 'About Us'" class="pl-10 list-style-none">
              <ng-container *ngTemplateOutlet="mobileListLinks; context: { list: menuMap['About Us'] || [] }"></ng-container>
            </ul>
            <li class="link-style" [routerLink]="['/o-ring-store.htm']">Shop Online</li>
            <li class="divider dropdown-divider p-0"></li>
            <li><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/inquiry-quote.htm'], name: 'Request Quote' }"></ng-container></li>
            <li><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/inquiry-general.htm'], name: 'Contact Us' }"></ng-container></li>
            <li class="divider dropdown-divider p-0"></li>
            <li *ngIf="sharedData.isLoggedIn">{{sharedData.customer.first_name}} {{sharedData.customer.last_name}}</li>
            <li *ngIf="sharedData.isAdmin"><ng-container *ngTemplateOutlet="mobileListLink; context: { url: adminUrl, name: 'Admin' }"></ng-container></li>
            <li *ngIf="sharedData.isLoggedIn"><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/profile/orders'], name: 'Orders' }"></ng-container></li>
            <li *ngIf="sharedData.isLoggedIn"><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/profile/main'], name: 'Profile' }"></ng-container></li>
            <li *ngIf="sharedData.isLoggedIn"><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/logout'], name: 'Logout' }"></ng-container></li>
            <li *ngIf="!sharedData.isLoggedIn"><ng-container *ngTemplateOutlet="mobileListLink; context: { url: ['/signin'], name: 'Log In' }"></ng-container></li>
          </ul>
        </nav>
      </div>
    </div>
    <a href="/"><img src="https://www.marcorubber.com/assets/img/logo/marco_logo.svg" width="230" height="45"
        alt=""></a>
    <div class="add-flex flex-row text-align-center">
      <div class="px-15 py-0 pointer">
        <ng-container *ngTemplateOutlet="cartButton; context: { text: false }"></ng-container>
      </div>
    </div>
  </div>
</div>
<!-- MOBILE HEADER END -->

<!-- TEMPLATES FOR HEADERS BEGIN -->
<ng-template #panel>
  <div>
    <div class="marco-dropdown-links font-16">
      <div class="background-gray-light add-flex flex-column">
        <img (click)="navigateToBlog()" width="269" height="117" alt="O-Rings and Basic Seals Suppliers"
          src="/assets/site/images/orings-variety.png" />
        <a class="p-2" [attr.href]="getBlogUrl()">Articles, tips and case studies</a>
      </div>
      <div class="background-gray-light mt-20 add-flex align-center p-2">
        <fa-icon [icon]="faCircleNotch" class="color-navy mr-10"></fa-icon>
        <a [routerLink]="['/o-ring-size-chart-index.htm']">Standard Size Charts</a>
      </div>
      <div class="background-gray-light mt-20 add-flex align-center p-2">
        <fa-icon [icon]="faBarsStaggered" class="color-navy mr-10"></fa-icon>
        <a [routerLink]="['/o-ring-material-selection-comparison.htm']">Material Selection</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desktopListItem let-name="name" let-variable="variable" let-sub="sub" let-links="links">
  <div class="marco-dropdown-links">
    <div class="font-weight-550">{{ name }}</div>
    <ng-container *ngTemplateOutlet="desktopListLink; context: { list: links }"></ng-container>
  </div>
</ng-template>

<ng-template #desktopListLink let-list="list">
  <ng-container *ngFor="let link of list">
    <a [routerLink]=link.url>{{ link.name }}</a>
  </ng-container>
</ng-template>

<ng-template #mobileListItem let-name="name" let-variable="variable" let-sub="sub" let-links="links">
  <li class="add-flex justify-content-between" (click)="setMenuCollapseState($event, name, sub)"
    [attr.aria-expanded]="mobileMenuId == name" aria-controls="collapseEvent">
    {{ name }}
    <fa-icon [icon]="variable == name ? faAngleDown : faAngleRight" size="lg"></fa-icon>
  </li>
  <ul [isAnimated]="true" [collapse]="mobileSubMenuId != name" class="pl-10 list-style-none">
    <ng-container *ngTemplateOutlet="mobileListLinks; context: { list: links }"></ng-container>
  </ul>
</ng-template>

<ng-template #mobileListLink let-name="name" let-url="url">
  <a (click)="mobileIsCollapsed = true" [routerLink]=url>{{ name }}</a>
</ng-template>

<ng-template #mobileListLinks let-list="list">
  <ng-container *ngFor="let link of list">
    <li><ng-container *ngTemplateOutlet="mobileListLink; context: { url: link.url, name: link.name }"></ng-container></li>
  </ng-container>
</ng-template>

<ng-template #headerIconLink let-name="name" let-icon="icon">
  <fa-layers [fixedWidth]="true" class="font-26 font-navy"><fa-icon [icon]="icon"></fa-icon></fa-layers>
  <div>
    {{name}}
  </div>
</ng-template>

<ng-template #cartButton let-text="text">
  <button [routerLink]="['/cart']" class="nav-button font-12 font-navy font-weight-550">
    <fa-layers [fixedWidth]="true" class="font-26 font-navy">
      <fa-icon [icon]="faShoppingCart"></fa-icon>
      <fa-layers-counter id="cart-counter" *ngIf="sharedData?.cart?.items?.length > 0"
        [content]="sharedData.cart.items.length"></fa-layers-counter>
    </fa-layers>
    <div *ngIf="text">
      Cart
    </div>
  </button>
</ng-template>

<!-- TEMPLATES FOR HEADERS END -->
