<div style="width: 100%">
  <div class="d-flex pl-15">
    <h4>{{ title }}</h4>
  </div>
  <hr class="pb-1">
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="form-group">
      <div class="d-flex">
        <div class="col-sm-3">
          <label for="type">Account Type</label>
        </div>
        <div class="col-sm-9">
          <select class="form-control" id="type" formControlName="type" name="type">
            <option [ngValue]="">Select a carrier</option>
            <option *ngFor="let shippingCarrier of shippingCarriers" [ngValue]="shippingCarrier.toLowerCase()">
              {{ shippingCarrier }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex">
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div *ngIf="form.controls['type'].hasError('required') && form.controls['type'].touched" class="form-warning">
            Field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex">
        <div class="col-sm-3">
          <label for="accountNumber">Account Number</label>
        </div>
        <div class="col-sm-9">
          <input class="form-control" type="text" id="accountNumber" formControlName="accountNumber" name="accountNumber">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-sm-3"></div>
        <div class="col-sm-9">
          <div *ngIf="form.controls['accountNumber'].hasError('required') && form.controls['accountNumber'].touched" class="form-warning">
            Field is required.
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-right paddin-top-1x mt-4">
      <button class="btn btn-primary mr-2">
        Save Account
      </button>

      <button class="btn btn-info" (click)="close()">
        Cancel
      </button>
    </div>
  </form>
</div>
  