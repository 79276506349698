
<ng-container [formGroup]="item">
<tr>
    <td colspan="2" class="product-item-width">
        <div class="product-item d-flex">
            <span class="product-thumb mr-2">
                <img style="width: 44px" *ngIf="item.get('thumbnailPath').value" src="{{item.get('thumbnailPath').value}}"
                    alt="{{ item.get('partNumber').value }}">
            </span>
            <div class="product-info align-items-center">
                <div class="product-title">
                    <a [routerLink]="[item.get('categoryUrlSlug').value ? '/product/'+item.get('categoryUrlSlug').value: '/product',  item.get('partNumber').value]" class="link-style">
                        {{ item.get('partNumber').value }} {{ item.get('itemTitle').value }}
                    </a>
                </div>
                <a *ngIf="edit" class="pointer text-primary cart-remove-text" (click)="removeRfqFromCart(item)">Remove</a>                
            </div>
        </div>
    </td>
    
    <td colspan="2" valign="top" class="text-center d-flex justify-content-between qty-col">
        <div>
            <label [for]="item?.get('rfqItemId').value + '-qty'" class="mr-2">Qty: </label>
            <span [id]="item?.get('rfqItemId').value + '-qty'">{{ item?.get('quantity').value }}</span>
        </div>
        <div *ngIf="edit">
            <fa-icon class="pointer text-primary cart-remove-button" (click)="removeRfqFromCart(item)" [icon]="faTimesCircle"></fa-icon>
        </div>
    </td>
</tr>

<tr>
    <td colspan="1"  class="border-top-0 border-bottom needed-col">
        <form [formGroup]="item">
        <div *ngIf="isFieldValid(item, 'requestedLeadTime')" class="form-warning">b
            Field is required.
        </div>
        <div>
            <label class="mr-1">When Needed:</label><br>{{ item.value.requestedLeadTime }}
        </div>
        </form>
    </td>
    <td colspan="1" class="border-top-0 border-bottom reason-col">
        <div>
            <label class="mr-1">Reason:</label><br>{{ item.value.reason }}
        </div>    
    </td>
    <td colspan="2" class="rfq-notes border-top-0 border-bottom notes-col">
        <div>
            <label class="mr-1">Notes:</label><br>{{ item.value.comment }}
        </div>    
    </td>
</tr>
<!-- <tr>
    <td colspan="4">
        <pre>{{ item.value | json }}</pre>
    </td>
</tr> -->
</ng-container>
