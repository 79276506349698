import { Injectable } from '@angular/core';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessTime from 'dayjs-business-time';
import updateLocale from 'dayjs/plugin/updateLocale';

// add dayjs plugins
dayjs.extend(utc);
dayjs.extend(updateLocale)
dayjs.extend(dayjsBusinessTime);
dayjs.extend(timezone) 

@Injectable({ providedIn: 'root' })
export class DateService {
  longestLeadTime = {
    vendorCode: '',
    leadTimeDays: 0,
    leadTimeEndDays: 0
  }
  isForQuoteItem = false;
  SHIPPING_HOUR_CUTOFF = 12;

  constructor( ) { 
    dayjs.updateLocale('us', {
      holidays: ['09-02-2019', '11-28-2019', '11-29-2019','12-24-2019', '12-25-2019', '12-26-2019','12-27-2019','12-30-2019', '01-01-2020', '01-02-2020'],
      holidayFormat: 'MM-DD-YYYY'
    });
  }


  private computeLongestLeadTimeFromCart(cart) {
    cart.items.forEach(cartItem => {
      if(!cartItem.quoteItemQuantityBreakSource){
        this.computeLongestLeadTimeFromItem(cartItem);
      }
    })
  }

  private computeLongestLeadTimeFromItem(cartItem) {
    cartItem.itemDetails.forEach(cartItemDetails => {
      if (cartItemDetails['leadTimeDays'] >= this.longestLeadTime.leadTimeDays) {
        this.longestLeadTime = {
          vendorCode: cartItemDetails['vendorCode'],
          leadTimeDays: cartItemDetails['leadTimeDays'],
          leadTimeEndDays: cartItemDetails['leadTimeEndDays']
        }
      }
    })
  }


  private computeStartEndTimeRange(leadTimeDays, leadTimeEndDays){
    dayjs.tz.setDefault("America/New_York")
    let startTime = dayjs();

    if(startTime.hour() >= this.SHIPPING_HOUR_CUTOFF || !dayjs(startTime).isBusinessDay()){
      leadTimeDays += 1;
      if(leadTimeEndDays){
        leadTimeEndDays += 1;
      }
    }

    let leadTimeMessage = '';
    leadTimeMessage +=  startTime.addBusinessDays(leadTimeDays).format('ddd MMM DD');
    if(leadTimeEndDays){
      leadTimeMessage += ' - '
      leadTimeMessage +=  startTime.addBusinessDays(leadTimeEndDays).format('ddd MMM DD');
    }
    return leadTimeMessage;
  }

  private computeLeadTimeRange(leadTime, additionalDays) {

    dayjs.tz.setDefault("America/New_York")
    let startTime = dayjs();

    if(startTime.hour() >= this.SHIPPING_HOUR_CUTOFF || !dayjs(startTime).isBusinessDay()){
      leadTime += 1;
    }

    let leadTimeMessage = '';
    leadTimeMessage +=  startTime.addBusinessDays(leadTime).format('ddd MMM DD');
    leadTimeMessage += ' - '
    leadTimeMessage +=  startTime.addBusinessDays(leadTime + additionalDays).format('ddd MMM DD');
    return leadTimeMessage;
  }


  public buildDateString(deliveryDays?) {
    let leadTimeMessage = '';
    const deliveryDaysToAdd = deliveryDays || 0;


    if(this.isForQuoteItem){
      leadTimeMessage = this.computeStartEndTimeRange(this.longestLeadTime.leadTimeDays, this.longestLeadTime.leadTimeEndDays);
    } else if (this.longestLeadTime.vendorCode === "MARCO" && !deliveryDays) {
      if (this.longestLeadTime.leadTimeDays > 0){
        leadTimeMessage = this.computeLeadTimeRange(this.longestLeadTime.leadTimeDays, 1);
      } else {
        dayjs.tz.setDefault("America/New_York")
        let startTime = dayjs();
        if(!dayjs(startTime).isBusinessDay()){
          leadTimeMessage = startTime.addBusinessDays(1).format('ddd MMM DD');
        } else if (startTime.hour() >= this.SHIPPING_HOUR_CUTOFF){
          leadTimeMessage = 'Tomorrow';
        } else {
          leadTimeMessage = 'Today or Tomorrow';
        }
      }
    } else if (this.longestLeadTime.vendorCode === "MARCO" && deliveryDays) {
      leadTimeMessage = this.computeLeadTimeRange(this.longestLeadTime.leadTimeDays + deliveryDaysToAdd, 1);
    } else if (this.longestLeadTime.vendorCode === "OUTSIDE_VENDOR" || this.longestLeadTime.vendorCode === "ESCORT") {
      leadTimeMessage = this.computeLeadTimeRange(this.longestLeadTime.leadTimeDays + deliveryDaysToAdd, 4);
    }

    return leadTimeMessage;
  }

  private setLongestLeadTime(vendorCode?, leadTimeDays?, leadTimeEndDays?) {
    this.longestLeadTime = {
      vendorCode: vendorCode || '',
      leadTimeDays: leadTimeDays || 0,
      leadTimeEndDays: leadTimeEndDays || 0
    }
  }

  public convertTimestampToDate(timestamp) {
    return dayjs(timestamp).format('MM/DD/YYYY');
  }

  public computeShippingDate(item) {
    this.isForQuoteItem = item.quoteItemQuantityBreakSource ? true :false;
    this.computeLongestLeadTimeFromItem(item);
    
    let dateString = this.buildDateString();
    this.setLongestLeadTime();
    return dateString;
  }

  public computeShippingDateFromFulfillmentOption(fullfillmentOption) {
    this.setLongestLeadTime(fullfillmentOption.vendorCode, fullfillmentOption.leadTimeDays)
    let dateString = this.buildDateString();
    this.setLongestLeadTime();
    return dateString;
  }

  public computeItemDeliveryDate(item, cart, deliveryDays) {
    this.isForQuoteItem = item.quoteItemQuantityBreakSource ? true :false;
    if(this.isForQuoteItem){
      this.computeLongestLeadTimeFromItem(item);
    } else {
      this.computeLongestLeadTimeFromCart(cart);
    }
    let dateString = this.buildDateString(deliveryDays);
    this.setLongestLeadTime();
    return dateString;
  }

  public computeDeliveryDate(cart, deliveryDays) {
    this.computeLongestLeadTimeFromCart(cart);
    let dateString = this.buildDateString(deliveryDays);
    this.setLongestLeadTime();
    return dateString;
  }
}
