import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { ProductService } from './product.service';
import { CatalogProductService } from './catalog-product.service';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public typeAheadModel: string;
  public dataSource:Observable<any>;
  public searchResultsSubject:ReplaySubject<any[]> = new ReplaySubject(1);
  updateObservable;

  constructor(
    private http: HttpClient,
    private productService: ProductService,
    private catalogProductService: CatalogProductService,
  ) {
    this.dataSource = Observable.create((observer:any) => {
      if (this.typeAheadModel !== "") {
        this.executeSearch(this.typeAheadModel, observer);
      } else {
        observer.next([])
      }

      this.updateObservable = function (newValue) {
        observer.next(newValue);
        observer.complete();
      };
    });
  }
  
  private formatErrors(error: any) {
    return  throwError(error.error);
  }

  clearSearch = () => {
    this.typeAheadModel = '';
    if (this.updateObservable) {
      this.updateObservable([]);
    }
  }

  get (searchString:string): Observable<any> {
    // TODO - NEED TO MOVE ENGINE KEY
    return this.http.get(`https://search-api.swiftype.com/api/v1/public/engines/search.json`, { 
      params: {
        engine_key:"NdhCEEACcAyrbYHHczF-",
        q: searchString,
      }
    }).pipe(catchError(this.formatErrors));
  }

  suggest (searchString:string): Observable<any> {
    // TODO - NEED TO MOVE ENGINE KEY
    return this.http.get(`https://search-api.swiftype.com/api/v1/public/engines/suggest.json`, { 
      params: {
        engine_key:"NdhCEEACcAyrbYHHczF-",
        q: searchString,
      }
    }).pipe(catchError(this.formatErrors));
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  executeSearch = (searchString, observer?, track?) => {
    let regexOutSlashes = searchString.replace(/\/|\\/g, " ");

    let resultSet = [];
    let searchCall = observer ? this.suggest(searchString) : this.get(searchString);

    searchCall.subscribe(res => {
      res.records.page.forEach(hit => {
        let parsedUrl = hit.url.replace('https://www.marcorubber.com', '');
        if (hit['search-title'] && hit['search-title'] !== '') {
          let replace = searchString;
          let re = new RegExp(replace,"gi");
          hit.highlight.title = hit['search-title'].replace(re, `<b>$&</b>`);
        } else if (hit.highlight && hit.highlight.title) {
          hit.highlight.title = hit.highlight.title.replace(/(em)>/g, 'b>');
        }
        resultSet.push({
          name: hit.title,
          highlight: hit.highlight,
          group: "Reference Pages",
          url: [parsedUrl]
        })
      });

      this.productService.checkProductOrFacetExists(regexOutSlashes).subscribe(productExists => {
        if (productExists) {
          if (productExists.product) {
            let product = searchString.toUpperCase();
            resultSet.push({
              group: "Product",
              name: `${product}`,
              url: [`/product/${[product]}`]
            });
          } else if (productExists.facet) {
            resultSet.push({
              group: "See All Products For",
              name: `${this.capitalizeFirstLetter(searchString)}`,
              term: productExists.term,
              facet: productExists.facet,
              results: productExists.results,
              url: [`/store/${productExists.facet}/${productExists.term}`]
            });
          }
        } else {
          this.catalogProductService.requestFilteredProducts({
            filters: [],
            numberOfResults: 1,
            pageNum: 0,
            searchQuery: regexOutSlashes
          }).subscribe(results => {
            if (results.hits.total.value > 0) {
              resultSet.push({
                group: "See All Products For",
                name: `<b>${this.capitalizeFirstLetter(searchString)}</b>`,
                results: results.hits.total.value,
                url: [`/store`],
                queryParams: {
                  searchQuery: searchString
                }
              });

              if (observer) {
                observer.next(resultSet);
              } else {
                this.searchResultsSubject.next(resultSet);
              }
            } 
          })
        }

        if (observer) {
          observer.next(resultSet);
        } else {
          this.searchResultsSubject.next(resultSet);
        }
      }, (err) => {
        resultSet.push({
          group: "See All Products For",
          name: `<b>${searchString}</b>`,
          url: [`/store`],
          queryParams: {
            searchQuery: searchString
          }
        });

        if (observer) {
          observer.next(resultSet);
        } else {
          this.searchResultsSubject.next(resultSet);
        }
      });
    })
  }
}
