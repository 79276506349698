import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, NavigationStart } from '@angular/router';
import { PreviousUrlModel } from '../../shared/router-extension.model';
import { BehaviorSubject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class RouterExtensionService {
  private currentUrl: string = undefined;

  private previousUrl:PreviousUrlModel;
  public previousUrlSubject = new BehaviorSubject<PreviousUrlModel | null>(null);

  private previousCatalogUrl:PreviousUrlModel;
  public previousCatalogUrlSubject = new BehaviorSubject<PreviousUrlModel | null>(null);

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = this.currentUrl;
        if (url.indexOf('/signin') === -1 && url.indexOf('/forgot-password') === -1 && url.indexOf('/register') === -1 && url.indexOf('/checkout') === -1 && url.indexOf('/logout') === -1 && url !== event.url) {
          let previousUrlObject = new PreviousUrlModel();
        
          url.split('?').forEach((u, i) => {
            if (i !== 0) {
            const paramMap = {};
              u.split('&').forEach(param => {
                let paramSplit = param.split('=');
                paramMap[paramSplit[0]] = paramSplit[1]
              })
              previousUrlObject.paramMap = paramMap;
            }  else {
              previousUrlObject.url = [u];
            }
          });

          this.previousUrl = previousUrlObject;
          this.previousUrlSubject.next(this.previousUrl);


          if (url.indexOf('/store') !== -1) {
            this.previousCatalogUrl = previousUrlObject;
            this.previousCatalogUrlSubject.next(this.previousCatalogUrl);
          }  
        }

        this.currentUrl = event.url;
      };
    });
  }

  getCurrentUrl() {
    return this.currentUrl;
  }
}
