import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  faTimes = faTimes;
  title: string;
  closeBtnName: string;
  item: object;
  callback;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  executeCallback() {
    this.callback(this.item);
    this.bsModalRef.hide();
  }
}
