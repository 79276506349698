import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { PricingApiService } from './pricing-api.service';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductService {
  private formatErrors(error: any) {
    return  throwError(error);
  }

  constructor (
    private apiService: PricingApiService
  ) {}

  get(slug): Observable<any> {
    return this.apiService.get('/product/' + slug).pipe(catchError(this.formatErrors));
  }

  getAccessoryByPartNumber(slug): Observable<any> {
    return this.apiService.get('/accessory/part_number/' + slug).pipe(catchError(this.formatErrors));
  }

  isProductLine(slug): Observable<any> {
    return this.apiService.get('/product_line/check/' + slug).pipe(catchError(this.formatErrors));
  }

  getProductLine(slug): Observable<any> {
    return this.apiService.get('/product_line/' + slug).pipe(catchError(this.formatErrors));
  }

  getProductLineByPart(slug): Observable<any> {
    return this.apiService.get('/product_line/part_number/' + slug).pipe(catchError(this.formatErrors));
  }

  getProductByPartNumber(slug): Observable<any> {
    return this.apiService.get('/product/part_number/' + slug).pipe(catchError(this.formatErrors));
  }

  getProductBySku(slug): Observable<any> {
    return this.apiService.get('/product/sku/' + slug).pipe(catchError(this.formatErrors));
  }

  checkProductOrFacetExists(slug): Observable<any> {
    return this.apiService.get('/product/query/' + slug + '/exists').pipe(catchError(this.formatErrors));
  }

  getPrice(partNum, quantity): Observable<any> {
    return this.apiService.post('/pricing/simple/part_number', {partNum: partNum, quantity: quantity}).pipe(catchError(this.formatErrors));
  }

  checkPartNumbers(partNumbers): Observable<any> {
    return this.apiService.post('/product/part_numbers/exist', { partNumbers }).pipe(catchError(this.formatErrors));
  }

  getFulfillmentOptions(partNum, quantity): Observable<any> {
    return this.apiService.post('/pricing/fulfillment/part_number', {partNum: partNum, quantity: quantity, showFulfillmentFlags:true}).pipe(catchError(this.formatErrors));
  }

  queryMaterialTypes(queryObject): Observable<any> {
    return this.apiService.post('/search/autocomplete', queryObject).pipe(catchError(this.formatErrors));
  }
}
